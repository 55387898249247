import { Box, Grid } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';

import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { AddWaterFormFields } from 'modules/transport/forms/AddWaterForm';

interface IAddWaterTransportSettingsForm {
  handleChangeValues: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  isDeposit: boolean;
}

const AddWaterTransportSettingsForm: React.FC<IAddWaterTransportSettingsForm> = ({ handleChangeValues, isDeposit }) => {
  const { ENGINE_POWER, COLOR, MAX_SPEED, ISSUE_YEAR, CABINS_COUNT, CAPACITY, COMMENT, DEPOSIT } = AddWaterFormFields;

  return (
    <>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <QInput
              name={ENGINE_POWER}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Двигатель, мощность, л.с."
              inputProps={{
                min: 0,
                step: 0.01,
              }}
            />
          </Grid>
          <Grid xs={6} item>
            <QInput
              name={MAX_SPEED}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Макс. скорость, км/ч."
              inputProps={{
                min: 0,
                step: 0.01,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <QInput name={COLOR} onChange={handleChangeValues} label="Цвет" />
          </Grid>
          <Grid xs={6} item>
            <QInput
              name={ISSUE_YEAR}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Год выпуска"
              inputProps={{
                min: 1900,
                max: 2100,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <NumberFormat
              name={CABINS_COUNT}
              label="Количество кают"
              onChange={handleChangeValues}
              customInput={QInput}
              displayType="input"
            />
          </Grid>
          <Grid xs={6} item>
            <NumberFormat
              name={CAPACITY}
              label="Пассажировместимость"
              onChange={handleChangeValues}
              customInput={QInput}
              displayType="input"
            />
          </Grid>
        </Grid>
      </Box>
      {isDeposit ? (
        <Box mb={4}>
          <QInput fullWidth multiline name={DEPOSIT} onChange={handleChangeValues} label="Залог" />
        </Box>
      ) : null}
      <Box mb={4}>
        <QInput fullWidth multiline name={COMMENT} onChange={handleChangeValues} label="Комментарий" />
      </Box>
    </>
  );
};

export default AddWaterTransportSettingsForm;
