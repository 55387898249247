import { Box, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useEffect } from 'react';

import { AddWaterFormFields } from 'modules/transport/forms/AddWaterForm';
import WaterTransportCategory from 'modules/transport/models/WaterTransportCategory';

interface ICategoryForm {
  handleChangeValues: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  getCategories: () => void;
  categories: WaterTransportCategory[];
}

const CategoryForm: React.FC<ICategoryForm> = ({ handleChangeValues, getCategories, categories }) => {
  // Effects
  useEffect(() => {
    getCategories();
  }, []);

  // Renders
  const renderCategories = () => {
    return categories.map(item => {
      if (!item.id) {
        return <></>;
      }

      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">Категория, наименование</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item>
          <Box width={288}>
            <InputLabel>Категория</InputLabel>
            <Select
              name={AddWaterFormFields.CATEGORY_ID}
              onChange={handleChangeValues}
              fullWidth
              variant="outlined"
              required
            >
              {renderCategories()}
            </Select>
          </Box>
        </Grid>
        <Grid item>
          <Box width={392}>
            <InputLabel>Наименование</InputLabel>
            <TextField
              name={AddWaterFormFields.NAME}
              onChange={handleChangeValues}
              fullWidth
              variant="outlined"
              required
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryForm;
