import { Edit16Regular } from '@fluentui/react-icons';
import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import React from 'react';

import AddPhoto from 'components/UI/AddPhoto';
import FileModel from 'modules/files/models/FileModel';
import { useCommonStyles } from 'styles/commonStyles';

interface IRentalPhotoProps {
  width: number;
  height: number;
  title: string;
  handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  uploadLabel: string;
  canEdit: boolean;
  img?: FileModel | null;
}

const RentalPhoto: React.FC<IRentalPhotoProps> = props => {
  const { width, height, title, img, uploadLabel, handleUpload, canEdit } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const theme = useTheme();

  return (
    <div
      style={{
        width,
      }}
    >
      <Box
        style={{
          width,
          height,
        }}
        mb={1}
      >
        {img?.fullUrl ? (
          <Box width={width} height={height} position="relative">
            <img width="100%" height="100%" src={img.fullUrl} alt="img" />
            {canEdit && (
              <>
                <input
                  accept="image"
                  id={uploadLabel}
                  type="file"
                  className={commonClasses.displayNone}
                  onChange={handleUpload}
                />
                <label htmlFor={uploadLabel}>
                  <div className={classes.iconWrapper}>
                    <Edit16Regular color={theme.palette.custom.onPrimary.primary} />
                  </div>
                </label>
              </>
            )}
          </Box>
        ) : (
          <AddPhoto width={width} height={height} handleUpload={canEdit ? handleUpload : () => {}} />
        )}
      </Box>
      <Box mb={1}>
        <Typography variant="body1">{title}</Typography>
      </Box>
      <Typography className={classes.subtitleText} variant="body2">
        Будет отображаться в карточке каталога прокатов
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  subtitleText: {
    color: theme.palette.custom.onSurface.secondary,
  },
  iconWrapper: {
    position: 'absolute',
    display: 'flex',
    padding: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.custom.onSurface.primary,
    bottom: 0,
    right: 0,
    cursor: 'pointer',
  },
}));

export default RentalPhoto;
