import AbstractModel from 'base/AbstractModel';

export default class Address extends AbstractModel {
  id: number | null = null;
  unrestrictedValue: string | null = null;
  house: string | null = null;
  flat: string | null = null;
  geoLat: string | null = null;
  geoLon: string | null = null;
  city: string | null = null;
  settlement: string | null = null;
  isEnough: boolean | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
