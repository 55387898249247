import Address from '../models/Address';
import Schedule from '../models/Schedule';
import { DaysNumbers, ILocalSchedule, ILocalScheduleData, TypesOfRental } from '../types/RentalTypes';

export class RentalRenderHelper {
  static getSearchAddressString = (strings: Array<string | null>) => {
    return strings.reduce((prevItem, currentItem) => {
      return currentItem ? prevItem + currentItem : prevItem + ' ';
    }, '');
  };

  static findScheduleDay = (schedules: ILocalSchedule[], dayId: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    return schedules.map((item: any) => {
      if (item.dayNumber === dayId) {
        return {
          ...item,
          [name]: value,
        };
      }

      return item;
    });
  };

  static getDayLabel = (day: number) => {
    switch (day) {
      case DaysNumbers.MON:
        return 'Пн';
      case DaysNumbers.TUE:
        return 'Вт';
      case DaysNumbers.WED:
        return 'Ср';
      case DaysNumbers.THU:
        return 'Чт';
      case DaysNumbers.FRI:
        return 'Пт';
      case DaysNumbers.SAT:
        return 'Сб';
      case DaysNumbers.SUN:
        return 'Вс';
      default:
        return 'Пн';
    }
  };

  static getFullAddress = (address?: Address | null) => {
    if (!address) {
      return '';
    }

    return `${address.unrestrictedValue || ''}${
      address.city ? ', ' + address.city : address.settlement ? ', ' + address.settlement : ''
    }${address.house ? ', ' + address.house : ''}${address.flat ? ', ' + address.flat : ''}`;
  };

  static formatSchedule = (schedule: Schedule[], roundTheClock: boolean): ILocalScheduleData => {
    const newSchedule = schedule.map(item => {
      return {
        dayNumber: item.dayNumber as DaysNumbers,
        timeStart: item.timeStart,
        timeEnd: item.timeEnd,
        shortDayName: item.shortDayName,
      };
    });

    return {
      roundTheClock,
      schedule: newSchedule,
    };
  };

  static getScheduleItem = (schedule: Schedule | null) => {
    if (!schedule) {
      return '';
    }

    if (!schedule.timeStart && !schedule.timeEnd) {
      return 'Выходной';
    } else if (schedule.timeStart && schedule.timeEnd) {
      return `с ${schedule.timeStart} до ${schedule.timeEnd}`;
    }
  };

  static getIsAutoRental = (currentType: string | undefined | null) => {
    return currentType === TypesOfRental.AUTOMOBILE;
  };

  static getSettingDescription = (currentType: string | undefined | null) => {
    if (this.getIsAutoRental(currentType)) {
      return 'Редактируется для каждой машины отдельно в карточке машины';
    } else {
      return 'Редактируется для каждого водного транспорта отдельно в карточке водного транспорта';
    }
  };
}
