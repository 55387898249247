import { Box, DialogProps, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { UpdateWaterFormFields } from 'modules/transport/forms/UpdateWaterForm';
import { TransportFormKeys } from 'modules/transport/types/TransportTypes';

interface IEditWaterFeaturesModalProps extends DialogProps {
  onClose: () => void;
}

const EditWaterFeaturesModal: React.FC<IEditWaterFeaturesModalProps> = observer(({ onClose, ...rest }) => {
  const { transportStore } = useRootStore();

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    transportStore.setForm(TransportFormKeys.UPDATE_WATER, name as UpdateWaterFormFields, value);
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    transportStore.setForm(TransportFormKeys.UPDATE_WATER, name as UpdateWaterFormFields, checked);
  };

  const handleSave = () => {
    transportStore.updateWater(onClose);
  };

  // Renders
  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper title="Редактировать характеристики" onClose={onClose} onSave={handleSave}>
        <Box mb={4}>
          <Grid justifyContent="space-between" container spacing={2}>
            <Grid xs={6} item>
              <QInput
                value={transportStore.updateWaterForm[UpdateWaterFormFields.ENGINE_POWER]}
                name={UpdateWaterFormFields.ENGINE_POWER}
                onChange={handleChangeValues}
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                label="Двигатель, мощность, л.с."
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
              />
            </Grid>
            <Grid xs={6} item>
              <QInput
                value={transportStore.updateWaterForm[UpdateWaterFormFields.MAX_SPEED]}
                name={UpdateWaterFormFields.MAX_SPEED}
                onChange={handleChangeValues}
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                label="Макс. скорость, км/ч."
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid justifyContent="space-between" container spacing={2}>
            <Grid xs={6} item>
              <QInput
                value={transportStore.updateWaterForm[UpdateWaterFormFields.COLOR]}
                name={UpdateWaterFormFields.COLOR}
                onChange={handleChangeValues}
                label="Цвет"
              />
            </Grid>
            <Grid xs={6} item>
              <QInput
                value={transportStore.updateWaterForm[UpdateWaterFormFields.ISSUE_YEAR]}
                name={UpdateWaterFormFields.ISSUE_YEAR}
                onChange={handleChangeValues}
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                label="Год выпуска"
                inputProps={{
                  min: 1900,
                  max: 2100,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid justifyContent="space-between" container spacing={2}>
            <Grid xs={6} item>
              <QInput
                value={transportStore.updateWaterForm[UpdateWaterFormFields.CABINS_COUNT]}
                name={UpdateWaterFormFields.CABINS_COUNT}
                onChange={handleChangeValues}
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                label="Количество кают"
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>
            <Grid xs={6} item>
              <QInput
                value={transportStore.updateWaterForm[UpdateWaterFormFields.CAPACITY]}
                name={UpdateWaterFormFields.CAPACITY}
                onChange={handleChangeValues}
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                label="Пассажировместимость"
                inputProps={{
                  min: 0,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <FormControlLabel
            control={
              <Switch
                name={UpdateWaterFormFields.NEED_LICENSE}
                onChange={handleToggleSwitches}
                checked={!!transportStore.updateWaterForm[UpdateWaterFormFields.NEED_LICENSE]}
                color="primary"
              />
            }
            label="Требуются права"
          />
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

export default EditWaterFeaturesModal;
