import AbstractModel from 'base/AbstractModel';

import { DaysNumbers } from '../types/RentalTypes';

export default class Schedule extends AbstractModel {
  dayNumber: DaysNumbers | null = null;
  timeStart: string | null = null;
  timeEnd: string | null = null;
  dayName: string | null = null;
  shortDayName: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
