import AbstractModel from 'base/AbstractModel';

export default class Tariff extends AbstractModel {
  id: number | null = null;
  start: number | null = null;
  end: number | null = null;
  isSingleDay: boolean | null = null;
  price: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
