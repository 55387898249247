import { ImageAdd24Regular } from '@fluentui/react-icons';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useRef } from 'react';

interface IAddPhoto {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMultiple?: boolean;
  width?: number;
  height?: number;
}

const AddPhoto: React.FC<IAddPhoto> = ({ handleUpload, isMultiple = false, width, height }) => {
  const classes = useStyles();

  const ref = useRef<any>();

  const handleClick = () => {
    ref.current?.click();
  };

  return (
    <Box
      width={width ? width : 187}
      height={height ? height : 120}
      onClick={handleClick}
      className={classes.addPhotoContainer}
    >
      <Grid container alignItems="center" direction="column">
        <Box mb={1}>
          <ImageAdd24Regular className={classes.icon} />
        </Box>
        <Typography className={classes.text} variant="caption">
          Добавить изображение
        </Typography>
        <input ref={ref} onChange={handleUpload} className={classes.input} multiple={isMultiple} type="file" />
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  addPhotoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '.2s all linear',
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    fill: theme.palette.custom.onSurface.secondary,
  },
  text: {
    color: theme.palette.custom.onSurface.secondary,
  },
  input: {
    display: 'none',
  },
}));

export default AddPhoto;
