import { makeStyles, Theme } from '@material-ui/core';

export const useCommonStyles = makeStyles((theme: Theme) => ({
  routerLink: {
    textDecoration: 'none',
  },
  onPrimaryPrimary: {
    color: theme.palette.custom.onPrimary.primary,
  },
  onSurfacePrimary: {
    color: theme.palette.custom.onSurface.primary,
  },
  onSurfaceSecondary: {
    color: theme.palette.custom.onSurface.secondary,
  },
  onSurfaceTertiary: {
    color: theme.palette.custom.onSurface.tertiary,
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  displayNone: {
    display: 'none',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  fontWeight700: {
    fontWeight: 700,
  },
  textOverflowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));
