import AbstractModel from 'base/AbstractModel';
import FileModel from 'modules/files/models/FileModel';
import Address from 'modules/rental/models/Address';

import { TypesOfRental } from '../types/RentalTypes';
import Schedule from './Schedule';

export default class Rental extends AbstractModel {
  id: number | null = null;
  title: string | null = null;
  preview: FileModel | null = null;
  logo: FileModel | null = null;
  phone: string | null = null;
  address: Address | null = null;
  delivery: boolean | null = null;
  deliveryPrice: number | null = null;
  fuelCostPerLiter: number | null = null;
  prePayment: number | null = null;
  deposit: boolean | null = null;
  speedLimit: boolean | null = null;
  mileageLimit: boolean | null = null;
  comment: string | null = null;
  ageLimit: number | null = null;
  experienceLimit: number | null = null;
  washFee: number | null = null;
  isRoundTheClock: boolean | null = null;
  travelAboard: boolean | null = null;
  schedule: Schedule[] | null = null;
  type: TypesOfRental | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
