import AbstractApiRepository from 'base/api/AbstractApiRepository';

import AddAutoDto from './dto/AddAutoDto';
import AddWaterDto from './dto/AddWaterDto';
import UpdateAutoDto from './dto/UpdateAutoDto';
import UpdateBrandDto from './dto/UpdateBrandDto';
import UpdateDepositDto from './dto/UpdateDepositDto';
import UpdateStatusDto from './dto/UpdateStatusDto';
import UpdateTariffsDto from './dto/UpdateTariffsDto';
import UpdateWaterDto from './dto/UpdateWaterDto';

export default class TransportApiRepository extends AbstractApiRepository {
  addAuto = (data: AddAutoDto) => {
    return this.apiClient.post({ url: '/lessor/rentals/transport/auto', data });
  };

  addWater = (data: AddWaterDto) => {
    return this.apiClient.post({ url: '/lessor/rentals/transport/water', data });
  };

  addModel = (brandId: number, name: string) => {
    return this.apiClient.post({
      url: `/lessor/rentals/transport/models`,
      data: { brandId, name },
    });
  };

  getAutoTransport = (id: number, limit: number, offset: number, query: string) => {
    return this.apiClient.get({
      url: `/lessor/rentals/transport/list/${id}/auto?limit=${limit}&offset=${offset}${query}`,
    });
  };

  getWaterTransport = (id: number, limit: number, offset: number, categoryId?: string, queryString?: string) => {
    return this.apiClient.get({
      url: `/lessor/rentals/transport/list/${id}/water?limit=${limit}&offset=${offset}${categoryId}${queryString}`,
    });
  };

  getWaterTransportCategories = () => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/water-categories` });
  };

  getBrands = (limit: number, offset: number, query?: string) => {
    return this.apiClient.get({
      url: `/lessor/rentals/transport/brands?limit=${limit}&offset=${offset}${query}`,
    });
  };

  getModels = (id: number, limit: number, offset: number, query?: string) => {
    return this.apiClient.get({
      url: `/lessor/rentals/transport/brands/${id}/models?limit=${limit}&offset=${offset}${query}`,
    });
  };

  getFuelTypes = () => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/fuel-types` });
  };

  getDriveTypes = () => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/drive-types` });
  };

  getBodyTypes = () => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/body-types` });
  };

  getTransmissionTypes = () => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/transmission-types` });
  };

  getTransportInfo = (id: number) => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/view/${id}` });
  };

  updateBrand = (data: UpdateBrandDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/transport/updateBrand`, data });
  };

  updateAuto = (data: UpdateAutoDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/transport/update/auto`, data });
  };

  updateWater = (data: UpdateWaterDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/transport/update/water`, data });
  };

  updateTariffs = (data: UpdateTariffsDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/transport/updateTariffs`, data });
  };

  updateDeposit = (data: UpdateDepositDto, isAutoTransport: boolean) => {
    return this.apiClient.post({
      url: `/lessor/rentals/transport/${isAutoTransport ? 'updateDeposit' : 'updateWaterTransportDeposit'}`,
      data,
    });
  };

  updateStatus = (data: UpdateStatusDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/transport/updateStatus`, data });
  };

  deleteTransport = (transportId: number) => {
    return this.apiClient.delete({ url: `/lessor/rentals/transport/${transportId}` });
  };

  deleteTransportPhoto = (transportId: number, photoId: number) => {
    return this.apiClient.delete({ url: `/lessor/rentals/transport/photo/${transportId}/${photoId}` });
  };
}
