import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useLocation } from 'react-router-dom';
import { routes, authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import Routes from 'base/routes/components/Routes';
import BaseLayout from 'components/Layouts/BaseLayout';
import Loader from 'components/UI/Loader';

const App: React.FC = observer(() => {
  const { authStore, rentalStore } = useRootStore();
  const { pathname } = useLocation();

  // Effects
  useEffect(() => {
    authStore.checkAuth();

    if (authStore.isAuth) {
      rentalStore.getRental(authStore.logout);
      authStore.getUserInfo();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Renders
  const renderIsAuthStack = () => {
    return (
      <>
        <BaseLayout>
          <Routes routes={routes} />
        </BaseLayout>

        <ReactNotifications />
      </>
    );
  };

  const renderIsNotAuthStack = () => {
    return (
      <>
        <Routes redirectProps={{ to: authRoutes.LoginScreen.path }} routes={authRoutes} />

        <ReactNotifications />
      </>
    );
  };

  if (!authStore.completeCheckAuth) {
    return <Loader minHeight="100vh" />;
  }

  return authStore.isAuth ? renderIsAuthStack() : renderIsNotAuthStack();
});

export default App;
