import { ChevronRight20Filled } from '@fluentui/react-icons';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import { PhoneMaskInput } from 'components/UI/NumberMaskedInputs';
import QDatePicker from 'components/UI/QDatePicker';
import QInput from 'components/UI/textFields/QInput';
import DateHelper from 'helpers/DateHelper';
import { BookingFormFields } from 'modules/booking/forms/BookingForm';
import { BookingRenderHelper } from 'modules/booking/helpers/BookingRenderHelper';
import Period from 'modules/booking/models/Period';
import { BookingDates, BookingFormKeys, TimeTypes } from 'modules/booking/types/BookingTypes';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import Tariff from 'modules/transport/models/Tariff';
import { TypesOfTransport } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { declOfNum } from 'utils/declOfNum';

import MainFeaturesItem from '../components/MainFeaturesItem';

const WaterBookingScreen = observer(() => {
  const { bookingStore, transportStore, rentalStore } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [hoursCount, setHoursCount] = useState(0);
  const [freePeriods, setFreePeriods] = useState<Period[] | null>(null);

  const currentDay = new Date().getDate();
  const hoursNames = ['час', 'часа', 'часов'];

  const [date, setDate] = useState<Record<BookingDates, Date | null>>({
    [BookingDates.START_DATE]: null,
    [BookingDates.END_DATE]: null,
  });
  const [time, setTime] = useState({
    [TimeTypes.START_TIME]: '',
    [TimeTypes.END_TIME]: '',
  });

  // Effects
  useEffect(() => {
    if (transportStore.currentTransportInfo?.id !== Number(id)) {
      transportStore.setCurrentTransportInfo(null);
      transportStore.getTransportInfo(Number(id));
    }

    return () => {
      rentalStore.setSearchAddressData([]);
      bookingStore.setForm(BookingFormKeys.BOOKING, BookingFormFields.START_DATE, '');
      bookingStore.setForm(BookingFormKeys.BOOKING, BookingFormFields.END_DATE, '');
      bookingStore.setBookingSchedule([]);
    };
  }, [id]);

  useEffect(() => {
    if (bookingStore.bookingSchedule.length && !date[BookingDates.START_DATE]) {
      handleChangeDate(new Date());
    }
  }, [bookingStore.bookingSchedule]);

  useEffect(() => {
    if (transportStore.currentTransportInfo?.id || transportStore.currentTransportInfo?.id === 0) {
      bookingStore.setForm(
        BookingFormKeys.BOOKING,
        BookingFormFields.TRANSPORT_ID,
        transportStore.currentTransportInfo.id,
      );
      bookingStore.setForm(BookingFormKeys.BOOKING, BookingFormFields.DELIVERY, false);
      bookingStore.getBookingSchedule(
        transportStore.currentTransportInfo.id,
        DateHelper.formatDateForServer(new Date()),
      );
    }
  }, [transportStore.currentTransportInfo]);

  useEffect(() => {
    if (!!date[BookingDates.START_DATE] && !!time[TimeTypes.START_TIME] && !!time[TimeTypes.END_TIME]) {
      setHoursCount(DateHelper.getBookingHoursCount(time[TimeTypes.START_TIME], time[TimeTypes.END_TIME]) || 0);

      bookingStore.setForm(
        BookingFormKeys.BOOKING,
        BookingFormFields.START_DATE,
        DateHelper.formatDateForServer(date[BookingDates.START_DATE], time[TimeTypes.START_TIME]),
      );
      bookingStore.setForm(
        BookingFormKeys.BOOKING,
        BookingFormFields.END_DATE,
        DateHelper.formatDateForServer(
          date[BookingDates.END_DATE] || date[BookingDates.START_DATE],
          time[TimeTypes.END_TIME],
        ),
      );
    }
  }, [date, time]);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    bookingStore.setForm(BookingFormKeys.BOOKING, name as BookingFormFields, value);
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    bookingStore.setForm(BookingFormKeys.BOOKING, name as BookingFormFields, checked);
  };

  const handleChangeDate = (date: Date) => {
    setFreePeriods(BookingRenderHelper.getPeriods(bookingStore.bookingSchedule, date));

    setDate({
      [BookingDates.START_DATE]: date,
      [BookingDates.END_DATE]: date,
    });
  };

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTime({ ...time, [name]: value });
  };

  const handleChangeMonth = (date: Date) => {
    if (!transportStore.currentTransportInfo?.id && transportStore.currentTransportInfo?.id !== 0) {
      return;
    }

    bookingStore.getBookingSchedule(transportStore.currentTransportInfo?.id, DateHelper.formatDateForServer(date));
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    bookingStore.booking();
  };

  // Renders
  const renderTariffs = (tariffs: Tariff[]) => {
    return tariffs.map(item => {
      return (
        <MainFeaturesItem
          key={item.id}
          label={TransportRenderHelper.getTariffLabel(TypesOfTransport.WATER, item.start, item.end, item.isSingleDay)}
          value={`${item.price} ₽${!item.isSingleDay ? ' / час' : ''}`}
        />
      );
    });
  };

  const renderCurrentTariff = () => {
    const currentTariff = BookingRenderHelper.getCurrentTariff(
      hoursCount,
      transportStore.currentTransportInfo?.tariffs || [],
    );

    if (!currentTariff) {
      return <Typography variant="h4">тариф не найден</Typography>;
    }
    return (
      <>
        <Typography variant="h4">
          {hoursCount} {declOfNum(hoursCount, hoursNames)}: {(currentTariff.price || 1) * hoursCount} ₽
        </Typography>
        <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
          тариф:{' '}
          {TransportRenderHelper.getTariffLabel(
            TypesOfTransport.WATER,
            currentTariff.start,
            currentTariff.end,
            currentTariff.isSingleDay,
          )}
          : {currentTariff.price} ₽
        </Typography>
      </>
    );
  };

  const renderPeriods = () => {
    return freePeriods?.map((period, index) => {
      return <div key={index}>{`от ${period.start} до ${period.end}`}</div>;
    });
  };

  if (!transportStore.currentTransportInfo && transportStore.loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Box mb={6}>
        <Breadcrumbs separator={<ChevronRight20Filled />} aria-label="breadcrumb">
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={routes.WaterTransportScreen.path}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              Список водных транспортных средств
            </Typography>
          </Link>
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={`/transport/water/${transportStore.currentTransportInfo?.id}`}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              {transportStore.currentTransportInfo?.waterTransport?.name}
            </Typography>
          </Link>
          <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
            Бронирование
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={5}>
          <Box mb={0.5}>
            <Typography variant="h1">{transportStore.currentTransportInfo?.waterTransport?.name}</Typography>
          </Box>
          <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
            {transportStore.currentTransportInfo?.waterTransport?.category?.name}
          </Typography>
        </Box>
        <Box mb={5} display="flex">
          <Box mr={2} width={488}>
            <FormLabel>ФИО клиента</FormLabel>
            <TextField
              name={BookingFormFields.CLIENT_NAME}
              onChange={handleChangeValues}
              variant="outlined"
              fullWidth
              placeholder="Введите ФИО"
            />
          </Box>
          <Box mr={2} width={184}>
            <FormLabel>Телефон клиента</FormLabel>
            <TextField
              name={BookingFormFields.CLIENT_PHONE}
              onChange={handleChangeValues}
              variant="outlined"
              fullWidth
              placeholder="Введите номер"
              InputProps={{ inputComponent: PhoneMaskInput }}
            />
          </Box>
        </Box>
        <Box mb={5} className={classes.rateContainer}>
          {bookingStore.bookingForm[BookingFormFields.START_DATE] &&
          bookingStore.bookingForm[BookingFormFields.END_DATE] ? (
            <>
              <Typography variant="h4">{`${DateHelper.formatDateForBookingView(
                bookingStore.bookingForm[BookingFormFields.START_DATE],
              )} – ${DateHelper.formatDateForBookingView(
                bookingStore.bookingForm[BookingFormFields.END_DATE],
              )}`}</Typography>
              {renderCurrentTariff()}
            </>
          ) : (
            <Typography variant="h4">Выберите дату и время</Typography>
          )}
        </Box>
        <Box mb={5}>
          <Grid container justifyContent="space-between">
            <Grid item xs={5}>
              <Box mb={3}>
                <QDatePicker
                  onChange={handleChangeDate}
                  selected={date[BookingDates.START_DATE]}
                  minDate={new Date()}
                  excludeDates={BookingRenderHelper.getFilledDates(bookingStore.bookingSchedule)}
                  partFilledDates={BookingRenderHelper.getFilledDates(bookingStore.bookingSchedule, false)}
                  onMonthChange={handleChangeMonth}
                  inline
                />
              </Box>

              <Grid spacing={1} container>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.currentDate)}>
                    <Typography variant="caption">{currentDay}</Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Текущая дата
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.halfBusyDate)}>
                    <Typography variant="caption">{currentDay}</Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Дата частично занята
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.choosedDate)}>
                    <Typography className={commonClasses.onPrimaryPrimary} variant="caption">
                      {currentDay}
                    </Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Выбранная дата
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.busyDate)}>
                    <Typography className={commonClasses.onSurfaceTertiary} variant="caption">
                      {currentDay}
                    </Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Недоступная дата
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.freeDate)}>
                    <Typography variant="caption">{currentDay}</Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Свободная дата
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Box mb={1.5}>
                <Typography variant="body2">Начать аренду</Typography>
              </Box>
              <Box mb={1.5}>
                <QInput
                  name={TimeTypes.START_TIME}
                  value={time[TimeTypes.START_TIME]}
                  type="time"
                  onChange={handleChangeTime}
                  className={classes.time}
                  required
                />
              </Box>
              <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                водный транспорт свободен {renderPeriods()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box mb={1.5}>
                <Typography variant="body2">Завершить аренду</Typography>
              </Box>
              <Box mb={1.5}>
                <QInput
                  name={TimeTypes.END_TIME}
                  value={time[TimeTypes.END_TIME]}
                  type="time"
                  onChange={handleChangeTime}
                  className={classes.time}
                  required
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.blockContainer} mb={5}>
          <Box mb={5}>
            <FormControlLabel
              color="primary"
              control={
                <Checkbox name={BookingFormFields.PRE_PAYMENT} color={'primary'} onChange={handleToggleSwitches} />
              }
              label="Внесена предоплата"
            />
          </Box>
          <Grid container justifyContent="flex-end">
            <Button
              disabled={!bookingStore.bookingForm[BookingFormFields.START_DATE]}
              type="submit"
              variant="contained"
              color="primary"
            >
              Забронировать
            </Button>
          </Grid>
        </Box>
        <Box className={classes.blockContainer} mb={5}>
          <Box mb={2}>
            <Typography className={commonClasses.onSurfaceSecondary} variant="h5">
              Тарифы
            </Typography>
          </Box>
          <Grid container spacing={10}>
            <Grid item xs={4}>
              {renderTariffs(transportStore.tariffsColumns?.leftTariffsColumn || [])}
            </Grid>
            {!!transportStore.tariffsColumns?.rightTariffsColumn?.length && (
              <Grid item xs={5}>
                {renderTariffs(transportStore.tariffsColumns?.rightTariffsColumn || [])}
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  rateContainer: {
    padding: theme.spacing(3, 5),
    border: `1px solid ${theme.palette.custom.overlay}`,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  time: {
    maxWidth: 85,
    '& input': {
      minWidth: 75,
    },
  },
  blockContainer: {
    paddingTop: theme.spacing(5),
    borderTop: `1px dashed ${theme.palette.custom.overlay}`,
  },
  calendarLegend: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: 11,
  },
  currentDate: {
    border: `1px solid ${theme.palette.common.black}`,
  },
  choosedDate: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.custom.onPrimary.primary,
  },
  freeDate: {
    backgroundColor: theme.palette.custom.onPrimary.primary,
  },
  halfBusyDate: {
    background: `linear-gradient(90deg, ${theme.palette.custom.overlay} 50%, ${theme.palette.custom.onPrimary.primary} 50%)`,
    border: '1px solid rgba(33, 33, 33, 0.08)',
  },
  busyDate: {
    color: theme.palette.custom.onSurface.tertiary,
  },
}));

export default WaterBookingScreen;
