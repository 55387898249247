import { makeAutoObservable, runInAction, set } from 'mobx';

import PaginationModel from 'base/modules/pagination/PaginationModel';
import { TypesOfRental } from 'modules/rental/types/RentalTypes';
import { initialPagination } from 'modules/transport/consts/TransportConsts';

import { OrdersService } from './OrdersService';
import { ChangeOrderStatusForm, ChangeOrderStatusFormFields } from './forms/ChangeOrderStatusForm';
import OrderModel from './models/OrderModel';
import { OrdersFormFields, OrderStatuses } from './types/OrdersTypes';

export class OrdersStore {
  loading = false;
  hotLoading = false;
  actionLoading = false;
  statusesLoading = false;

  orders: OrderModel[] = [];
  statuses: Record<string, string> | null = null;
  statusesWithCount: Record<string, number> | null = null;
  declineReasons: Record<string, string> | null = null;

  changeOrderStatusForm = ChangeOrderStatusForm;

  pagination = initialPagination;

  private service: OrdersService;

  constructor() {
    makeAutoObservable(this);
    this.service = new OrdersService();
  }

  get isEndOfList() {
    const { totalCount, currentOffset } = this.pagination;

    return totalCount <= currentOffset;
  }

  initOrdersScreen = async (type?: OrderStatuses, rentalType?: TypesOfRental | null) => {
    await this.getStatusesWithCount();
    !type && (await this.getOrders());
    await this.getDeclineReasons(rentalType);
  };

  getOrders = async (isLoadMore: boolean = false, type?: OrderStatuses) => {
    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
      this.setPagination({ meta: initialPagination } as PaginationModel);
    }

    return this.service
      .getOrders(OrdersService.LIMIT, this.pagination.currentOffset, type)
      .then(({ orders, pagination }) => {
        if (isLoadMore && this.orders.length) {
          this.setOrders([...this.orders, ...orders]);
        } else {
          this.setOrders(orders);
        }

        this.setPagination(pagination);
      })
      .catch(err => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  getStatuses = async () => {
    this.setStatusesLoading(true);

    return this.service
      .getStatuses()
      .then(data => {
        runInAction(() => {
          this.statuses = data;
        });
      })
      .catch(err => {})
      .finally(() => {
        this.setStatusesLoading(false);
      });
  };

  getStatusesWithCount = async () => {
    this.setStatusesLoading(true);

    return this.service
      .getStatusesWithCount()
      .then(data => {
        runInAction(() => {
          this.statusesWithCount = data;
        });
      })
      .catch(err => {})
      .finally(() => {
        this.setStatusesLoading(false);
      });
  };

  getDeclineReasons = async (rentalType?: TypesOfRental | null) => {
    return this.service
      .getDeclineReasons(rentalType)
      .then(data => {
        runInAction(() => {
          this.declineReasons = data;
        });
      })
      .catch(err => {});
  };

  changeStatus = async () => {
    this.setActionLoading(true);

    return this.service
      .changeStatus(this.changeOrderStatusForm)
      .then(data => {
        const changedStatus = this.service.getOrderStatus(this.orders, data);
        this.setCountOfStatuses(changedStatus, this.changeOrderStatusForm.status);
        this.service.removeOrder(this.orders, this.setOrders, data);
      })
      .catch(err => {})
      .finally(() => {
        this.setActionLoading(false);
      });
  };

  setOrders = (data: OrderModel[]) => {
    this.orders = data;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setHotLoading = (value: boolean) => {
    this.hotLoading = value;
  };

  setActionLoading = (value: boolean) => {
    this.actionLoading = value;
  };

  setStatusesLoading = (value: boolean) => {
    this.statusesLoading = value;
  };

  setChangeStatusForm = (key: OrdersFormFields, value: any) => {
    set(this.changeOrderStatusForm, key, value);
  };

  setCountOfStatuses = (changedStatus: string, currentStatus: string) => {
    this.statusesWithCount = {
      ...this.statusesWithCount,
      [changedStatus]: Number(this.statusesWithCount?.[changedStatus]) - 1,
      [currentStatus]: Number(this.statusesWithCount?.[currentStatus]) + 1,
    };
  };

  resetChangeStatusForm = () => {
    Object.values(ChangeOrderStatusFormFields).forEach(fieldName => {
      this.setChangeStatusForm(fieldName, ChangeOrderStatusForm[fieldName]);
    });
  };

  setPagination = (pagination: PaginationModel) => {
    if (pagination.meta) {
      this.pagination = pagination.meta;
    }
  };
}
