import AbstractDto from 'base/AbstractDto';

export default class UpdateBrandDto extends AbstractDto {
  transportId: string = '';
  brandId: number = 0;
  modelId: number = 0;
  number: string = '';

  constructor(props: any) {
    super();
    this.load(props);
  }
}
