import { EyeHide24Regular, EyeShow24Regular } from '@fluentui/react-icons';
import { Box, IconButton, InputAdornment, InputProps, makeStyles, OutlinedInput, Theme } from '@material-ui/core';
import React, { useState } from 'react';

interface IPasswordInputProps extends InputProps {}

const PasswordInput: React.FC<IPasswordInputProps> = props => {
  const classes = useStyles();

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Box style={{ width: '100%' }}>
      <OutlinedInput
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className={classes.icon}
              onClick={() => setIsShowPassword(!isShowPassword)}
              onMouseDown={handleMouseDownPassword}
            >
              {isShowPassword ? <EyeHide24Regular /> : <EyeShow24Regular />}
            </IconButton>
          </InputAdornment>
        }
        type={isShowPassword ? 'text' : 'password'}
        fullWidth
        {...props}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    cursor: 'pointer',
  },
}));

export default PasswordInput;
