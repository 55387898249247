import AbstractFactory from 'base/AbstractFactory';

import BookingScheduleDay from './models/BookingScheduleDay';
import Period from './models/Period';

export class BookingFactory extends AbstractFactory {
  createBookingSchedule = (data: any[]) => {
    if (data?.length) {
      return data.map(this.createBookingScheduleDay);
    }

    return [];
  };

  createBookingScheduleDay = (data: any) => {
    const model = data;
    model.filledPeriods = this.createList<Period>(Period, data.filledPeriods);
    model.emptyPeriods = this.createList<Period>(Period, data.emptyPeriods);

    return this.create<BookingScheduleDay>(BookingScheduleDay, model);
  };
}
