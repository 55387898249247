import { DocumentsTypesFields } from '../types/OrdersTypes';

export const documentsTypes: Record<DocumentsTypesFields, string> = {
  passport_main_page: 'Паспорт (главная страница)',
  passport_registration: 'Паспорт (прописка)',
  driver_license_front: 'Лицевая сторона водительского удостоверения',
  driver_license_back: 'Задняя сторона водительского удостоверения',
  water_license_front: 'Права на водный транспорт 1ая сторона',
  water_license_back: 'Права на водный транспорт 2ая сторона',
};

export const ordersHeaderLinks = [
  {
    type: 'paid',
    label: 'На рассмотрении',
  },
  {
    type: 'confirmed',
    label: 'Одобрено',
  },
  {
    type: 'rent',
    label: 'Активные',
  },
  {
    type: 'completed',
    label: 'Завершённые',
  },
  {
    type: 'declined',
    label: 'Отклонённые',
  },
];
