import AbstractDto from 'base/AbstractDto';

export default class UpdateDepositDto extends AbstractDto {
  transportId: number = 0;
  status: string = '';

  constructor(props: any) {
    super();
    this.load(props);
  }
}
