import { Add20Regular } from '@fluentui/react-icons';
import { DialogProps, Box, Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import { RenderHelper } from 'helpers/RenderHelper';
import { ILocalTariff, initTariff } from 'modules/transport/consts/TransportConsts';
import { TariffFields } from 'modules/transport/forms/AddAutoForm';
import { UpdateTariffsFormFields } from 'modules/transport/forms/UpdateTariffsForm';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import { TransportFormKeys, TypesOfTransport } from 'modules/transport/types/TransportTypes';

import TariffItem from '../../cars/components/TariffItem';

interface IEditTariffsModalProps extends DialogProps {
  onClose: () => void;
  transportType: TypesOfTransport;
}

const EditTariffsModal: React.FC<IEditTariffsModalProps> = observer(({ onClose, transportType, ...rest }) => {
  const { TARIFFS } = UpdateTariffsFormFields;
  const { transportStore } = useRootStore();
  const [newTariffId, setNewTariffId] = useState(1);
  const classes = useStyles();

  // Handlers
  const handleChangeTariff = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tariffId: number) => {
    const { name, value } = event.target;
    const tariffs = transportStore.updateTariffsForm[TARIFFS];

    transportStore.setForm(
      TransportFormKeys.UPDATE_TARIFFS,
      TARIFFS,
      TransportRenderHelper.changeTariff(tariffs, tariffId, name as TariffFields, Number(value)),
    );
  };

  const handleSwitchTariffPeriod = (value: boolean, tariffId: number) => {
    const tariffs = transportStore.updateTariffsForm[TARIFFS];

    transportStore.setForm(
      TransportFormKeys.UPDATE_TARIFFS,
      TARIFFS,
      TransportRenderHelper.changeTariffPeriod(tariffs, tariffId, value),
    );
  };

  const handleAddTariff = () => {
    const tariffs = transportStore.updateTariffsForm[TARIFFS];
    let id = newTariffId + 1;

    setNewTariffId(id);
    tariffs.push({ ...initTariff, id: newTariffId });

    transportStore.setForm(TransportFormKeys.UPDATE_TARIFFS, TARIFFS, tariffs);
  };

  const handleDeleteTariff = (e: React.MouseEvent<HTMLButtonElement>, tariffId: number) => {
    const tariffs = transportStore.updateTariffsForm[TARIFFS];

    transportStore.setForm(
      TransportFormKeys.UPDATE_TARIFFS,
      TARIFFS,
      RenderHelper.deleteElem<ILocalTariff>(tariffs, tariffId, 'id'),
    );
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    transportStore.updateTariffs(onClose);
  };

  // Renders
  const renderTariffs = () => {
    return transportStore.updateTariffsForm[TARIFFS].map(item => {
      return (
        <TariffItem
          key={item.id}
          tariffId={item.id}
          handleDeleteTariff={handleDeleteTariff}
          handleChangeTariff={handleChangeTariff}
          defaultValues={item}
          handleSwitchTariffPeriod={handleSwitchTariffPeriod}
          transportType={transportType}
        />
      );
    });
  };

  return (
    <QModal onClose={onClose} {...rest} modalClassName={classes.modal}>
      <EditModalWrapper isForm={false} title="Редактировать тарифы" showBtns={false}>
        <form onSubmit={handleSubmit} className={classes.tariffsEditBtnsForm}>
          <Box mb={10}>{renderTariffs()}</Box>
          <div className={classes.tariffsEditBtns}>
            <Button onClick={handleAddTariff} variant="contained">
              <Box mr={1}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Add20Regular />
                </Grid>
              </Box>
              Добавить тариф
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Сохранить
            </Button>
          </div>
        </form>
      </EditModalWrapper>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  tariffsEditBtnsForm: {
    position: 'relative',
    height: '100%',
  },
  tariffsEditBtns: {
    display: 'flex',
    position: 'absolute',
    bottom: -96,
    width: '100%',
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  modal: {
    minHeight: 232,
  },
}));

export default EditTariffsModal;
