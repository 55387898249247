import { initAddress } from 'modules/rental/consts/RentalConsts';

export const BookingForm: IBookingForm = {
  transportId: 0,
  startDate: '',
  endDate: '',
  deposit: false,
  prePayment: false,
  delivery: false,
  clientName: '',
  clientPhone: '',
  address: null,
};

export interface IBookingForm {
  transportId: number;
  startDate: string;
  endDate: string;
  deposit: boolean;
  prePayment: boolean;
  delivery: boolean;
  clientName: string;
  clientPhone: string;
  address: typeof initAddress | null;
}

export enum BookingFormFields {
  TRANSPORT_ID = 'transportId',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  DEPOSIT = 'deposit',
  PRE_PAYMENT = 'prePayment',
  DELIVERY = 'delivery',
  CLIENT_NAME = 'clientName',
  CLIENT_PHONE = 'clientPhone',
  ADDRESS = 'address',
}
