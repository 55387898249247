import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import history from 'base/routes/history';
import QHelperText from 'components/UI/textFields/QHelperText';
import { RenderHelper } from 'helpers/RenderHelper';
import useQuery from 'hooks/useQuery';
import { ForgotPasswordFormFields, IForgotPasswordForm } from 'modules/auth/forms/ForgotPasswordForm';
import { AuthFormKeys, ErrorTypes } from 'modules/auth/types/AuthTypes';

import LoginCard from './components/LoginCard';
import PasswordInput from './components/PasswordInput';

interface INewPasswordScreenProps {}

const NewPasswordScreen: React.FC<INewPasswordScreenProps> = observer(() => {
  const { authStore } = useRootStore();
  const { HASH } = ForgotPasswordFormFields;
  const classes = useStyles();

  const query = useQuery();
  const hash = query.get(HASH);

  // Effects
  useEffect(() => {
    if (hash) {
      authStore.setForm(AuthFormKeys.FORGOT_PASSWORD_FORM, HASH, hash);
    } else {
      history.push(authRoutes.LoginScreen.path);
    }
  }, [hash]);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    authStore.setForm(AuthFormKeys.FORGOT_PASSWORD_FORM, name as ForgotPasswordFormFields, value);
    authStore.errorMessages?.newPassword?.length &&
      authStore.setErrors({ ...authStore.errorMessages, [ErrorTypes.NEW_PASSWORD]: null });
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    authStore.setNewPassword();
  };

  // Renders
  return (
    <LoginCard>
      <Box className={classes.form} component="form" onSubmit={handleSubmit}>
        <Box mb={6}>
          <Typography variant="h1">Новый пароль</Typography>
        </Box>
        <Box mb={5}>
          <input name={HASH} onChange={() => {}} value={HASH} hidden />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PasswordInput
                name={ForgotPasswordFormFields.PASSWORD}
                onChange={handleChangeValues}
                placeholder="Пароль"
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                name={ForgotPasswordFormFields.PASSWORD_CONFIRM}
                onChange={handleChangeValues}
                placeholder="Подтвердите пароль"
              />
            </Grid>
          </Grid>
          {authStore.errorMessages?.newPassword && <QHelperText errors={authStore.errorMessages?.newPassword} />}
        </Box>

        <Grid container spacing={3}>
          <Grid item>
            <Button
              disabled={RenderHelper.getIsFilledFields<IForgotPasswordForm>(
                authStore.forgotPasswordForm,
                ForgotPasswordFormFields.EMAIL,
              )}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.loginBtn}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LoginCard>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
  loginBtn: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export default NewPasswordScreen;
