import AbstractModel from 'base/AbstractModel';
import FileModel from 'modules/files/models/FileModel';

import { TransportStatuses } from '../types/TransportTypes';
import AutoInfoDetailed from './AutoInfoDetailed';
import Tariff from './Tariff';
import WaterInfoDetailed from './WaterInfoDetailed';

export default class TransportInfo extends AbstractModel {
  id: number | null = null;
  autoTransport: AutoInfoDetailed | null = null;
  waterTransport: WaterInfoDetailed | null = null;
  wrapper: FileModel | null = null;
  photos: FileModel[] | null = null;
  contract: FileModel | null = null;
  status: TransportStatuses | null = null;
  lineStatus: TransportStatuses | null = null;
  tariffs: Tariff[] | null = null;
  comment: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
