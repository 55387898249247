import AbstractApiRepository from 'base/api/AbstractApiRepository';

import ChangeOrderStatusDto from './dto/ChangeOrderStatusDto';

export default class OrdersApiRepository extends AbstractApiRepository {
  getOrders = (limit: number, offset: number, type: string) => {
    return this.apiClient.get({ url: `/lessor/orders?limit=${limit}&offset=${offset}${type}` });
  };

  getStatuses = () => {
    return this.apiClient.get({ url: `/lessor/orders/types` });
  };

  getStatusesWithCount = () => {
    return this.apiClient.get({ url: `/lessor/orders/typesWithCount` });
  };

  getDeclineReasons = () => {
    return this.apiClient.get({ url: `/lessor/orders/decline-reasons` });
  };

  changeStatus = (data: ChangeOrderStatusDto) => {
    return this.apiClient.post({ url: `/lessor/orders/changeStatus`, data });
  };
}
