import AbstractModel from 'base/AbstractModel';

import { PrivateFile } from './PrivateFile';

export class User extends AbstractModel {
  name: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  canEdit: boolean | null = null;
  privateFiles: PrivateFile[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
