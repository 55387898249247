import DateHelper from 'helpers/DateHelper';
import Tariff from 'modules/transport/models/Tariff';

import BookingScheduleDay from '../models/BookingScheduleDay';
import { BookingFillTypes, BookingPeriodTypes } from '../types/BookingTypes';

export class BookingRenderHelper {
  static getCurrentTariff = (periodCount: number, tariffs: Tariff[]) => {
    if (!tariffs) {
      return;
    }

    const currentTariff = tariffs.find(tariff => {
      if (!tariff.start) {
        return null;
      }

      if (!tariff.isSingleDay && !tariff.end) {
        return periodCount >= tariff.start;
      }

      return periodCount >= tariff.start && periodCount <= Number(tariff.end);
    });

    return currentTariff || tariffs.find(tariff => tariff.isSingleDay);
  };

  static getFilledDates = (bookingSchedule: BookingScheduleDay[] | null, isFullFilled: boolean = true) => {
    if (!bookingSchedule) {
      return;
    }

    const filledType = isFullFilled ? BookingFillTypes.FULL_FILLED : BookingFillTypes.PART_FILLED;

    return bookingSchedule.filter(item => item[filledType] && item.date).map(item => new Date(item.date || ''));
  };

  static getPeriods = (bookingSchedule: BookingScheduleDay[] | null, date: Date, isFilledPeriods: boolean = false) => {
    if (!bookingSchedule) {
      return [];
    }

    const periodsType = isFilledPeriods ? BookingPeriodTypes.FILLED_PERIODS : BookingPeriodTypes.EMPTY_PERIODS;
    const stringDate = date?.toLocaleString('sv').split(' ')[0];
    const scheduleDay = bookingSchedule.find(item => item.date === stringDate);

    if (!scheduleDay) {
      return [];
    }

    return (
      scheduleDay[periodsType]?.sort((a, b) => Number(a.start?.replace(':', '')) - Number(b.start?.replace(':', ''))) ||
      null
    );
  };

  private static removePaintedDaysClasses = () => {
    const removingClass = 'react-datepicker__day--in-selecting-range';

    document.querySelectorAll(`.${removingClass}`).forEach(item => {
      item.classList.remove(removingClass);
    });
  };

  private static handleDayHover = (e: Event) => {
    document.querySelectorAll('.react-datepicker__day').forEach((day, index) => {
      if (
        // @ts-ignore
        index <= Number(e.target?.getAttribute('eventIndex')) &&
        !day.classList.contains('react-datepicker__day--disabled')
      ) {
        day.classList.add('react-datepicker__day--in-selecting-range');
      }
    });
  };

  static removePaintedDays = () => {
    document.querySelector('.react-datepicker__month')?.addEventListener('mouseleave', this.removePaintedDaysClasses);

    this.removePaintedDaysClasses();

    // Необходимо для правильного отображения выделения дней
    document.querySelectorAll('.react-datepicker__day').forEach((item, index) => {
      if (!item.classList.contains('react-datepicker__day--disabled')) {
        item.setAttribute('eventIndex', index.toString());

        item.addEventListener('mouseenter', this.handleDayHover);
      }
    });
  };

  static removeMonthScreenListeners = () => {
    document
      .querySelector('.react-datepicker__month')
      ?.removeEventListener('mouseleave', this.removePaintedDaysClasses);

    document.querySelectorAll('.react-datepicker__day').forEach(item => {
      item.removeEventListener('mouseenter', this.handleDayHover);
    });
  };

  static getIsPartFilledDate = (dayOfYear: number, partFilledDates?: Date[], excludeDates?: Date[]) => {
    return !!partFilledDates?.find(
      item =>
        DateHelper.getDayOfYear(item) === dayOfYear &&
        !excludeDates?.find(item => dayOfYear === DateHelper.getDayOfYear(item)),
    );
  };
}
