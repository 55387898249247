import { Box, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';

import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { AddAutoFormFields } from 'modules/transport/forms/AddAutoForm';
import { IUpdateAutoForm } from 'modules/transport/forms/UpdateAutoForm';
import DetailType from 'modules/transport/models/DetailType';
import { Insurances } from 'modules/transport/types/AddAutoTypes';
import { useCommonStyles } from 'styles/commonStyles';

interface IDefaultSettingsForm {
  handleChangeValues: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  fuelTypes: DetailType[];
  driveTypes: DetailType[];
  bodyTypes: DetailType[];
  transmissionTypes: DetailType[];
  defaultValues?: IUpdateAutoForm | null;
  isTransportCard?: boolean;
}

const DefaultSettingsForm: React.FC<IDefaultSettingsForm> = observer(props => {
  const {
    ENGINE_POWER,
    ENGINE_VOLUME,
    COLOR,
    RACING,
    MAX_SPEED,
    ISSUE_YEAR,
    MUSIC,
    DRIVE,
    TRANSMISSION,
    BODY_TYPE,
    FUEL_TYPE,
    FUEL_CONSUMPTION,
    INSURANCES,
    RERUN_PRICE,
    WASH_FEE,
  } = AddAutoFormFields;
  const {
    handleChangeValues,
    fuelTypes,
    driveTypes,
    bodyTypes,
    transmissionTypes,
    defaultValues,
    isTransportCard,
  } = props;
  const commonClasses = useCommonStyles();

  // Renders
  const renderFuelTypes = () => {
    return fuelTypes.map(fuelType => {
      if (!fuelType.key) {
        return <></>;
      }

      return (
        <MenuItem key={fuelType.key} value={fuelType.key}>
          {fuelType.value}
        </MenuItem>
      );
    });
  };

  const renderDriveTypes = () => {
    return driveTypes.map(driveTypes => {
      if (!driveTypes.key) {
        return <></>;
      }

      return (
        <MenuItem key={driveTypes.key} value={driveTypes.key}>
          {driveTypes.value}
        </MenuItem>
      );
    });
  };

  const renderBodyTypes = () => {
    return bodyTypes.map(bodyTypes => {
      if (!bodyTypes.key) {
        return <></>;
      }

      return (
        <MenuItem key={bodyTypes.key} value={bodyTypes.key}>
          {bodyTypes.value}
        </MenuItem>
      );
    });
  };

  const renderTransmissionTypes = () => {
    return transmissionTypes.map(transmissionTypes => {
      if (!transmissionTypes.key) {
        return <></>;
      }

      return (
        <MenuItem key={transmissionTypes.key} value={transmissionTypes.key}>
          {transmissionTypes.value}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <QInput
              value={defaultValues?.[ENGINE_VOLUME] || undefined}
              name={ENGINE_VOLUME}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Двигатель, объем, л."
              inputProps={{
                min: 0,
                step: 0.01,
                lang: 'en-US',
              }}
            />
          </Grid>
          <Grid xs={6} item>
            <QInput
              value={defaultValues?.[ENGINE_POWER] || undefined}
              name={ENGINE_POWER}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Двигатель, мощность, л.с."
              inputProps={{
                min: 0,
                step: 0.01,
                lang: 'en-US',
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <InputLabel className={commonClasses.onSurfacePrimary}>Привод</InputLabel>
            <Select
              defaultValue=""
              value={
                defaultValues &&
                driveTypes.length &&
                RenderHelper.getValueInRange(defaultValues[DRIVE], driveTypes, 'key')
              }
              name={DRIVE}
              fullWidth
              variant="outlined"
              onChange={handleChangeValues}
            >
              {renderDriveTypes()}
            </Select>
          </Grid>
          <Grid xs={6} item>
            <InputLabel className={commonClasses.onSurfacePrimary}>Коробка</InputLabel>
            <Select
              defaultValue=""
              value={
                defaultValues &&
                transmissionTypes.length &&
                RenderHelper.getValueInRange(defaultValues[TRANSMISSION], transmissionTypes, 'key')
              }
              name={TRANSMISSION}
              fullWidth
              variant="outlined"
              onChange={handleChangeValues}
            >
              {renderTransmissionTypes()}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <QInput
              value={defaultValues?.[RACING] || undefined}
              name={RACING}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="0-100 км/ч, с."
              inputProps={{
                min: 0,
                step: 0.01,
                lang: 'en-US',
              }}
            />
          </Grid>
          <Grid xs={6} item>
            <QInput
              value={defaultValues?.[MAX_SPEED] || undefined}
              name={MAX_SPEED}
              onChange={handleChangeValues}
              label="Макс. скорость, км/ч."
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              inputProps={{
                min: 0,
                step: 0.01,
                lang: 'en-US',
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid justifyContent="space-between" container spacing={2}>
          <Grid xs={6} item>
            <InputLabel className={commonClasses.onSurfacePrimary}>Топливо</InputLabel>
            <Select
              defaultValue=""
              value={
                defaultValues &&
                fuelTypes.length &&
                RenderHelper.getValueInRange(defaultValues[FUEL_TYPE], fuelTypes, 'key')
              }
              name={FUEL_TYPE}
              fullWidth
              variant="outlined"
              onChange={handleChangeValues}
            >
              {renderFuelTypes()}
            </Select>
          </Grid>
          <Grid xs={6} item>
            <QInput
              value={defaultValues?.[FUEL_CONSUMPTION] || undefined}
              name={FUEL_CONSUMPTION}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Расход, л. на 100 км."
              inputProps={{
                min: 0,
                step: 0.01,
                lang: 'en-US',
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid xs={4} item>
            <InputLabel className={commonClasses.onSurfacePrimary}>Кузов</InputLabel>
            <Select
              defaultValue=""
              value={
                defaultValues &&
                bodyTypes.length &&
                RenderHelper.getValueInRange(defaultValues[BODY_TYPE], bodyTypes, 'key')
              }
              name={BODY_TYPE}
              fullWidth
              variant="outlined"
              onChange={handleChangeValues}
            >
              {renderBodyTypes()}
            </Select>
          </Grid>
          <Grid xs={4} item>
            <QInput
              value={defaultValues?.[COLOR] || undefined}
              name={COLOR}
              onChange={handleChangeValues}
              label="Цвет"
            />
          </Grid>
          <Grid xs={4} item>
            <QInput
              value={defaultValues?.[ISSUE_YEAR] || undefined}
              name={ISSUE_YEAR}
              onChange={handleChangeValues}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              label="Год выпуска"
              inputProps={{
                min: 1900,
                max: 2100,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={4}>
        <QInput value={defaultValues?.[MUSIC] || undefined} name={MUSIC} onChange={handleChangeValues} label="Музыка" />
      </Box>
      <Box mb={4}>
        <QInput
          value={defaultValues?.[RERUN_PRICE] || undefined}
          name={RERUN_PRICE}
          onChange={handleChangeValues}
          label="Доплата за перепробег"
          type="number"
          onKeyDown={RenderHelper.disableInputKeyDownEvent}
          onWheel={RenderHelper.disableInputWheelEvent}
          inputProps={{
            min: 0,
          }}
        />
      </Box>
      {isTransportCard ? (
        <Box mb={4}>
          <InputLabel className={commonClasses.onSurfacePrimary}>Страховка</InputLabel>
          <Select
            value={defaultValues?.[INSURANCES] || undefined}
            name={INSURANCES}
            onChange={handleChangeValues}
            variant="outlined"
            fullWidth
            multiple
          >
            <MenuItem value={Insurances.KASKO}>КАСКО</MenuItem>
            <MenuItem value={Insurances.OSAGO}>ОСАГО</MenuItem>
          </Select>
        </Box>
      ) : null}
      {isTransportCard ? (
        <Box mb={4}>
          <QInput
            value={defaultValues?.[WASH_FEE] || undefined}
            name={WASH_FEE}
            onChange={handleChangeValues}
            label="Доплата за мойку"
            type="number"
            onKeyDown={RenderHelper.disableInputKeyDownEvent}
            onWheel={RenderHelper.disableInputWheelEvent}
            inputProps={{
              min: 0,
              step: 0.01,
            }}
          />
        </Box>
      ) : null}
    </>
  );
});

export default DefaultSettingsForm;
