export const UpdateDepositForm = {
  transportId: 0,
  amount: 0,
};

export interface IUpdateDepositForm {
  transportId: number;
  amount: number;
}

export enum UpdateDepositFormFields {
  TRANSPORT_ID = 'transportId',
  AMOUNT = 'amount',
}
