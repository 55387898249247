import { DocumentBulletList24Regular } from '@fluentui/react-icons';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

import FileModel from 'modules/files/models/FileModel';
import { useCommonStyles } from 'styles/commonStyles';

interface IDocumentItem {
  document?: FileModel | null;
  isOrdersScreen?: boolean;
  title?: string | null;
  handleClick?: () => void;
  icon?: React.ReactNode;
}

const DocumentItem: React.FC<IDocumentItem> = props => {
  const { document, handleClick, isOrdersScreen = false, title = null, icon } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Renders
  const renderInner = () => {
    return (
      <>
        <Box className={classes.iconContainer} mr={1}>
          {icon || <DocumentBulletList24Regular />}
        </Box>
        <Typography className={commonClasses.textOverflowEllipsis} variant="body1">
          {title || 'Правила аренды автосалона'}
        </Typography>
      </>
    );
  };

  return (
    <>
      {isOrdersScreen ? (
        <div onClick={handleClick} className={classes.documentContainer}>
          {renderInner()}
        </div>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={document?.fullUrl || ''}
          className={clsx(classes.documentContainer, { disabled: !document?.fullUrl })}
        >
          {renderInner()}
        </a>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    padding: 8,
    border: `1px solid ${theme.palette.custom.onSurface.secondary}`,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    maxWidth: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '&.disabled': {
      pointerEvents: 'none',
    },
  },
  iconContainer: {
    display: 'flex',
  },
}));

export default DocumentItem;
