import { ChevronRight20Filled } from '@fluentui/react-icons';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import UploadDocument from 'components/UI/UploadDocument';
import { RenderHelper } from 'helpers/RenderHelper';
import FileModel from 'modules/files/models/FileModel';
import { ILocalTariff, initTariff } from 'modules/transport/consts/TransportConsts';
import { AddWaterFormFields, TariffFields } from 'modules/transport/forms/AddWaterForm';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import { TransportFormKeys, TypesOfTransport } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import TariffsList from '../cars/components/TariffsList';
import PhotoList from '../components/PhotoList';
import AddWaterTransportSettingsForm from './components/AddWaterTransportSettingsForm';
import CategoryForm from './components/CategoryForm';

const AddWaterTransportScreen = observer(() => {
  const { transportStore, filesStore, rentalStore } = useRootStore();
  const { setForm } = transportStore;
  const { NEED_LICENSE, TARIFFS, PHOTOS, CONTRACT, WRAPPER, OFFLINE } = AddWaterFormFields;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [newTariffId, setNewTariffId] = useState(1);

  // Effects
  useEffect(() => {
    return () => {
      filesStore.clearStore();
    };
  }, []);

  useEffect(() => {
    if (filesStore.pdf) {
      setForm(TransportFormKeys.ADD_WATER, CONTRACT, filesStore.pdf.uuid);
    }
  }, [filesStore.pdf]);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    setForm(TransportFormKeys.ADD_WATER, name as AddWaterFormFields, value);
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setForm(TransportFormKeys.ADD_WATER, name as AddWaterFormFields, checked);
  };

  const handleChangeTariff = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tariffId: number) => {
    const { name, value } = event.target;
    const tariffs = transportStore.addWaterForm[TARIFFS];

    setForm(
      TransportFormKeys.ADD_WATER,
      TARIFFS,
      TransportRenderHelper.changeTariff(tariffs, tariffId, name as TariffFields, Number(value)),
    );
  };

  const handleSwitchTariffPeriod = (value: boolean, tariffId: number) => {
    const tariffs = transportStore.addWaterForm[TARIFFS];

    transportStore.setForm(
      TransportFormKeys.ADD_WATER,
      TARIFFS,
      TransportRenderHelper.changeTariffPeriod(tariffs, tariffId, value),
    );
  };

  const handleAddTariff = () => {
    const tariffs = [...transportStore.addWaterForm[TARIFFS]];
    let id = newTariffId + 1;

    setNewTariffId(id);
    tariffs.push({ ...initTariff, id: newTariffId });

    setForm(TransportFormKeys.ADD_WATER, TARIFFS, tariffs);
  };

  const handleDeleteTariff = (e: React.MouseEvent<HTMLButtonElement>, tariffId: number) => {
    const tariffs = [...transportStore.addWaterForm[TARIFFS]];
    const elemsWithoutDeleted = RenderHelper.deleteElem<ILocalTariff>(tariffs, tariffId, 'id');

    setForm(TransportFormKeys.ADD_WATER, TARIFFS, elemsWithoutDeleted);
  };

  const handleAddPhotos = (images: FileModel[]) => {
    const uuids = images.map(item => item.uuid);

    setForm(TransportFormKeys.ADD_WATER, PHOTOS, uuids);
  };

  const handleAddWrapper = (image: FileModel) => {
    setForm(TransportFormKeys.ADD_WATER, WRAPPER, image.uuid);
  };

  const handleAddContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    filesStore.uploadPdf(e.target.files[0]);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    transportStore.addWater(rentalStore.currentRental?.id);
  };

  // Renders
  return (
    <Container maxWidth="lg">
      <Box mb={6}>
        <Breadcrumbs separator={<ChevronRight20Filled />} aria-label="breadcrumb">
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={routes.WaterTransportScreen.path}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              Список водных транспортных средств
            </Typography>
          </Link>
          <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
            Добавить водный транспорт
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={7}>
          <Typography variant="h1">Добавить водный транспорт</Typography>
        </Box>
        <Box mb={5}>
          <CategoryForm
            getCategories={transportStore.getWaterTransportCategories}
            handleChangeValues={handleChangeValues}
            categories={transportStore.waterTransportCategories}
          />
        </Box>
        <Box mb={5}>
          <PhotoList canEdit handleAddWrapper={handleAddWrapper} handleAddPhotos={handleAddPhotos} />
        </Box>

        <Grid xs={7} item>
          <Box mb={5}>
            <Box mb={3}>
              <Typography variant="h3">Основные характеристики</Typography>
            </Box>
            <AddWaterTransportSettingsForm
              handleChangeValues={handleChangeValues}
              isDeposit={!!rentalStore.currentRental?.deposit}
            />

            <Box mb={2}>
              <FormControlLabel
                control={<Switch name={NEED_LICENSE} onChange={handleToggleSwitches} color="primary" />}
                label="Требуются права"
              />
            </Box>
            <Box mb={5}>
              <FormControlLabel
                color="primary"
                control={<Switch name={OFFLINE} onChange={handleToggleSwitches} color="primary" />}
                label="Снять водный транспорт с линии"
              />
            </Box>

            <Typography variant="h3">Загрузите документ:</Typography>
            <Typography className={classes.lineText} variant="body1">
              Будет отображаться в карточке водного транспорта
            </Typography>
            <Box mt={2}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Правила аренды</Typography>
                <UploadDocument handleUploadDocument={handleAddContract} document={filesStore.pdf} />
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Box mb={5}>
          <TariffsList
            handleAddTariff={handleAddTariff}
            handleChangeTariff={handleChangeTariff}
            handleDeleteTariff={handleDeleteTariff}
            tariffs={transportStore.addWaterForm[TARIFFS]}
            handleSwitchTariffPeriod={handleSwitchTariffPeriod}
            transportType={TypesOfTransport.WATER}
          />
        </Box>

        <Button type="submit" variant="contained" color="primary">
          Добавить водный транспорт
        </Button>
      </form>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    color: theme.palette.custom.onSurface.tertiary,
  },
  inputLabel: {
    margin: 'auto 0',
  },
  inputContainer: {
    display: 'flex',
  },
  lineText: {
    color: theme.palette.custom.onSurface.secondary,
  },
}));

export default AddWaterTransportScreen;
