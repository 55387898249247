import { makeStyles, Theme } from '@material-ui/core';

interface ILoginCardProps {
  children?: React.ReactNode;
}

const LoginCard: React.FC<ILoginCardProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.containerWrap}>
      <div className={classes.container}>
        <div className={classes.innerWrap}>
          <div className={classes.inner}>
            <div className={classes.cardWrap}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  containerWrap: {
    position: 'fixed',
    inset: 0,
    zIndex: 2,
    backgroundColor: 'white',
  },
  container: {
    height: '100%',
    textAlign: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::after': {
      content: '""',
      width: '0',
      height: '100%',
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  innerWrap: {
    display: 'inline-block',
    width: '100%',
    maxWidth: 390 + theme.spacing(4),
    margin: theme.spacing(4),
    verticalAlign: 'middle',
    textAlign: 'left',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardWrap: {
    position: 'relative',
    width: '100%',
  },
}));

export default LoginCard;
