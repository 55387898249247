import AbstractApiRepository from 'base/api/AbstractApiRepository';

import RefreshAddressAndPhoneDto from './dto/RefreshAddressAndPhoneDto';
import RefreshDefaultSettingsDto from './dto/RefreshDefaultSettingsDto';
import RefreshScheduleDto from './dto/RefreshScheduleDto';

export default class RentalApiRepository extends AbstractApiRepository {
  getRental = () => {
    return this.apiClient.get({ url: `/lessor/rentals` });
  };

  refreshAddressAndPhone = (data: RefreshAddressAndPhoneDto) => {
    return this.apiClient.post({ url: '/lessor/rentals/address', data });
  };

  refreshDefaultSettings = (data: RefreshDefaultSettingsDto) => {
    return this.apiClient.post({ url: '/lessor/rentals/settings', data });
  };

  refreshSchedule = (data: RefreshScheduleDto) => {
    return this.apiClient.post({ url: '/lessor/rentals/schedule', data });
  };

  refreshPreview = (uuid: string) => {
    return this.apiClient.post({ url: '/lessor/rentals/update-preview', data: { uuid: uuid } });
  };

  refreshLogo = (uuid: string) => {
    return this.apiClient.post({ url: '/lessor/rentals/update-logo', data: { uuid: uuid } });
  };

  searchAddress = (query: string) => {
    return this.apiClient.get({ url: `/geo/search-by-address-name/?query=${query}` });
  };
}
