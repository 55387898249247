import AbstractModel from 'base/AbstractModel';

import { Insurances } from '../types/AddAutoTypes';
import Brand from './Brand';
import Model from './Model';

export default class AutoInfoDetailed extends AbstractModel {
  id: number | null = null;
  brand: Brand | null = null;
  model: Model | null = null;
  stateNumber: string | null = null;
  enginePower: number | null = null;
  engineVolume: number | null = null;
  drive: string | null = null;
  transmission: string | null = null;
  racing: number | null = null;
  maxSpeed: number | null = null;
  fuelType: string | null = null;
  fuelConsumption: number | null = null;
  bodyType: string | null = null;
  color: string | null = null;
  issueYear: number | null = null;
  insurances: Insurances[] | null = null;
  music: string | null = null;
  deliveryPrice: number | null = null;
  deposit: number | null = null;
  speedLimit: number | null = null;
  mileageLimit: number | null = null;
  fuelCostPerLiter: number | null = null;
  rerunPrice: number | null = null;
  washFee: number | null = null;
  travelAboard: boolean | null = null;
  travelOutOfCity: boolean | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
