import { Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { RenderHelper } from 'helpers/RenderHelper';
import { AddAutoFormFields } from 'modules/transport/forms/AddAutoForm';
import { StateNumberParts } from 'modules/transport/types/AddAutoTypes';

const initStateNumberParts = {
  [StateNumberParts.PART_1]: '',
  [StateNumberParts.PART_2]: '',
};

interface INumberInput {
  handleChangeValuesNoEvent: (name: string, value: string) => void;
  fieldName?: string;
  defaultValue?: string | null;
}

const NumberInput: React.FC<INumberInput> = ({
  handleChangeValuesNoEvent,
  fieldName = AddAutoFormFields.STATE_NUMBER,
  defaultValue = '',
}) => {
  const classes = useStyles();
  const [stateNumberParts, setStateNumberParts] = useState<Record<StateNumberParts, string>>(initStateNumberParts);

  // Effects
  useEffect(() => {
    if (defaultValue) {
      setStateNumberParts({
        [StateNumberParts.PART_1]: RenderHelper.getStringWithEmpty(defaultValue.split('|')[0], defaultValue),
        [StateNumberParts.PART_2]: RenderHelper.getStringWithEmpty(defaultValue.split('|')[1]),
      });
    }
  }, []);

  useEffect(() => {
    const fullStateNumber =
      stateNumberParts[StateNumberParts.PART_1].trim() +
      (stateNumberParts[StateNumberParts.PART_2].trim()
        ? ` | ${stateNumberParts[StateNumberParts.PART_2].trim()}`
        : '');

    if (fullStateNumber || fullStateNumber === '') {
      handleChangeValuesNoEvent(fieldName, fullStateNumber);
    }
  }, [stateNumberParts]);

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setStateNumberParts({ ...stateNumberParts, [name]: value });
  };

  // Renders
  return (
    <>
      <InputLabel>Госномер</InputLabel>
      <Grid item container>
        <Grid item xs={4}>
          <TextField
            name={StateNumberParts.PART_1}
            onChange={handleChange}
            placeholder="А000АА"
            className={classes.numberLeftInput}
            variant="outlined"
            defaultValue={RenderHelper.getStringWithEmpty(defaultValue?.split('|')[0] || '', defaultValue)}
            inputProps={{
              maxLength: 10,
              minLength: 5,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <NumberFormat
            name={StateNumberParts.PART_2}
            onChange={handleChange}
            placeholder="000"
            customInput={TextField}
            className={classes.numberRightInput}
            displayType={'input'}
            format="###"
            variant="outlined"
            defaultValue={defaultValue?.split('|')[1] || ''}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  numberLeftInput: {
    '& div': {
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',

      '& input': {
        textAlight: 'center',
      },
    },
  },
  numberRightInput: {
    '& div': {
      borderTopLeftRadius: '0 !important',
      borderBottomLeftRadius: '0 !important',

      '& input': {
        textAlight: 'center !important',
      },
    },
  },
}));

export default NumberInput;
