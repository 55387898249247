import { DialogProps, Box, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { UpdateWaterFormFields } from 'modules/transport/forms/UpdateWaterForm';
import { TransportFormKeys } from 'modules/transport/types/TransportTypes';

interface IEditCategoryModalProps extends DialogProps {
  onClose: () => void;
}

const EditCategoryModal: React.FC<IEditCategoryModalProps> = observer(({ onClose, ...rest }) => {
  const { transportStore } = useRootStore();

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    transportStore.setForm(TransportFormKeys.UPDATE_WATER, name as UpdateWaterFormFields, value);
  };

  const handleSave = () => {
    transportStore.updateWater(onClose);
  };

  // Renders
  const renderCategories = () => {
    return transportStore.waterTransportCategories.map(item => {
      return (
        <MenuItem key={item.id} value={item.id || 0}>
          {item.name}
        </MenuItem>
      );
    });
  };

  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper title="Изменить название, категорию" onClose={onClose} onSave={handleSave}>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <InputLabel>Категория</InputLabel>
              <Select
                name={UpdateWaterFormFields.CATEGORY_ID}
                onChange={handleChangeValues}
                fullWidth
                variant="outlined"
                defaultValue=""
                value={RenderHelper.getValueInRange(
                  transportStore.updateWaterForm[UpdateWaterFormFields.CATEGORY_ID],
                  transportStore.waterTransportCategories,
                )}
                required
              >
                {renderCategories()}
              </Select>
            </Grid>
            <Grid xs={6} item>
              <QInput
                label="Наименование"
                name={UpdateWaterFormFields.NAME}
                onChange={handleChangeValues}
                value={transportStore.updateWaterForm[UpdateWaterFormFields.NAME]}
                required
              />
            </Grid>
          </Grid>
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

export default EditCategoryModal;
