export const ForgotPasswordForm = {
  email: '',
  hash: '',
  password: '',
  passwordConfirm: '',
};

export interface IForgotPasswordForm {
  email: string;
  hash: string;
  password: string;
  passwordConfirm: string;
}

export enum ForgotPasswordFormFields {
  EMAIL = 'email',
  HASH = 'hash',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
}
