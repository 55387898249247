import { makeAutoObservable, set } from 'mobx';

import RentalService from './RentalService';
import { RefreshAddressAndPhoneForm, RefreshAddressAndPhoneFormFields } from './forms/RefreshAddressAndPhoneForm';
import { RefreshDefaultSettingsForm, RefreshDefaultSettingsFormFields } from './forms/RefreshDefaultSettingsForm';
import { RentalRenderHelper } from './helpers/RentalRenderHelper';
import Address from './models/Address';
import Rental from './models/Rental';
import { RentalFormKeys, RentalFormFields, ILocalScheduleData } from './types/RentalTypes';

export class RentalStore {
  loading = false;
  globalLoading = false;
  searchAddressData: Address[] = [];

  currentRental: Rental | null = null;
  formattedSchedule: ILocalScheduleData | null = null;

  refreshAddressAndPhoneForm = RefreshAddressAndPhoneForm;
  refreshDefaultSettingsForm = RefreshDefaultSettingsForm;

  private rentalService: RentalService;

  constructor() {
    makeAutoObservable(this);
    this.rentalService = new RentalService();
  }

  initRentalScreen = () => {
    if (!this.currentRental?.id || !this.currentRental?.phone || !this.currentRental?.address?.unrestrictedValue) {
      return;
    }

    this.setSearchAddressData([]);

    Object.entries(this.currentRental)?.forEach(([key, value]) => {
      this.setForm(RentalFormKeys.REFRESH_DEFAULT_SETTINGS, key as RefreshDefaultSettingsFormFields, value);
    });

    this.setForm(
      RentalFormKeys.REFRESH_DEFAULT_SETTINGS,
      RefreshDefaultSettingsFormFields.DELIVERY,
      !!this.currentRental?.deliveryPrice,
    );

    this.setForm(
      RentalFormKeys.REFRESH_ADDRESS_AND_PHONE,
      RefreshAddressAndPhoneFormFields.PHONE,
      this.currentRental?.phone,
    );

    this.setForm(
      RentalFormKeys.REFRESH_ADDRESS_AND_PHONE,
      RefreshAddressAndPhoneFormFields.ADDRESS,
      this.currentRental?.address,
    );

    this.setFormattedSchedule(
      RentalRenderHelper.formatSchedule(this.currentRental.schedule || [], !!this.currentRental.isRoundTheClock),
    );
  };

  getRental = (cbLogout?: () => void) => {
    this.setGlobalLoading(true);

    this.rentalService
      .getRental()
      .then(rental => {
        this.setCurrentRental(rental);
      })
      .catch(error => {
        if (error.response?.status === 422) {
          cbLogout && cbLogout();
        }
      })
      .finally(() => {
        this.setGlobalLoading(false);
      });
  };

  refreshAddressAndPhone = (cb?: () => void) => {
    this.setLoading(true);

    this.rentalService
      .refreshAddressAndPhone(this.refreshAddressAndPhoneForm)
      .then(data => {
        this.setCurrentRental(data);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  refreshDefaultSettings = (cb?: () => void) => {
    this.setLoading(true);

    this.rentalService
      .refreshDefaultSettings(this.refreshDefaultSettingsForm)
      .then(data => {
        this.setCurrentRental(data);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  refreshSchedule = (data: ILocalScheduleData, cb?: () => void) => {
    this.setLoading(true);

    this.rentalService
      .refreshSchedule(data)
      .then(data => {
        this.setCurrentRental(data);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  refreshPreview = (uuid: string) => {
    this.setLoading(true);

    this.rentalService
      .refreshPreview(uuid)
      .then(data => {
        this.setCurrentRental(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  refreshLogo = (uuid: string) => {
    this.setLoading(true);

    this.rentalService
      .refreshLogo(uuid)
      .then(data => {
        this.setCurrentRental(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  searchAddress = async (query: string) => {
    this.setLoading(true);

    this.rentalService
      .searchAddress(query)
      .then(data => {
        this.setSearchAddressData(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  resetCurrentRental = () => {
    this.currentRental = null;
  };

  setCurrentRental = (rental: Rental) => {
    this.currentRental = rental;
  };

  setFormattedSchedule = (data: ILocalScheduleData) => {
    this.formattedSchedule = data;
  };

  setForm = (formKey: RentalFormKeys, key: RentalFormFields, value: any) => {
    set(this[formKey], key, value);
  };

  setSearchAddressData = (data: Address[]) => {
    this.searchAddressData = data;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setGlobalLoading = (value: boolean) => {
    this.globalLoading = value;
  };
}
