import { ChevronRight20Filled } from '@fluentui/react-icons';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
  Breadcrumbs,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import UploadDocument from 'components/UI/UploadDocument';
import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import FileModel from 'modules/files/models/FileModel';
import { ILocalTariff, initTariff } from 'modules/transport/consts/TransportConsts';
import { AddAutoFormFields, TariffFields } from 'modules/transport/forms/AddAutoForm';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import { Insurances } from 'modules/transport/types/AddAutoTypes';
import { TransportFormKeys, TypesOfTransport } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import PhotoList from '../components/PhotoList';
import DefaultSettingsForm from './components/DefaultSettingsForm';
import MarkForm from './components/MarkForm';
import TariffsList from './components/TariffsList';

const AddTransportScreen = observer(() => {
  const { transportStore, filesStore, rentalStore } = useRootStore();
  const { setForm } = transportStore;
  const {
    SPEED_LIMIT,
    MILEAGE_LIMIT,
    DELIVERY_PRICE,
    DEPOSIT,
    TARIFFS,
    WRAPPER,
    PHOTOS,
    CONTRACT,
    INSURANCES,
    OFFLINE,
    COMMENT,
    WASH_FEE,
    TRAVEL_ABOARD,
    TRAVEL_OUT_OF_CITY,
  } = AddAutoFormFields;

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [newTariffId, setNewTariffId] = useState(1);

  // Effects
  useEffect(() => {
    transportStore.getAllDetails();
    transportStore.resetAddAutoForm();
    document.addEventListener('keydown', handleLockEnter);

    return () => {
      filesStore.clearStore();
      document.removeEventListener('keydown', handleLockEnter);
    };
  }, []);

  useEffect(() => {
    if (filesStore.pdf) {
      setForm(TransportFormKeys.ADD_AUTO, CONTRACT, filesStore.pdf.uuid);
    }
  }, [filesStore.pdf]);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    setForm(TransportFormKeys.ADD_AUTO, name as AddAutoFormFields, value);
  };

  const handleChangeValuesNoEvent = (name: string, value: string | number) => {
    setForm(TransportFormKeys.ADD_AUTO, name as AddAutoFormFields, value);
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setForm(TransportFormKeys.ADD_AUTO, name as AddAutoFormFields, checked);
  };

  const handleZeroingValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      return;
    }

    setForm(TransportFormKeys.ADD_AUTO, name as AddAutoFormFields, 0);
  };

  const handleChangeInsurances = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const insurancesArr: string[] = transportStore.addAutoForm[INSURANCES];

    if (checked) {
      insurancesArr.push(name);
    } else {
      insurancesArr.filter(item => item !== name);
    }

    setForm(TransportFormKeys.ADD_AUTO, INSURANCES, insurancesArr);
  };

  const handleChangeTariff = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tariffId: number) => {
    const { name, value } = event.target;
    const tariffs = transportStore.addAutoForm[TARIFFS];

    setForm(
      TransportFormKeys.ADD_AUTO,
      TARIFFS,
      TransportRenderHelper.changeTariff(tariffs, tariffId, name as TariffFields, Number(value)),
    );
  };

  const handleSwitchTariffPeriod = (value: boolean, tariffId: number) => {
    const tariffs = transportStore.addAutoForm[TARIFFS];

    transportStore.setForm(
      TransportFormKeys.ADD_AUTO,
      TARIFFS,
      TransportRenderHelper.changeTariffPeriod(tariffs, tariffId, value),
    );
  };

  const handleAddTariff = () => {
    const tariffs = transportStore.addAutoForm[TARIFFS];
    let id = newTariffId + 1;

    setNewTariffId(id);
    tariffs.push({ ...initTariff, id: newTariffId });

    setForm(TransportFormKeys.ADD_AUTO, TARIFFS, tariffs);
  };

  const handleDeleteTariff = (e: React.MouseEvent<HTMLButtonElement>, tariffId: number) => {
    const tariffs = transportStore.addAutoForm[TARIFFS];

    setForm(TransportFormKeys.ADD_AUTO, TARIFFS, RenderHelper.deleteElem<ILocalTariff>(tariffs, tariffId, 'id'));
  };

  const handleAddPhotos = (images: FileModel[]) => {
    const uuids = images.map(item => item.uuid);

    setForm(TransportFormKeys.ADD_AUTO, PHOTOS, uuids);
  };

  const handleAddWrapper = (image: FileModel) => {
    setForm(TransportFormKeys.ADD_AUTO, WRAPPER, image.uuid);
  };

  const handleAddContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    filesStore.uploadPdf(e.target.files[0]);
  };

  const handleLockEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return false;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    transportStore.addAuto(rentalStore.currentRental?.id);
  };

  // Renders
  return (
    <Container maxWidth="lg">
      <Box mb={6}>
        <Breadcrumbs separator={<ChevronRight20Filled />} aria-label="breadcrumb">
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={routes.AutoTransportScreen.path}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              Список автомобилей
            </Typography>
          </Link>
          <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
            Добавить автомобиль
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={7}>
          <Typography variant="h1">Добавить автомобиль</Typography>
        </Box>
        <Box mb={5}>
          <MarkForm handleChangeValuesNoEvent={handleChangeValuesNoEvent} />
        </Box>
        <Box mb={5}>
          <PhotoList canEdit handleAddWrapper={handleAddWrapper} handleAddPhotos={handleAddPhotos} />
        </Box>

        <Grid xs={7} item>
          <Box mb={5}>
            <Box mb={3}>
              <Typography variant="h3">Основные характеристики</Typography>
            </Box>
            <DefaultSettingsForm
              handleChangeValues={handleChangeValues}
              fuelTypes={transportStore.fuelTypes}
              driveTypes={transportStore.driveTypes}
              bodyTypes={transportStore.bodyTypes}
              transmissionTypes={transportStore.transmissionTypes}
            />
            {rentalStore.currentRental?.delivery ? (
              <Box mb={4}>
                <QInput
                  name={DELIVERY_PRICE}
                  onChange={handleChangeValues}
                  label="Доставка"
                  type="number"
                  onKeyDown={RenderHelper.disableInputKeyDownEvent}
                  onWheel={RenderHelper.disableInputWheelEvent}
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                />
              </Box>
            ) : null}
            <Box mb={4}>
              <QInput
                name={WASH_FEE}
                onChange={handleChangeValues}
                label="Доплата за мойку"
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                inputProps={{
                  min: 0,
                  step: 0.01,
                }}
              />
            </Box>
            <Box mb={4}>
              <QInput fullWidth multiline name={COMMENT} onChange={handleChangeValues} label="Комментарий" />
            </Box>
            {rentalStore.currentRental?.deposit ? (
              <>
                <Box mb={1.5}>
                  <FormControlLabel
                    color="primary"
                    control={
                      <Switch
                        color={'primary'}
                        name={DEPOSIT}
                        onChange={handleZeroingValues}
                        checked={!!transportStore.addAutoForm[DEPOSIT]}
                      />
                    }
                    label="Залог"
                  />
                </Box>

                <Box mb={3}>
                  <QInput
                    name={DEPOSIT}
                    type="number"
                    onKeyDown={RenderHelper.disableInputKeyDownEvent}
                    onWheel={RenderHelper.disableInputWheelEvent}
                    value={transportStore.addAutoForm[DEPOSIT]}
                    inputProps={{
                      min: 0,
                      step: 0.01,
                    }}
                    onChange={handleChangeValues}
                  />
                </Box>
              </>
            ) : null}
            <Box mb={1}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormControlLabel
                    color="primary"
                    control={
                      <Switch
                        color={'primary'}
                        name={TRAVEL_OUT_OF_CITY}
                        onChange={handleToggleSwitches}
                        checked={transportStore.addAutoForm[TRAVEL_OUT_OF_CITY]}
                      />
                    }
                    label="Выезд за город"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    color="primary"
                    control={
                      <Switch
                        color={'primary'}
                        name={TRAVEL_ABOARD}
                        onChange={handleToggleSwitches}
                        checked={transportStore.addAutoForm[TRAVEL_ABOARD]}
                      />
                    }
                    label="Выезд за границу"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={1}>
              <Grid container spacing={4}>
                {rentalStore.currentRental?.speedLimit ? (
                  <Grid item xs={6}>
                    <FormControlLabel
                      color="primary"
                      control={
                        <Switch
                          color={'primary'}
                          name={SPEED_LIMIT}
                          onChange={handleZeroingValues}
                          checked={!!transportStore.addAutoForm[SPEED_LIMIT]}
                        />
                      }
                      label="Ограничения скорости"
                    />
                    <Grid alignContent="center" spacing={1} container>
                      <Grid className={classes.inputContainer} item>
                        <Typography className={classes.inputLabel} variant="body1">
                          до
                        </Typography>
                      </Grid>
                      <Grid xs={6} item>
                        <NumberFormat
                          name={SPEED_LIMIT}
                          onChange={handleChangeValues}
                          customInput={QInput}
                          displayType="input"
                          format="###"
                          value={transportStore.addAutoForm[SPEED_LIMIT]}
                          inputProps={{
                            min: 0,
                            step: 0.01,
                          }}
                        />
                      </Grid>
                      <Grid className={classes.inputContainer} item>
                        <Typography className={classes.inputLabel} variant="body1">
                          км/час
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {rentalStore.currentRental?.mileageLimit ? (
                  <Grid item xs={6}>
                    <FormControlLabel
                      color="primary"
                      control={
                        <Switch
                          color={'primary'}
                          name={MILEAGE_LIMIT}
                          onChange={handleZeroingValues}
                          checked={!!transportStore.addAutoForm[MILEAGE_LIMIT]}
                        />
                      }
                      label="Ограничения км в день"
                    />
                    <Grid alignContent="center" spacing={1} container>
                      <Grid className={classes.inputContainer} item>
                        <Typography className={classes.inputLabel} variant="body1">
                          до
                        </Typography>
                      </Grid>
                      <Grid xs={6} item>
                        <NumberFormat
                          name={MILEAGE_LIMIT}
                          onChange={handleChangeValues}
                          customInput={QInput}
                          displayType="input"
                          value={transportStore.addAutoForm[MILEAGE_LIMIT]}
                          inputProps={{
                            min: 0,
                            step: 0.01,
                          }}
                        />
                      </Grid>
                      <Grid className={classes.inputContainer} item>
                        <Typography className={classes.inputLabel} variant="body1">
                          км/день
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
            <Box mb={5}>
              <Grid spacing={4} container>
                <Grid item xs={6}>
                  <Box mb={1.5}>
                    <Typography variant="body1">Страховка:</Typography>
                  </Box>
                  <Grid container spacing={4}>
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox color="primary" name={Insurances.KASKO} onChange={handleChangeInsurances} />}
                        label="КАСКО"
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox color="primary" name={Insurances.OSAGO} onChange={handleChangeInsurances} />}
                        label="ОСАГО"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel
              control={<Switch name={OFFLINE} onChange={handleToggleSwitches} color="primary" />}
              label="Снять машину с линии"
            />
            <Box mb={5}>
              <Typography className={classes.lineText} variant="body1" color="secondary">
                Машина не будет доступна для бронирования и не будет отображаться в каталоге, пока вы не вернете ее на
                линию.
              </Typography>
            </Box>
            <Typography variant="h3">Загрузите документ:</Typography>
            <Typography className={classes.lineText} variant="body1">
              Будет отображаться в карточке машины
            </Typography>
            <Box mt={2}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Typography variant="body1">Правила аренды автосалона</Typography>
                <UploadDocument handleUploadDocument={handleAddContract} document={filesStore.pdf} />
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Box mb={5}>
          <TariffsList
            handleAddTariff={handleAddTariff}
            handleChangeTariff={handleChangeTariff}
            tariffs={transportStore.addAutoForm[TARIFFS]}
            handleDeleteTariff={handleDeleteTariff}
            handleSwitchTariffPeriod={handleSwitchTariffPeriod}
            transportType={TypesOfTransport.AUTO}
          />
        </Box>

        <Button type="submit" variant="contained" color="primary">
          Добавить автомобиль
        </Button>
      </form>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    color: theme.palette.custom.onSurface.tertiary,
  },
  inputLabel: {
    margin: 'auto 0',
  },
  inputContainer: {
    display: 'flex',
  },
  lineText: {
    color: theme.palette.custom.onSurface.secondary,
  },
}));

export default AddTransportScreen;
