import AbstractDto from 'base/AbstractDto';

export default class UpdateTariffsDto extends AbstractDto {
  transportId: number = 0;
  tariffs: Object[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
