import { Insurances } from '../types/AddAutoTypes';
import { TransportStatuses } from '../types/TransportTypes';

export const UpdateAutoForm: IUpdateAutoForm = {
  id: 0,
  enginePower: 0,
  engineVolume: 0,
  drive: '',
  transmission: '',
  racing: 0,
  maxSpeed: 0,
  fuelType: '',
  fuelConsumption: 0,
  bodyType: '',
  color: '',
  issueYear: 0,
  music: '',
  speedLimit: 0,
  lineStatus: TransportStatuses.FREE,
  mileageLimit: 0,
  fuelCostPerLiter: 0,
  deliveryPrice: 0,
  photos: [],
  wrapper: '',
  contract: '',
  insurances: [],
  comment: '',
  rerunPrice: 0,
  washFee: 0,
  travelAboard: false,
  travelOutOfCity: false,
};

export interface IUpdateAutoForm {
  id: number;
  enginePower: number;
  engineVolume: number;
  drive: string;
  transmission: string;
  racing: number;
  maxSpeed: number;
  fuelType: string;
  fuelConsumption: number;
  bodyType: string;
  color: string;
  issueYear: number;
  music: string;
  speedLimit: number;
  lineStatus: TransportStatuses;
  mileageLimit: number;
  fuelCostPerLiter: number;
  deliveryPrice: number;
  photos: string[];
  wrapper: string;
  contract: string;
  insurances: Insurances[];
  comment: string;
  rerunPrice: number;
  washFee: number;
  travelAboard: boolean;
  travelOutOfCity: boolean;
}

export enum UpdateAutoFormFields {
  ID = 'id',
  ENGINE_POWER = 'enginePower',
  ENGINE_VOLUME = 'engineVolume',
  DRIVE = 'drive',
  TRANSMISSION = 'transmission',
  RACING = 'racing',
  MAX_SPEED = 'maxSpeed',
  FUEL_TYPE = 'fuelType',
  FUEL_CONSUMPTION = 'fuelConsumption',
  BODY_TYPE = 'bodyType',
  COLOR = 'color',
  ISSUE_YEAR = 'issueYear',
  MUSIC = 'music',
  SPEED_LIMIT = 'speedLimit',
  MILEAGE_LIMIT = 'mileageLimit',
  FUEL_COST_PER_LITER = 'fuelCostPerLiter',
  DELIVERY_PRICE = 'deliveryPrice',
  PHOTOS = 'photos',
  WRAPPER = 'wrapper',
  CONTRACT = 'contract',
  LINE_STATUS = 'lineStatus',
  INSURANCES = 'insurances',
  COMMENT = 'comment',
  RERUN_PRICE = 'rerunPrice',
  WASH_FEE = 'washFee',
  TRAVEL_ABOARD = 'travelAboard',
  TRAVEL_OUT_OF_CITY = 'travelOutOfCity',
}
