export class RenderHelper {
  static deleteElem<T>(arr: T[], index: string | number, indexName: string): T[] {
    return arr.filter((item: any) => {
      return index !== item[indexName];
    });
  }

  static getEndOfPage = () => {
    const totalHeight = document.documentElement.scrollHeight;
    const currentPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const currentOffset = window.innerHeight;

    const isEndOfPage = totalHeight - (currentPosition + currentOffset) < 200;

    return isEndOfPage;
  };

  static getEndOfBlock = (block: HTMLUListElement | HTMLDivElement | null, minValue = 200) => {
    if (!block) {
      return;
    }

    const { scrollHeight, scrollTop, clientHeight } = block;
    const isEndOfBlock = scrollHeight - (scrollTop + clientHeight) < minValue;

    return isEndOfBlock;
  };

  // email is default check
  static checkIsValid = (value: string, regexp: RegExp = /^[a-zA-Z0-9_-]+@(?:[a-zA-Z0-9_-]+\.)+[A-Za-z]+$/) => {
    return regexp.test(value);
  };

  static getIsFilledFields<T extends Object>(fields: T, excludedFields?: string | string[]) {
    return Object.entries(fields).some(([key, value]) => {
      return !value && (key !== excludedFields || (Array.isArray(excludedFields) && !excludedFields?.includes(key)));
    });
  }

  static getIsAvailable = (value?: boolean | null) => {
    if (value) {
      return 'Есть';
    } else {
      return 'Нет';
    }
  };

  static getValueInRange = (
    value?: string | number | null,
    availableValues?: any[] | null,
    paramName: string = 'id',
    returningValue: string | null = null,
  ) => {
    if ((!value && value !== 0) || !availableValues) {
      return;
    }

    const findValue = availableValues.find(item => {
      return item[paramName].toString().toLowerCase() === value.toString().toLowerCase();
    });

    if (findValue) {
      return returningValue ? findValue[returningValue] : value;
    }

    return '';
  };

  static formatPhoneString = (phone: string | null) => {
    if (!phone) {
      return;
    }

    if (phone.length !== 11) {
      return phone;
    }

    // Было сделано для неправильных номеров
    if (phone.charAt(0) === '+') {
      return `${phone.slice(0, 2)} ${phone.slice(2, 5)} ${phone.slice(6, 8)} ${phone.slice(8, 10)} ${phone.slice(
        10,
        12,
      )}`;
    }

    return `+${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9, 11)}`;
  };

  static getStringWithEmpty = (value: string | null, extraValue: string | null = '') => {
    if (value === null || extraValue === null) {
      return '';
    }

    if (!!value?.length || value === '') {
      return value;
    } else {
      return extraValue;
    }
  };

  static disableInputWheelEvent = () => {
    // @ts-ignore
    document.activeElement?.blur();
  };

  static disableInputKeyDownEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
}
