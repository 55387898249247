import AbstractApiRepository from 'base/api/AbstractApiRepository';

import LoginDto from './dto/LoginDto';
import RegisterDto from './dto/RegisterDto';
import SetNewPasswordDto from './dto/SetNewPasswordDto';

export default class AuthApiRepository extends AbstractApiRepository {
  register = (data: RegisterDto) => {
    return this.apiClient.post({
      url: `/lessor/auth/registration`,
      data,
    });
  };

  login = (data: LoginDto) => {
    return this.apiClient.post({ url: `/lessor/auth/login`, data });
  };

  logout = () => {
    return this.apiClient.post({ url: `/lessor/auth/logout` });
  };

  forgotPassword = (email: string) => {
    return this.apiClient.post({ url: '/lessor/auth/forgot-password', data: { email } });
  };

  setNewPassword = (data: SetNewPasswordDto) => {
    return this.apiClient.post({ url: '/lessor/auth/reset-password', data });
  };

  getUserInfo = () => {
    return this.apiClient.get({ url: '/lessor/user/info' });
  };
}
