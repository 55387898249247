import AbstractModel from 'base/AbstractModel';

import Period from './Period';

export default class BookingScheduleDay extends AbstractModel {
  date: string | null = null;
  empty: boolean | null = null;
  partFilled: boolean | null = null;
  fullFilled: boolean | null = null;
  filledPeriods: Period[] | null = null;
  emptyPeriods: Period[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
