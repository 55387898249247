import AbstractModel from 'base/AbstractModel';

export default class FileModel extends AbstractModel {
  id: number | null = null;
  uuid: string | null = null;
  status: string | null = null;
  fileName: string | null = null;
  fullUrl: string | null = null;
  extension: string | null = null;
  type: string | null = null;
  size: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
