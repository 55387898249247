import { Box, Button, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { appConfig } from 'appConfig';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

import QHelperText from 'components/UI/textFields/QHelperText';
import { RenderHelper } from 'helpers/RenderHelper';
import { ILoginForm, LoginForm, LoginFormFields } from 'modules/auth/forms/LoginForm';
import { AuthFormKeys, ErrorTypes } from 'modules/auth/types/AuthTypes';
import { useCommonStyles } from 'styles/commonStyles';

import { useRootStore } from '../../base/hooks/useRootStore';
import { authRoutes } from '../../routes/routes';
import LoginCard from './components/LoginCard';
import PasswordInput from './components/PasswordInput';

interface ILoginScreenProps {}

const LoginScreen: React.FC<ILoginScreenProps> = observer(() => {
  const { authStore, rentalStore } = useRootStore();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  // Effects
  useEffect(() => {
    return () => authStore.resetStore<ILoginForm>(AuthFormKeys.LOGIN_FORM, LoginForm);
  }, []);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    authStore.setForm(AuthFormKeys.LOGIN_FORM, name as LoginFormFields, value);
    authStore.errorMessages?.login?.length &&
      authStore.setErrors({ ...authStore.errorMessages, [ErrorTypes.LOGIN]: null });
  };

  const handleSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    recaptchaRef.current
      ?.executeAsync()
      .then(data => {
        authStore.setForm(AuthFormKeys.LOGIN_FORM, LoginFormFields.G_RECAPTCHA_RESPONSE, data);

        authStore.login(rentalStore.getRental);
      })
      .catch(err => {
        console.error('Ошибка капчи');
      });
  };

  // Renders
  return (
    <LoginCard>
      <Box
        className={classes.form}
        component="form"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Box mb={6}>
          <Typography variant="h1">Вход</Typography>
        </Box>

        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name={LoginFormFields.EMAIL}
                onChange={handleChangeValues}
                fullWidth
                variant="outlined"
                placeholder="Логин"
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                name={LoginFormFields.PASSWORD}
                onChange={handleChangeValues}
                placeholder="Пароль"
                autoComplete="on"
              />
            </Grid>
          </Grid>
          {authStore.errorMessages?.login && <QHelperText errors={authStore.errorMessages?.login} />}
        </Box>

        <Grid container spacing={3}>
          <Grid item>
            <Button
              disabled={RenderHelper.getIsFilledFields<ILoginForm>(
                authStore.loginForm,
                LoginFormFields.G_RECAPTCHA_RESPONSE,
              )}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.loginBtn}
            >
              Войти
            </Button>
          </Grid>
          <Grid item>
            <Link className={commonClasses.textDecorationNone} to={authRoutes.ChangePasswordScreen.path}>
              <Button variant="text" disableRipple>
                Забыли пароль?
              </Button>
            </Link>
          </Grid>
        </Grid>
        <ReCAPTCHA className={classes.capcha} ref={recaptchaRef} sitekey={appConfig.sitekey} size="invisible" />
      </Box>
    </LoginCard>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
  loginBtn: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  capcha: {
    visibility: 'hidden',
  },
}));

export default LoginScreen;
