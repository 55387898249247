import { Box, Button, Container, debounce, Tab, Tabs, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import PaginationModel from 'base/modules/pagination/PaginationModel';
import { RenderHelper } from 'helpers/RenderHelper';
import { initialPagination } from 'modules/transport/consts/TransportConsts';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import { TransportPaginationTypes } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import SearchInput from '../components/SearchInput';
import TransportList from '../components/TransportList';

const WaterTransportScreen = observer(() => {
  const { transportStore, rentalStore, authStore } = useRootStore();
  const commonClasses = useCommonStyles();

  const [tabIndex, setTabIndex] = React.useState<number | null>(null);
  const [value, setValue] = useState('');

  const searchApiCall = useCallback(
    debounce((value: string) => {
      transportStore.getWaterTransport(rentalStore.currentRental?.id, tabIndex, value);
    }, 400),
    [rentalStore.currentRental?.id, tabIndex],
  );

  // Effects
  useEffect(() => {
    transportStore.getWaterTransportCategories();

    document.addEventListener('scroll', handleUpdate);

    return () => {
      document.removeEventListener('scroll', handleUpdate);
    };
  }, [rentalStore.currentRental?.id]);

  useEffect(() => {
    if (transportStore.waterTransportCategories.length && !transportStore.loading && rentalStore.currentRental?.id) {
      const transportId = TransportRenderHelper.getFirstCategoryWithTransport(transportStore.waterTransportCategories)
        ?.id;
      if (!transportId && transportId !== 0) {
        return;
      }

      setTabIndex(transportId);
      transportStore.getWaterTransport(rentalStore.currentRental?.id, transportId);
    }
  }, [transportStore.waterTransportCategories, rentalStore.currentRental]);

  // Handlers
  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    if (newValue === tabIndex) {
      return;
    }

    setTabIndex(newValue);
    transportStore.setPagination(
      { meta: initialPagination } as PaginationModel,
      TransportPaginationTypes.WATER_TRANSPORT,
    );
    transportStore.getWaterTransport(rentalStore.currentRental?.id, newValue, value);
  };

  const handleUpdate = () => {
    const isEndOfPage = RenderHelper.getEndOfPage();

    if (
      isEndOfPage &&
      !transportStore.getIsEndOfList(TransportPaginationTypes.WATER_TRANSPORT) &&
      transportStore.waterTransport.length &&
      !transportStore.loading
    ) {
      transportStore.getWaterTransport(rentalStore.currentRental?.id, tabIndex, value, true);
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (transportStore[TransportPaginationTypes.WATER_TRANSPORT].currentOffset) {
      transportStore.setPagination(
        { meta: initialPagination } as PaginationModel,
        TransportPaginationTypes.WATER_TRANSPORT,
      );
    }

    setValue(event.target.value);
    searchApiCall(event.target.value);
  };

  // Renders
  const renderTabs = () => {
    return transportStore.waterTransportCategories.map(item => {
      if (!item.transportCount) {
        return null;
      }

      return <Tab key={item.id} label={item.name} value={item.id} />;
    });
  };

  return (
    <Container maxWidth="md">
      <Box mb={4}>
        <Typography className={commonClasses.fontWeight700} variant="h1">
          {rentalStore.currentRental?.title}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h1">Водный транспорт</Typography>
      </Box>

      <Box mb={4}>
        {(tabIndex || tabIndex === 0) && (
          <Tabs variant="scrollable" scrollButtons="auto" value={tabIndex} onChange={handleChangeTab}>
            {renderTabs()}
          </Tabs>
        )}
      </Box>

      <Box mb={4} display="flex" justifyContent="space-between">
        {authStore.userInfo?.canEdit && (
          <Link className={commonClasses.textDecorationNone} to={routes.AddWaterTransportScreen.path}>
            <Button variant="contained" color="primary">
              Добавить единицу аренды
            </Button>
          </Link>
        )}

        <SearchInput handleChangeValue={handleChangeValue} placeholder="Найти по наименованию" />
      </Box>

      <TransportList waterTransportList={transportStore.waterTransport} />
    </Container>
  );
});

export default WaterTransportScreen;
