export const CalendarForm: ICalendarForm = {
  date: null,
  query: '',
};

export interface ICalendarForm {
  date: Date | null;
  query: string;
}

export enum CalendarFormFields {
  DATE = 'date',
  QUERY = 'query',
}
