import AbstractModel from 'base/AbstractModel';

export class SuccessAuth extends AbstractModel {
  tokenType: string | null = null;
  accessToken: string | null = null;
  expiresIn: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
