import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class FilesApiRepository extends AbstractApiRepository {
  uploadImage = (data: FormData) => {
    return this.apiClient.post({ url: `/file/upload-image-file`, data });
  };

  uploadImages = (data: FormData) => {
    return this.apiClient.post({ url: `/file/upload-image-files`, data });
  };

  uploadPdf = (data: FormData) => {
    return this.apiClient.post({ url: `/file/upload-pdf-file`, data });
  };

  uploadPdfs = (data: FormData) => {
    return this.apiClient.post({ url: `/file/upload-pdf-files`, data });
  };

  getPrivateFile = (uuid: string) => {
    return this.apiClient.get({ url: `/private-file/${uuid}`, config: { responseType: 'blob' } });
  };
}
