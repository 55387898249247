import AbstractFactory from 'base/AbstractFactory';
import FileModel from 'modules/files/models/FileModel';

import AutoInfoDetailed from './models/AutoInfoDetailed';
import AutoTransport from './models/AutoTransport';
import AutoTransportModel from './models/AutoTransportModel';
import Brand from './models/Brand';
import Model from './models/Model';
import Tariff from './models/Tariff';
import TransportInfo from './models/TransportInfo';
import WaterInfoDetailed from './models/WaterInfoDetailed';
import WaterTransport from './models/WaterTransport';
import WaterTransportCategory from './models/WaterTransportCategory';
import WaterTransportModel from './models/WaterTransportModel';

export class TransportFactory extends AbstractFactory {
  createAutoTransportList = (data: any[]) => {
    if (data?.length) {
      return data?.map(this.createAutoTransportModel);
    }

    return [];
  };

  createWaterTransportList = (data: any[]) => {
    if (data?.length) {
      return data?.map(this.createWaterTransportModel);
    }

    return [];
  };

  createCategoriesList = (data: any[]) => {
    if (data?.length) {
      return data?.map(this.createWaterTransportCategory);
    }

    return [];
  };

  createFilesList = (data: any[]) => {
    if (data?.length) {
      return data?.map(this.createFile);
    }

    return [];
  };

  createTariffsList = (data: any[]) => {
    if (data?.length) {
      return data?.map(this.createTariff);
    }

    return [];
  };

  createAutoTransportModel = (data: any) => {
    const model = data;
    model.wrapper = this.createFile(data?.wrapper);
    model.transport = this.createAutoTransport(data?.autoTransport);

    return this.create<AutoTransportModel>(AutoTransportModel, model);
  };

  createTransportInfo = (data: any) => {
    const model = data || {};
    model.autoTransport = this.createAutoInfoDetailed(data?.autoTransport);
    model.waterTransport = this.createWaterInfoDetailed(data?.waterTransport);
    model.wrapper = this.createFile(data?.wrapper);
    model.photos = this.createFilesList(data?.photos);
    model.contract = this.createFile(data?.contract);
    model.tariffs = this.createTariffsList(data?.tariffs);

    return this.create<TransportInfo>(TransportInfo, model);
  };

  createWaterTransportModel = (data: any) => {
    const model = data;
    model.wrapper = this.createFile(data?.wrapper);
    model.transport = this.createWaterTransport(data?.waterTransport);

    return this.create<WaterTransportModel>(WaterTransportModel, model);
  };

  createAutoTransport = (data: any) => {
    const model = data;
    model.brand = this.createBrand(data?.brand);
    model.model = this.createModel(data?.model);

    return this.create<AutoTransport>(AutoTransport, model);
  };

  createWaterTransport = (data: any) => {
    const model = data;
    model.category = this.createWaterTransportCategory(data?.category);

    return this.create<WaterTransport>(WaterTransport, model);
  };

  createBrand = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<Brand>(Brand, data);
  };

  createModel = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<Model>(Model, data);
  };

  createTariff = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<Tariff>(Tariff, data);
  };

  createWaterTransportCategory = (data: any) => {
    return this.create<WaterTransportCategory>(WaterTransportCategory, data);
  };

  createFile = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<FileModel>(FileModel, data);
  };

  createWaterInfoDetailed = (data: any) => {
    if (!data) {
      return null;
    }

    return this.create<WaterInfoDetailed>(WaterInfoDetailed, data);
  };

  createAutoInfoDetailed = (data: any) => {
    if (!data) {
      return null;
    }

    return this.create<AutoInfoDetailed>(AutoInfoDetailed, data);
  };
}
