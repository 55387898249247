import { ChevronDown24Filled } from '@fluentui/react-icons';
import { DialogProps, Box, Grid, InputLabel, makeStyles, Theme, TextField, debounce } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import PaginationModel from 'base/modules/pagination/PaginationModel';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import { RenderHelper } from 'helpers/RenderHelper';
import { useRefWithCallback } from 'hooks/useRefWithCallback';
import { initialPagination } from 'modules/transport/consts/TransportConsts';
import { UpdateBrandFormFields } from 'modules/transport/forms/UpdateBrandForm';
import Brand from 'modules/transport/models/Brand';
import Model from 'modules/transport/models/Model';
import { TransportFormKeys, TransportPaginationTypes } from 'modules/transport/types/TransportTypes';

import NumberInput from '../NumberInput';

interface IEditMarkModalProps extends DialogProps {
  onClose: () => void;
}

const EditMarkModal: React.FC<IEditMarkModalProps> = observer(({ onClose, ...rest }) => {
  const { transportStore } = useRootStore();
  const [searchBrandsValue, setSearchBrandsValue] = useState('');
  const [searchModelsValue, setSearchModelsValue] = useState('');
  const setBrandsListRef = useRefWithCallback(
    node => node.addEventListener('scroll', handleBrandsUpdate),
    node => node.removeEventListener('scroll', handleBrandsUpdate),
  );
  const setModelsListRef = useRefWithCallback(
    node => node.addEventListener('scroll', handleModelsUpdate),
    node => node.removeEventListener('scroll', handleModelsUpdate),
  );
  const classes = useStyles();

  const defaultSearchInputProps = {
    disableClearable: true,
    fullWidth: true,
    noOptionsText: 'Не найдено',
    popupIcon: <ChevronDown24Filled />,
    clearOnBlur: false,
    getOptionLabel: (option: any) => option.name,
    getOptionSelected: (option: any) => option.id,
  };

  const searchBrandsApiCall = useCallback(
    debounce((value: string) => {
      transportStore.getBrands(false, value);
    }, 400),
    [],
  );

  const searchModelsApiCall = useCallback(
    debounce((value: string) => {
      transportStore.getModels(false, value);
    }, 400),
    [],
  );

  // Effects
  useEffect(() => {
    if (rest.open) {
      transportStore.setForm(
        TransportFormKeys.UPDATE_BRAND,
        UpdateBrandFormFields.BRAND_ID,
        transportStore.currentTransportInfo?.autoTransport?.brand?.id,
      );
      transportStore.setForm(
        TransportFormKeys.UPDATE_BRAND,
        UpdateBrandFormFields.MODEL_ID,
        transportStore.currentTransportInfo?.autoTransport?.model?.id,
      );
      transportStore.setForm(
        TransportFormKeys.UPDATE_BRAND,
        UpdateBrandFormFields.NUMBER,
        transportStore.currentTransportInfo?.autoTransport?.stateNumber,
      );

      const brandId = transportStore.currentTransportInfo?.autoTransport?.brand?.id;
      brandId && brandId !== 0 && transportStore.setCurrentBrandId(brandId);

      transportStore.currentTransportInfo?.autoTransport?.brand?.name &&
        handleSearchBrands(transportStore.currentTransportInfo.autoTransport.brand.name);
      transportStore.currentTransportInfo?.autoTransport?.model?.name &&
        handleSearchModels(transportStore.currentTransportInfo.autoTransport.model.name);
    }
  }, [rest.open]);

  useEffect(() => {
    transportStore.getModels();
  }, [transportStore.currentBrandId]);

  // Handlers
  const handleBrandsUpdate = (e: Event) => {
    const isEndOfSearchBlock = RenderHelper.getEndOfBlock(e.target as HTMLUListElement, 20);

    if (
      !transportStore.getIsEndOfList(TransportPaginationTypes.BRANDS) &&
      isEndOfSearchBlock &&
      !transportStore.hotLoading &&
      transportStore.brands.length
    ) {
      transportStore.getBrands(true);
    }
  };

  const handleModelsUpdate = (e: Event) => {
    const isEndOfSearchBlock = RenderHelper.getEndOfBlock(e.target as HTMLUListElement, 20);

    if (
      !transportStore.getIsEndOfList(TransportPaginationTypes.MODELS) &&
      isEndOfSearchBlock &&
      !transportStore.hotLoading &&
      transportStore.models.length
    ) {
      transportStore.getModels(true);
    }
  };

  const handleChangeBrand = (event: React.ChangeEvent<{}>, newValue: Brand | null) => {
    if (!newValue?.id && newValue?.id !== 0) {
      return;
    }

    handleSearchBrands(newValue.name || '');
    handleSearchModels('');

    transportStore.setCurrentBrandId(Number(newValue?.id));
    handleChangeValuesNoEvent(UpdateBrandFormFields.BRAND_ID, newValue?.id);
  };

  const handleChangeModel = (event: React.ChangeEvent<{}>, newValue: Model | null) => {
    if (!newValue?.id && newValue?.id !== 0) {
      return;
    }

    handleSearchModels(newValue.name || '');

    handleChangeValuesNoEvent(UpdateBrandFormFields.MODEL_ID, newValue?.id);
  };

  const handleChangeValuesNoEvent = (name: string, value: string | number) => {
    transportStore.setForm(TransportFormKeys.UPDATE_BRAND, name as UpdateBrandFormFields, value);
  };

  const handleSearchBrands = (value: string) => {
    setSearchBrandsValue(value);
    searchBrandsApiCall(value);
  };

  const handleSearchModels = (value: string) => {
    setSearchModelsValue(value);
    searchModelsApiCall(value);
  };

  const handleCloseModelsList = () => {
    transportStore.setPagination({ meta: initialPagination } as PaginationModel, TransportPaginationTypes.MODELS);
  };

  const handleSave = () => {
    transportStore.updateBrand(onClose);
  };

  // Renders
  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper title="Изменить марку, модель, госномер" onClose={onClose} onSave={handleSave}>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <InputLabel>Марка</InputLabel>
              <Autocomplete
                {...defaultSearchInputProps}
                options={transportStore.brands}
                inputValue={searchBrandsValue}
                onChange={handleChangeBrand}
                ListboxProps={{ ref: setBrandsListRef, className: classes.menuList }}
                renderInput={params => (
                  <TextField
                    name={UpdateBrandFormFields.BRAND_ID}
                    onChange={e => handleSearchBrands(e.target.value)}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid xs={6} item>
              <InputLabel>Модель</InputLabel>
              <Autocomplete
                {...defaultSearchInputProps}
                options={transportStore.models}
                inputValue={searchModelsValue}
                onChange={handleChangeModel}
                onClose={handleCloseModelsList}
                ListboxProps={{ ref: setModelsListRef, className: classes.menuList }}
                renderInput={params => (
                  <TextField
                    name={UpdateBrandFormFields.MODEL_ID}
                    onChange={e => handleSearchModels(e.target.value)}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid item xs={7}>
            <NumberInput
              fieldName={UpdateBrandFormFields.NUMBER}
              handleChangeValuesNoEvent={handleChangeValuesNoEvent}
              defaultValue={transportStore.updateBrandForm[UpdateBrandFormFields.NUMBER]}
            />
          </Grid>
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    maxHeight: 340,
    overflow: 'auto',
    padding: 0,
    margin: 0,
    borderRadius: 8,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
    '& li': {
      padding: theme.spacing(1.5, 2),
    },
  },
}));

export default EditMarkModal;
