import { Box, Button, Container, debounce, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import PaginationModel from 'base/modules/pagination/PaginationModel';
import { RenderHelper } from 'helpers/RenderHelper';
import { initialPagination } from 'modules/transport/consts/TransportConsts';
import { TransportPaginationTypes } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import SearchInput from '../components/SearchInput';
import TransportList from '../components/TransportList';

interface IAutoTransportScreenProps {}

const AutoTransportScreen: React.FC<IAutoTransportScreenProps> = observer(() => {
  const { transportStore, rentalStore, authStore } = useRootStore();
  const [value, setValue] = useState('');
  const commonClasses = useCommonStyles();

  const searchApiCall = useCallback(
    debounce((value: string) => {
      transportStore.getAutoTransport(rentalStore.currentRental?.id, value);
    }, 400),
    [rentalStore.currentRental?.id],
  );

  // Effects
  useEffect(() => {
    transportStore.getAutoTransport(rentalStore.currentRental?.id);
  }, [rentalStore.currentRental?.id]);

  useEffect(() => {
    document.addEventListener('scroll', handleUpdate);

    return () => {
      document.removeEventListener('scroll', handleUpdate);
    };
  }, []);

  // Handlers
  const handleUpdate = () => {
    const isEndOfPage = RenderHelper.getEndOfPage();

    if (
      isEndOfPage &&
      !transportStore.getIsEndOfList(TransportPaginationTypes.AUTO_TRANSPORT) &&
      transportStore.autoTransport.length &&
      !transportStore.hotLoading
    ) {
      transportStore.getAutoTransport(rentalStore.currentRental?.id, value, true);
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (transportStore[TransportPaginationTypes.AUTO_TRANSPORT].currentOffset) {
      transportStore.setPagination(
        { meta: initialPagination } as PaginationModel,
        TransportPaginationTypes.AUTO_TRANSPORT,
      );
    }

    setValue(event.target.value);
    searchApiCall(event.target.value);
  };

  // Renders
  return (
    <Container maxWidth="md">
      <Box mb={4}>
        <Typography className={commonClasses.fontWeight700} variant="h1">
          {rentalStore.currentRental?.title}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="h1">Автомобили</Typography>
      </Box>

      <Box mb={4} display="flex" justifyContent="space-between">
        {authStore.userInfo?.canEdit && (
          <Link className={commonClasses.textDecorationNone} to={routes.AddTransportScreen.path}>
            <Button variant="contained" color="primary">
              Добавить автомобиль
            </Button>
          </Link>
        )}

        <SearchInput handleChangeValue={handleChangeValue} placeholder="Найти по марке и модели" />
      </Box>

      <TransportList autoTransportList={transportStore.autoTransport} />
    </Container>
  );
});

export default AutoTransportScreen;
