import { ChevronRight20Filled } from '@fluentui/react-icons';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Collapse,
  Container,
  debounce,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import { PhoneMaskInput } from 'components/UI/NumberMaskedInputs';
import QDatePicker from 'components/UI/QDatePicker';
import QInput from 'components/UI/textFields/QInput';
import DateHelper from 'helpers/DateHelper';
import { BookingFormFields } from 'modules/booking/forms/BookingForm';
import { BookingRenderHelper } from 'modules/booking/helpers/BookingRenderHelper';
import Period from 'modules/booking/models/Period';
import { BookingDates, BookingFormKeys, TimeTypes } from 'modules/booking/types/BookingTypes';
import { RentalRenderHelper } from 'modules/rental/helpers/RentalRenderHelper';
import Address from 'modules/rental/models/Address';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import Tariff from 'modules/transport/models/Tariff';
import { TypesOfTransport } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { declOfNum } from 'utils/declOfNum';

import MainFeaturesItem from '../components/MainFeaturesItem';

const AutoBookingScreen = observer(() => {
  const { bookingStore, transportStore, rentalStore } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const theme = useTheme();

  const [searchValue, setSearchValue] = useState('');
  const [daysCount, setDaysCount] = useState(0);
  const [freePeriods, setFreePeriods] = useState<Period[] | null>(null);
  const [stopRemoving, setStopRemoving] = useState(true);

  const currentDay = new Date().getDate();
  const daysNames = ['сутки', 'суток', 'суток'];

  const removingClass = 'react-datepicker__day--in-selecting-range';

  const [date, setDate] = useState<Record<BookingDates, Date | null>>({
    [BookingDates.START_DATE]: null,
    [BookingDates.END_DATE]: null,
  });
  const [time, setTime] = useState({
    [TimeTypes.START_TIME]: '',
    [TimeTypes.END_TIME]: '',
  });

  const searchApiCall = useCallback(
    debounce((value: string) => {
      rentalStore.searchAddress(value);
    }, 400),
    [],
  );

  // Effects
  useEffect(() => {
    if (transportStore.currentTransportInfo?.id !== Number(id)) {
      transportStore.setCurrentTransportInfo(null);
      transportStore.getTransportInfo(Number(id));
    }

    return () => {
      rentalStore.setSearchAddressData([]);
      bookingStore.setForm(BookingFormKeys.BOOKING, BookingFormFields.START_DATE, '');
      bookingStore.setForm(BookingFormKeys.BOOKING, BookingFormFields.END_DATE, '');
      bookingStore.setBookingSchedule([]);
      BookingRenderHelper.removeMonthScreenListeners();
    };
  }, [id]);

  useEffect(() => {
    if (bookingStore.bookingSchedule.length && !date[BookingDates.START_DATE]) {
      handleChangeDate([new Date()]);
    }
  }, [bookingStore.bookingSchedule]);

  useEffect(() => {
    if (transportStore.currentTransportInfo?.id || transportStore.currentTransportInfo?.id === 0) {
      bookingStore.setForm(
        BookingFormKeys.BOOKING,
        BookingFormFields.TRANSPORT_ID,
        transportStore.currentTransportInfo.id,
      );
      bookingStore.getBookingSchedule(
        transportStore.currentTransportInfo.id,
        DateHelper.formatDateForServer(new Date()),
      );
    }
  }, [transportStore.currentTransportInfo]);

  useEffect(() => {
    if (!!date[BookingDates.START_DATE] && !!time[TimeTypes.START_TIME] && !!time[TimeTypes.END_TIME]) {
      setDaysCount(
        DateHelper.getBookingDaysCount(
          date[BookingDates.START_DATE],
          date[BookingDates.END_DATE],
          time[TimeTypes.START_TIME],
          time[TimeTypes.END_TIME],
        ) || 0,
      );

      bookingStore.setForm(
        BookingFormKeys.BOOKING,
        BookingFormFields.START_DATE,
        DateHelper.formatDateForServer(date[BookingDates.START_DATE], time[TimeTypes.START_TIME]),
      );
      bookingStore.setForm(
        BookingFormKeys.BOOKING,
        BookingFormFields.END_DATE,
        DateHelper.formatDateForServer(
          date[BookingDates.END_DATE] || date[BookingDates.START_DATE],
          time[TimeTypes.END_TIME],
        ),
      );
    }
  }, [date, time]);

  useEffect(() => {
    // Костыль используется для удаления окраски дней в календаре при переключении месяца (в библиотеке такой возможности не предусмотрено)
    if (!stopRemoving && document.querySelectorAll(`.${removingClass}`).length) {
      BookingRenderHelper.removePaintedDays();

      setStopRemoving(true);
    }
  }, [document.querySelectorAll(`.${removingClass}`).length, stopRemoving]);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    bookingStore.setForm(BookingFormKeys.BOOKING, name as BookingFormFields, value);
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    bookingStore.setForm(BookingFormKeys.BOOKING, name as BookingFormFields, checked);
  };

  const handleChangeDate = (dates: Date[]) => {
    const [start, end] = dates;

    setFreePeriods(BookingRenderHelper.getPeriods(bookingStore.bookingSchedule, end || start));

    setDate({
      [BookingDates.START_DATE]: start,
      [BookingDates.END_DATE]: end,
    });

    if (!!end) {
      BookingRenderHelper.removeMonthScreenListeners();
    }
  };

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTime({ ...time, [name]: value });
  };

  const handleChangeAddress = (value: Address) => {
    bookingStore.setForm(BookingFormKeys.BOOKING, BookingFormFields.ADDRESS, value);
  };

  const handleChangeMonth = (date: Date) => {
    if (!transportStore.currentTransportInfo?.id && transportStore.currentTransportInfo?.id !== 0) {
      return;
    }

    BookingRenderHelper.removeMonthScreenListeners();

    bookingStore.getBookingSchedule(transportStore.currentTransportInfo?.id, DateHelper.formatDateForServer(date), () =>
      setStopRemoving(false),
    );
  };

  const handleSearch = (value: string | null) => {
    if (!value && value !== '') {
      return;
    }

    setSearchValue(value);
    searchApiCall(value);
  };

  const handleSearchCardClick = (addressObject: Address) => {
    const { unrestrictedValue } = addressObject;

    if (addressObject.isEnough) {
      setSearchValue(unrestrictedValue || '');
      searchApiCall('');
    } else {
      handleSearch(RentalRenderHelper.getSearchAddressString([unrestrictedValue]));
    }

    handleChangeAddress(addressObject);
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    bookingStore.booking();
  };

  // Renders
  const renderTariffs = (tariffs: Tariff[]) => {
    return tariffs.map(item => {
      return (
        <MainFeaturesItem
          key={item.id}
          label={TransportRenderHelper.getTariffLabel(TypesOfTransport.AUTO, item.start, item.end, item.isSingleDay)}
          value={`${item.price} ₽${!item.isSingleDay ? ' / сутки' : ''}`}
        />
      );
    });
  };

  const renderSearchResults = () => {
    return rentalStore.searchAddressData.map((item: Address) => {
      const { unrestrictedValue } = item;

      return (
        <Button onClick={() => handleSearchCardClick(item)}>
          <Box display="flex">
            {/* TODO: сверстать блок, когда будет дизайн (сейчас тестовая вёрстка) */}
            {unrestrictedValue && <h4>{unrestrictedValue} </h4>}
          </Box>
        </Button>
      );
    });
  };

  const renderCurrentTariff = () => {
    const currentTariff = BookingRenderHelper.getCurrentTariff(
      daysCount,
      transportStore.currentTransportInfo?.tariffs || [],
    );

    if (!currentTariff) {
      return <Typography variant="h4">тариф не найден</Typography>;
    }
    return (
      <>
        <Typography variant="h4">
          {daysCount} {declOfNum(daysCount, daysNames)}: {(currentTariff.price || 1) * daysCount} ₽
        </Typography>
        <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
          тариф:{' '}
          {TransportRenderHelper.getTariffLabel(
            TypesOfTransport.AUTO,
            currentTariff.start,
            currentTariff.end,
            currentTariff.isSingleDay,
          )}
          : {currentTariff.price} ₽
        </Typography>
      </>
    );
  };

  const renderPeriods = () => {
    return freePeriods?.map((period, index) => {
      return <div key={index}>{`от ${period.start} до ${period.end}`}</div>;
    });
  };

  if (!transportStore.currentTransportInfo && transportStore.loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Box mb={6}>
        <Breadcrumbs separator={<ChevronRight20Filled />} aria-label="breadcrumb">
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={routes.AutoTransportScreen.path}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              Список автомобилей
            </Typography>
          </Link>
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={`/transport/auto/${transportStore.currentTransportInfo?.id}`}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              {`${transportStore.currentTransportInfo?.autoTransport?.brand?.name} ${transportStore.currentTransportInfo?.autoTransport?.model?.name}`}
            </Typography>
          </Link>
          <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
            Бронирование
          </Typography>
        </Breadcrumbs>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={5}>
          <Box mb={0.5}>
            <Typography variant="h1">{`${transportStore.currentTransportInfo?.autoTransport?.brand?.name} ${transportStore.currentTransportInfo?.autoTransport?.model?.name}`}</Typography>
          </Box>
          <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
            {transportStore.currentTransportInfo?.autoTransport?.stateNumber}
          </Typography>
        </Box>
        <Box mb={5} display="flex">
          <Box mr={2} width={488}>
            <FormLabel>ФИО клиента</FormLabel>
            <TextField
              name={BookingFormFields.CLIENT_NAME}
              onChange={handleChangeValues}
              variant="outlined"
              fullWidth
              placeholder="Введите ФИО"
            />
          </Box>
          <Box mr={2} width={184}>
            <FormLabel>Телефон клиента</FormLabel>
            <TextField
              name={BookingFormFields.CLIENT_PHONE}
              onChange={handleChangeValues}
              variant="outlined"
              fullWidth
              placeholder="Введите номер"
              InputProps={{ inputComponent: PhoneMaskInput }}
            />
          </Box>
        </Box>
        <Box mb={5} className={classes.rateContainer}>
          {bookingStore.bookingForm[BookingFormFields.START_DATE] &&
          bookingStore.bookingForm[BookingFormFields.END_DATE] ? (
            <>
              <Typography variant="h4">{`${DateHelper.formatDateForBookingView(
                bookingStore.bookingForm[BookingFormFields.START_DATE],
              )} – ${DateHelper.formatDateForBookingView(
                bookingStore.bookingForm[BookingFormFields.END_DATE],
              )}`}</Typography>
              {renderCurrentTariff()}
            </>
          ) : (
            <Typography variant="h4">Выберите дату и время</Typography>
          )}
        </Box>
        <Box mb={5}>
          <Grid container justifyContent="space-between">
            <Grid item xs={5}>
              <Box position="relative" mb={3}>
                {bookingStore.loading && (
                  <Box
                    position="absolute"
                    zIndex={2}
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    bgcolor={theme.palette.common.white}
                  >
                    <Loader />
                  </Box>
                )}
                <QDatePicker
                  onChange={handleChangeDate}
                  startDate={date[BookingDates.START_DATE]}
                  endDate={date[BookingDates.END_DATE]}
                  selected={date[BookingDates.START_DATE]}
                  minDate={new Date()}
                  excludeDates={BookingRenderHelper.getFilledDates(bookingStore.bookingSchedule)}
                  partFilledDates={BookingRenderHelper.getFilledDates(bookingStore.bookingSchedule, false)}
                  onMonthChange={handleChangeMonth}
                  isSelectingRange
                  inline
                />
              </Box>

              <Grid spacing={1} container>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.currentDate)}>
                    <Typography variant="caption">{currentDay}</Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Текущая дата
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.halfBusyDate)}>
                    <Typography variant="caption">{currentDay}</Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Дата частично занята
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.choosedDate)}>
                    <Typography className={commonClasses.onPrimaryPrimary} variant="caption">
                      {currentDay}
                    </Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Выбранная дата
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.busyDate)}>
                    <Typography className={commonClasses.onSurfaceTertiary} variant="caption">
                      {currentDay}
                    </Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Недоступная дата
                  </Typography>
                </Grid>
                <Grid item xs={6} container alignItems="center">
                  <Box mr={0.5} className={clsx(classes.calendarLegend, classes.freeDate)}>
                    <Typography variant="caption">{currentDay}</Typography>
                  </Box>
                  <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                    Свободная дата
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Box mb={1.5}>
                <Typography variant="body2">Начать аренду</Typography>
              </Box>
              <Box mb={1.5}>
                <QInput
                  name={TimeTypes.START_TIME}
                  value={time[TimeTypes.START_TIME]}
                  onChange={handleChangeTime}
                  className={classes.time}
                  type="time"
                  required
                />
              </Box>
              <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                машина свободна {renderPeriods()}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box mb={1.5}>
                <Typography variant="body2">Завершить аренду</Typography>
              </Box>
              <Box mb={1.5}>
                <QInput
                  name={TimeTypes.END_TIME}
                  value={time[TimeTypes.END_TIME]}
                  onChange={handleChangeTime}
                  className={classes.time}
                  type="time"
                  required
                />
              </Box>
              {!!time[TimeTypes.START_TIME] && (
                <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
                  Внимание: после {time[TimeTypes.START_TIME]} начнутся дополнительные сутки, это оплачивается как
                  полный день аренды.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.blockContainer} mb={5}>
          <Box mb={5}>
            <Grid spacing={10} container>
              <Grid item>
                <FormControlLabel
                  color="primary"
                  control={
                    <Checkbox name={BookingFormFields.DEPOSIT} color={'primary'} onChange={handleToggleSwitches} />
                  }
                  label="Внесён залог"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  color="primary"
                  control={
                    <Checkbox name={BookingFormFields.PRE_PAYMENT} color={'primary'} onChange={handleToggleSwitches} />
                  }
                  label="Внесена предоплата"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  color="primary"
                  control={
                    <Switch name={BookingFormFields.DELIVERY} color={'primary'} onChange={handleToggleSwitches} />
                  }
                  label="Доставка машины"
                />
                {bookingStore.bookingForm[BookingFormFields.DELIVERY] && (
                  <Box mt={1.5} width={450}>
                    <QInput
                      value={searchValue}
                      name={BookingFormFields.ADDRESS}
                      onChange={e => handleSearch(e.target.value)}
                      hiddenLabel
                      placeholder="Добавьте адрес доставки"
                      fullWidth
                    />
                    <Collapse in={!!rentalStore.searchAddressData?.length}>{renderSearchResults()}</Collapse>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <Grid container justifyContent="flex-end">
            <Button
              disabled={
                !bookingStore.bookingForm[BookingFormFields.START_DATE] ||
                !bookingStore.bookingForm[BookingFormFields.END_DATE]
              }
              type="submit"
              variant="contained"
              color="primary"
            >
              Забронировать
            </Button>
          </Grid>
        </Box>
        <Box className={classes.blockContainer} mb={5}>
          <Box mb={2}>
            <Typography className={commonClasses.onSurfaceSecondary} variant="h5">
              Тарифы
            </Typography>
          </Box>
          <Grid container spacing={10}>
            <Grid item xs={4}>
              {renderTariffs(transportStore.tariffsColumns?.leftTariffsColumn || [])}
            </Grid>
            {!!transportStore.tariffsColumns?.rightTariffsColumn?.length && (
              <Grid item xs={5}>
                {renderTariffs(transportStore.tariffsColumns?.rightTariffsColumn || [])}
              </Grid>
            )}
          </Grid>
        </Box>
      </form>
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  rateContainer: {
    padding: theme.spacing(3, 5),
    border: `1px solid ${theme.palette.custom.overlay}`,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  time: {
    maxWidth: 85,
    '& input': {
      minWidth: 75,
    },
  },
  blockContainer: {
    paddingTop: theme.spacing(5),
    borderTop: `1px dashed ${theme.palette.custom.overlay}`,
  },
  calendarLegend: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: 11,
  },
  currentDate: {
    border: `1px solid ${theme.palette.common.black}`,
  },
  choosedDate: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.custom.onPrimary.primary,
  },
  freeDate: {
    backgroundColor: theme.palette.custom.onPrimary.primary,
  },
  halfBusyDate: {
    background: `linear-gradient(90deg, ${theme.palette.custom.overlay} 50%, ${theme.palette.custom.onPrimary.primary} 50%)`,
    border: '1px solid rgba(33, 33, 33, 0.08)',
  },
  busyDate: {
    color: theme.palette.custom.onSurface.tertiary,
  },
}));

export default AutoBookingScreen;
