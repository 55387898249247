import AbstractDto from 'base/AbstractDto';

export default class UpdateWaterDto extends AbstractDto {
  id: number = 0;
  name: string = '';
  categoryId: number = 0;
  issueYear: number = 0;
  maxSpeed: number = 0;
  capacity: number = 0;
  cabinsCount: number = 0;
  enginePower: number = 0;
  needLicense: boolean = false;
  status: string = '';
  color: string = '';
  photos: string[] = [];
  wrapper: string = '';
  comment: string = '';
  contract: string = '';
  deposit: number = 0;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
