import { ChevronLeft24Filled, ChevronRight24Filled } from '@fluentui/react-icons';
import { Box, IconButton, Typography } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';

import DateHelper from 'helpers/DateHelper';

interface IQWeekPickerProps {
  date: Date;
  format?: string;
  disabled?: boolean;
  onPlusWeek: () => void;
  onMinusWeek: () => void;
}

const QWeekPicker: React.FC<IQWeekPickerProps> = props => {
  const { date, disabled, format, onPlusWeek, onMinusWeek } = props;
  const dates = DateHelper.getWeekByDay(date, format);

  // Renders
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Typography variant="h4">
          {dates.startDateFormatted} - {dates.endDateFormatted}
        </Typography>
      </Box>
      <Box mr={1}>
        <IconButton size="medium" onClick={onMinusWeek} disabled={disabled}>
          <ChevronLeft24Filled />
        </IconButton>
      </Box>
      <IconButton size="medium" onClick={onPlusWeek} disabled={disabled}>
        <ChevronRight24Filled />
      </IconButton>
    </Box>
  );
};

export default QWeekPicker;
