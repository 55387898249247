import DateHelper from 'helpers/DateHelper';

import { IDayToView } from '../interfaces/CalendarInterfaces';

export class CalendarHelper {
  static getTooltipText = (day: IDayToView[]) => {
    const textArray = day.map(item => `с ${item.start} до ${item.end}`);
    const title = 'Забронировано';

    return { title, textArray };
  };

  static getText = (dayItem: IDayToView, index: number, arr: IDayToView[][], weekDates: Date[]) => {
    const hasDate = dayItem.endDateIndex !== undefined && dayItem.endDateIndex !== index;

    const text = hasDate
      ? `${DateHelper.format(weekDates[index])} ${dayItem.start} – ${DateHelper.format(
          weekDates[dayItem.endDateIndex ?? 0],
        )} ${arr[dayItem.endDateIndex ?? 0][0].end}`
      : `${dayItem.start} – ${dayItem.end}`;

    const start = hasDate ? `${DateHelper.format(weekDates[index])} ${dayItem.start} ` : `${dayItem.start}`;
    const end = hasDate
      ? `${DateHelper.format(weekDates[dayItem.endDateIndex ?? 0])} ${arr[dayItem.endDateIndex ?? 0][0].end}`
      : `${dayItem.end}`;

    if (!hasDate) {
      return { hasDate, text, start, end };
    }

    const fullDateWrap =
      dayItem.endDateIndex! - index > 1 || (arr[index].length < 2 && arr[dayItem.endDateIndex!].length < 2);

    return { hasDate, text, start, end, fullDateWrap };
  };
}
