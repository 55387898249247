import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';

import QInput from 'components/UI/textFields/QInput';
import { ChangeOrderStatusFormFields, IChangeOrderStatusForm } from 'modules/orders/forms/ChangeOrderStatusForm';
import { DeclineReasons, OrdersFormFields } from 'modules/orders/types/OrdersTypes';

interface IDeclineReasonFormProps {
  handleClose: () => void;
  handleSave: (e: React.ChangeEvent<HTMLFormElement>) => void;
  reasons: Record<string, string> | null;
  setForm: (key: OrdersFormFields, value: any) => void;
  currentForm: IChangeOrderStatusForm;
}

const DeclineReasonForm: React.FC<IDeclineReasonFormProps> = observer(props => {
  const { handleClose, handleSave, reasons, setForm, currentForm } = props;
  const classes = useStyles();

  // Handlers
  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setForm(ChangeOrderStatusFormFields.REASON, value);
  };

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm(ChangeOrderStatusFormFields.COMMENT, event.target.value);
  };

  // Renders
  const renderReasons = () => {
    if (!reasons) {
      return null;
    }

    return Object.entries(reasons).map(([key, value]) => {
      return (
        <Box mb={1.5}>
          <FormControlLabel
            className={classes.radioContainer}
            value={key}
            control={<Radio color="primary" />}
            label={value}
            labelPlacement="start"
          />
        </Box>
      );
    });
  };

  return (
    <form onSubmit={handleSave}>
      <Box mb={3}>
        <Typography variant="h3">Причина отклонения заявки</Typography>
      </Box>
      <Box mb={4}>
        <RadioGroup value={currentForm[ChangeOrderStatusFormFields.REASON]} onChange={handleRadio}>
          {renderReasons()}
        </RadioGroup>
      </Box>
      <Box mb={2}>
        <QInput
          value={currentForm[ChangeOrderStatusFormFields.COMMENT]}
          onChange={handleChangeComment}
          label="Добавить комментарий для клиента"
          required={currentForm[ChangeOrderStatusFormFields.REASON] === DeclineReasons.OTHER_REASON}
        />
      </Box>
      <Grid container justifyContent="flex-end">
        <Box mr={2}>
          <Button onClick={handleClose} variant="contained">
            Отменить
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!currentForm[ChangeOrderStatusFormFields.REASON]}
        >
          Сохранить
        </Button>
      </Grid>
    </form>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  radioContainer: {
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: 0,
  },
}));

export default DeclineReasonForm;
