import { makeStyles, Theme } from '@material-ui/core';
import Header from 'widgets/Header/Header';

interface IBaseLayoutProps {
  children?: React.ReactNode;
}

const BaseLayout: React.FC<IBaseLayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Header />
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 80,
  },
}));

export default BaseLayout;
