import { TransportStatuses } from '../types/TransportTypes';

export const UpdateWaterForm: IUpdateWaterForm = {
  id: 0,
  name: '',
  categoryId: 0,
  issueYear: 0,
  maxSpeed: 0,
  capacity: 0,
  cabinsCount: 0,
  enginePower: 0,
  deposit: 0,
  needLicense: false,
  lineStatus: TransportStatuses.FREE,
  color: '',
  photos: [],
  wrapper: '',
  comment: '',
  contract: '',
};

export interface IUpdateWaterForm {
  id: number;
  name: string;
  categoryId: number;
  issueYear: number;
  maxSpeed: number;
  capacity: number;
  cabinsCount: number;
  enginePower: number;
  needLicense: boolean;
  lineStatus: TransportStatuses;
  color: string;
  photos: string[];
  wrapper: string;
  comment: string;
  contract: string;
  deposit: number;
}

export enum UpdateWaterFormFields {
  ID = 'id',
  NAME = 'name',
  CATEGORY_ID = 'categoryId',
  ISSUE_YEAR = 'issueYear',
  MAX_SPEED = 'maxSpeed',
  CAPACITY = 'capacity',
  CABINS_COUNT = 'cabinsCount',
  ENGINE_POWER = 'enginePower',
  NEED_LICENSE = 'needLicense',
  COLOR = 'color',
  LINE_STATUS = 'lineStatus',
  PHOTOS = 'photos',
  WRAPPER = 'wrapper',
  COMMENT = 'comment',
  CONTRACT = 'contract',
  DEPOSIT = 'deposit',
}
