import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import QTextField from 'components/UI/textFields/QTextField';
import { RenderHelper } from 'helpers/RenderHelper';
import {
  ForgotPasswordForm,
  ForgotPasswordFormFields,
  IForgotPasswordForm,
} from 'modules/auth/forms/ForgotPasswordForm';
import { AuthFormKeys, ErrorTypes } from 'modules/auth/types/AuthTypes';

import LoginCard from './components/LoginCard';

interface IChangePasswordScreenProps {}

const ChangePasswordScreen: React.FC<IChangePasswordScreenProps> = observer(() => {
  const { authStore } = useRootStore();
  const classes = useStyles();
  const [isValidEmail, setIsValidEmail] = useState(false);

  // Effects
  useEffect(() => {
    return () => authStore.resetStore<IForgotPasswordForm>(AuthFormKeys.FORGOT_PASSWORD_FORM, ForgotPasswordForm);
  }, []);

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    authStore.setForm(AuthFormKeys.FORGOT_PASSWORD_FORM, name as ForgotPasswordFormFields, value);
    setIsValidEmail(RenderHelper.checkIsValid(value));
    authStore.errorMessages?.restorePassword?.length &&
      authStore.setErrors({ ...authStore.errorMessages, [ErrorTypes.RESTORE_PASSWORD]: null });
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    authStore.forgotPassword();
  };

  // Renders
  return (
    <LoginCard>
      <Box className={classes.form} component="form" onSubmit={handleSubmit}>
        <Typography variant="h1">Восстановить пароль</Typography>
        <Box mb={6}>
          <Typography variant="body1">
            Напишите вашу почту, указанную при регистрации, и мы вышлем вам ссылку для сброса пароля
          </Typography>
        </Box>
        <Box mb={5}>
          <Grid item xs={12}>
            <QTextField
              fullWidth
              name={ForgotPasswordFormFields.EMAIL}
              onChange={handleChangeValues}
              variant="outlined"
              placeholder="Почта"
              errors={authStore.errorMessages?.restorePassword}
            />
          </Grid>
        </Box>

        <Grid container>
          <Grid item>
            <Button disabled={!isValidEmail} type="submit" variant="contained" color="primary">
              Отправить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LoginCard>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
}));

export default ChangePasswordScreen;
