import { Box, Typography } from '@material-ui/core';
import React from 'react';

import { useCommonStyles } from 'styles/commonStyles';

interface ICalendarTooltipProps {
  title: string;
  textArray: string[];
}

const CalendarTooltip: React.FC<ICalendarTooltipProps> = props => {
  const { title, textArray } = props;
  const commonClasses = useCommonStyles();

  // Renders
  return (
    <Box pr={1} display="flex" flexDirection="column">
      <Box mb={0.5}>
        <Typography className={commonClasses.onSurfacePrimary} variant="subtitle2">
          {title}
        </Typography>
      </Box>
      {textArray.map((item, idx) => (
        <Typography key={idx} className={commonClasses.onSurfaceSecondary} variant="caption">
          {item}
        </Typography>
      ))}
    </Box>
  );
};

export default CalendarTooltip;
