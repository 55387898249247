import AbstractModel from 'base/AbstractModel';

import CalendarDayItemPeriod from './CalendarDayItemPeriod';

export default class CalendarDayItem extends AbstractModel {
  date: string | null = null;
  empty: boolean | null = null;
  partFilled: boolean | null = null;
  fullFilled: boolean | null = null;
  filledPeriods: CalendarDayItemPeriod[] | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
