import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import ImageViewer from 'react-simple-image-viewer';

import { useRootStore } from 'base/hooks/useRootStore';
import AddPhoto from 'components/UI/AddPhoto';
import { RenderHelper } from 'helpers/RenderHelper';
import FileModel from 'modules/files/models/FileModel';
import { FilesTypes, FileTypes } from 'modules/files/types/FileTypes';
import { useCommonStyles } from 'styles/commonStyles';

import PhotoItem from './PhotoItem';

interface IPhotoListProps {
  handleAddPhotos: (images: FileModel[]) => void;
  handleAddWrapper: (image: FileModel) => void;
  canEdit: boolean;
  isTransportCard?: boolean;
  files?: FileModel[] | null;
  defaultWrapper?: FileModel | null;
  handleApiDelete?: (id: number) => void;
}

const PhotoList: React.FC<IPhotoListProps> = observer(props => {
  const {
    handleAddPhotos,
    handleAddWrapper,
    isTransportCard = false,
    files,
    defaultWrapper,
    handleApiDelete,
    canEdit,
  } = props;
  const { filesStore } = useRootStore();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const [wrapper, setWrapper] = useState<FileModel | null>(null);
  const [images, setImages] = useState<FileModel[]>([]);

  const [isOpenViewer, setIsOpenViewer] = useState(false);
  const [isOpenWrapperViewer, setIsOpenWrapperViewer] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  // Effects
  useEffect(() => {
    if (files) {
      setImages(files);
    }
  }, [files]);

  useEffect(() => {
    setWrapper(defaultWrapper || null);
  }, [defaultWrapper]);

  useEffect(() => {
    if (filesStore.images.length) {
      if (isTransportCard) {
        handleAddPhotos(filesStore.images);
      } else {
        setImages(filesStore.images);
        handleAddPhotos(filesStore.images);
      }
    }
  }, [filesStore.images, isTransportCard]);

  useEffect(() => {
    if (filesStore.image) {
      if (isTransportCard) {
        handleAddWrapper(filesStore.image);
      } else {
        setWrapper(filesStore.image);
        handleAddWrapper(filesStore.image);
      }
    }
  }, [filesStore.image, isTransportCard]);

  // Handlers
  const handleUploadWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    filesStore.uploadImage(event.target.files[0]);
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    filesStore.uploadImages(event.target.files, isTransportCard);
  };

  const handleDeleteWrapper = () => {
    if (isTransportCard) {
      handleApiDelete && wrapper?.id && handleApiDelete(wrapper.id);
    } else {
      setWrapper(null);
      filesStore.setFile(null, FileTypes.IMAGE);
    }
  };

  const handleDelete = (id: number) => {
    if (isTransportCard) {
      handleApiDelete && handleApiDelete(id);
    } else {
      setImages(RenderHelper.deleteElem<FileModel>(images, id, 'id'));
      filesStore.setFiles(RenderHelper.deleteElem<FileModel>(images, id, 'id'), FilesTypes.IMAGE);
    }
  };

  const handleOpenViewer = (index: number) => {
    setCurrentImage(index);
    setIsOpenViewer(true);

    document.body.style.overflow = 'hidden';
  };

  const handleCloseViewer = () => {
    setCurrentImage(0);
    setIsOpenViewer(false);

    document.body.style.removeProperty('overflow');
  };

  const handleOpenWrapperViewer = () => {
    setIsOpenWrapperViewer(true);

    document.body.style.overflow = 'hidden';
  };

  const handleCloseWrapperViewer = () => {
    setIsOpenWrapperViewer(false);

    document.body.style.removeProperty('overflow');
  };

  // Renders
  const renderPhotos = () => {
    return images.map((image, index) => {
      return (
        <Grid key={image.id} item>
          <PhotoItem
            canEdit={canEdit}
            image={image}
            handleOpenViewer={() => handleOpenViewer(index)}
            handleDelete={handleDelete}
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">Фотографии</Typography>
      </Box>
      <Box mb={3}>
        <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle1">
          Обложка
        </Typography>
      </Box>
      <Box mb={3} className={classes.addPhotoContainer}>
        {wrapper ? (
          <PhotoItem
            canEdit={canEdit}
            handleOpenViewer={handleOpenWrapperViewer}
            handleDelete={handleDeleteWrapper}
            image={wrapper}
          />
        ) : canEdit ? (
          <AddPhoto handleUpload={handleUploadWrapper} />
        ) : (
          <></>
        )}
      </Box>
      <Box mb={3}>
        <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle1">
          Галерея
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          {renderPhotos()}
          {canEdit && (
            <Grid className={classes.addPhotoContainer} item>
              <AddPhoto isMultiple handleUpload={handleUpload} />
            </Grid>
          )}
        </Grid>
      </Box>

      {isOpenViewer && (
        <ImageViewer
          backgroundStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
          src={images.map(item => item.fullUrl || '')}
          currentIndex={currentImage}
          closeOnClickOutside={true}
          onClose={handleCloseViewer}
        />
      )}
      {isOpenWrapperViewer && (
        <ImageViewer
          backgroundStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
          src={[wrapper?.fullUrl || '']}
          closeOnClickOutside={true}
          onClose={handleCloseWrapperViewer}
        />
      )}
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  addPhotoContainer: {
    width: 187,
    height: 120,
  },
}));

export default PhotoList;
