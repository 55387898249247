import AbstractDto from 'base/AbstractDto';

export default class AddAutoDto extends AbstractDto {
  type: string = '';
  rentalId: number = 0;
  brandId: number = 0;
  modelId: number = 0;
  stateNumber: string = '';
  enginePower: number = 0;
  engineVolume: number = 0;
  color: string = '';
  racing: number = 0;
  maxSpeed: number = 0;
  speedLimit: number = 0;
  mileageLimit: number = 0;
  issueYear: number = 0;
  music: string = '';
  deliveryPrice: number = 0;
  deposit: number = 0;
  drive: string = '';
  transmission: string = '';
  bodyType: string = '';
  fuelType: string = '';
  fuelConsumption: number = 0;
  fuelCostPerLiter: number = 0;
  tariffs: Object[] = [];
  wrapper: string = '';
  photos: string[] = [];
  contract: string = '';
  insurances: string[] = [];
  offline: boolean = false;
  comment: string = '';
  rerunPrice: number = 0;
  washFee: number = 0;
  travelAboard: boolean = false;
  travelOutOfCity: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
