import AbstractModel from 'base/AbstractModel';
import WaterTransport from 'modules/transport/models/WaterTransport';

import CalendarDayItem from './CalendarDayItem';

export default class WaterCalendarItem extends AbstractModel {
  id: number | null = null;
  waterTransport: WaterTransport | null = null;
  schedule: { days: CalendarDayItem[] } = { days: [] };

  constructor(props: any) {
    super();
    this.load(props);
  }
}
