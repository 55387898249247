import { makeAutoObservable, runInAction, set } from 'mobx';
import { routes } from 'routes/routes';

import PaginationModel from 'base/modules/pagination/PaginationModel';
import history from 'base/routes/history';
import Notification from 'base/ui/Notification';

import TransportService from './TransportService';
import { initialPagination } from './consts/TransportConsts';
import { AddAutoForm, AddAutoFormFields } from './forms/AddAutoForm';
import { AddWaterForm, AddWaterFormFields } from './forms/AddWaterForm';
import { UpdateAutoForm, UpdateAutoFormFields } from './forms/UpdateAutoForm';
import { UpdateBrandForm, UpdateBrandFormFields } from './forms/UpdateBrandForm';
import { UpdateDepositForm, UpdateDepositFormFields } from './forms/UpdateDepositForm';
import { UpdateStatusForm, UpdateStatusFormFields } from './forms/UpdateStatusForm';
import { UpdateTariffsForm, UpdateTariffsFormFields } from './forms/UpdateTariffsForm';
import { UpdateWaterForm, UpdateWaterFormFields } from './forms/UpdateWaterForm';
import { TransportRenderHelper } from './helpers/TransportRenderHelper';
import AutoTransportModel from './models/AutoTransportModel';
import Brand from './models/Brand';
import DetailType from './models/DetailType';
import Model from './models/Model';
import Tariff from './models/Tariff';
import TransportInfo from './models/TransportInfo';
import WaterTransportCategory from './models/WaterTransportCategory';
import WaterTransportModel from './models/WaterTransportModel';
import { TransportFormKeys, TransportFormFields, TransportPaginationTypes } from './types/TransportTypes';

interface ITariffsColumns {
  leftTariffsColumn: Tariff[] | null;
  rightTariffsColumn: Tariff[] | null;
}

export class TransportStore {
  loading = false;
  hotLoading = false;
  addModelLoading = false;

  brands: Brand[] = [];
  models: Model[] = [];
  fuelTypes: DetailType[] = [];
  driveTypes: DetailType[] = [];
  bodyTypes: DetailType[] = [];
  transmissionTypes: DetailType[] = [];
  waterTransportCategories: WaterTransportCategory[] = [];

  autoTransport: AutoTransportModel[] = [];
  waterTransport: WaterTransportModel[] = [];
  currentTransportInfo: TransportInfo | null = null;
  tariffsColumns: ITariffsColumns | null = null;
  currentBrandId: number | null = null;

  private currentModelName: string | null = null;

  addAutoForm = AddAutoForm;
  addWaterForm = AddWaterForm;
  updateBrandForm = UpdateBrandForm;
  updateAutoForm = UpdateAutoForm;
  updateWaterForm = UpdateWaterForm;
  updateTariffsForm = UpdateTariffsForm;
  updateDepositForm = UpdateDepositForm;
  updateStatusForm = UpdateStatusForm;

  brandsPagination = initialPagination;
  modelsPagination = initialPagination;
  waterTransportPagination = initialPagination;
  autoTransportPagination = initialPagination;

  private transportService: TransportService;

  constructor() {
    makeAutoObservable(this);
    this.transportService = new TransportService();
  }

  initAutoTransportCardScreen = () => {
    if (
      !this.fuelTypes.length ||
      !this.driveTypes.length ||
      !this.bodyTypes.length ||
      !this.transmissionTypes.length ||
      !this.currentTransportInfo?.autoTransport
    ) {
      return;
    }

    this.setForm(TransportFormKeys.UPDATE_STATUS, UpdateStatusFormFields.STATUS, this.currentTransportInfo.lineStatus);
    this.setForm(TransportFormKeys.UPDATE_TARIFFS, UpdateTariffsFormFields.TARIFFS, this.currentTransportInfo.tariffs);
    this.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.COMMENT, this.currentTransportInfo.comment);

    this.setForm(
      TransportFormKeys.UPDATE_DEPOSIT,
      UpdateDepositFormFields.AMOUNT,
      this.currentTransportInfo.autoTransport?.deposit,
    );

    if (!this.currentTransportInfo.autoTransport?.insurances) {
      this.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.INSURANCES, []);
    }

    Object.entries(this.currentTransportInfo.autoTransport)?.forEach(([key, value]) => {
      if (Object.values(UpdateAutoFormFields).includes(key as UpdateAutoFormFields)) {
        this.setForm(TransportFormKeys.UPDATE_AUTO, key as UpdateAutoFormFields, value);

        TransportRenderHelper.setDetailsForm(
          key,
          value,
          this.fuelTypes,
          this.driveTypes,
          this.bodyTypes,
          this.transmissionTypes,
          this.setForm,
        );

        if (key === UpdateAutoFormFields.INSURANCES && !this.currentTransportInfo?.autoTransport?.insurances) {
          this.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.INSURANCES, []);
        }
      }
    });
  };

  initWaterTransportCardScreen = () => {
    if (!this.currentTransportInfo?.waterTransport) {
      return;
    }

    this.setForm(TransportFormKeys.UPDATE_STATUS, UpdateStatusFormFields.STATUS, this.currentTransportInfo.lineStatus);
    this.setForm(TransportFormKeys.UPDATE_TARIFFS, UpdateTariffsFormFields.TARIFFS, this.currentTransportInfo.tariffs);
    this.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.COMMENT, this.currentTransportInfo.comment);
    this.setForm(
      TransportFormKeys.UPDATE_DEPOSIT,
      UpdateDepositFormFields.AMOUNT,
      this.currentTransportInfo.waterTransport.deposit,
    );

    Object.entries(this.currentTransportInfo.waterTransport)?.forEach(([key, value]) => {
      if (Object.values(UpdateWaterFormFields).includes(key as UpdateWaterFormFields)) {
        this.setForm(TransportFormKeys.UPDATE_WATER, key as UpdateWaterFormFields, value);
      }

      if (key === 'category') {
        this.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.CATEGORY_ID, value.id);
      }
    });
  };

  getIsEndOfList = (paginationType: TransportPaginationTypes) => {
    const { totalCount, currentOffset } = this[paginationType];

    return totalCount <= currentOffset;
  };

  addAuto = async (rentalId?: number | null) => {
    if (!rentalId && rentalId !== 0) {
      return;
    }

    if (!this.addAutoForm[AddAutoFormFields.TARIFFS].length) {
      Notification.showError('Не добавлены тарифы');
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.ADD_AUTO, AddAutoFormFields.RENTAL_ID, rentalId);
    await this.setModel(TransportFormKeys.ADD_AUTO);

    this.transportService
      .addAuto(this.addAutoForm)
      .then(() => {
        history.push(routes.AutoTransportScreen.path);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  addWater = (rentalId?: number | null) => {
    if (!rentalId && rentalId !== 0) {
      return;
    }

    if (!this.addWaterForm[AddWaterFormFields.TARIFFS].length) {
      Notification.showError('Не добавлены тарифы');
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.ADD_WATER, AddWaterFormFields.RENTAL_ID, rentalId);

    this.transportService
      .addWater(this.addWaterForm)
      .then(() => {
        history.push(routes.WaterTransportScreen.path);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  addModel = async (id: number, name: string) => {
    this.setAddModelLoading(true);

    return this.transportService
      .addModel(id, name)
      .then(data => {
        return data;
      })
      .catch(error => {})
      .finally(() => {
        this.setAddModelLoading(false);
      });
  };

  setModel = async (form: TransportFormKeys) => {
    const foundModel: Model | undefined = this.models.find(item => item.name === this.currentModelName);

    if (foundModel) {
      this.setForm(form, AddAutoFormFields.MODEL_ID, foundModel.id);
    } else if (this.currentModelName && this.currentBrandId) {
      return await this.addModel(this.currentBrandId, this.currentModelName).then(data => {
        if (data) {
          this.setModels([...this.models, data]);
          this.setForm(form, AddAutoFormFields.MODEL_ID, data.id);
          Notification.showSuccess('Добавлена новая модель');
        }
      });
    }
  };

  getAutoTransport = (rentalId?: number | null, query?: string, isLoadMore: boolean = false) => {
    if (!rentalId && rentalId !== 0) {
      return;
    }

    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
      this.setPagination({ meta: initialPagination } as PaginationModel, TransportPaginationTypes.AUTO_TRANSPORT);
    }

    return this.transportService
      .getAutoTransport(rentalId, TransportService.LIMIT, this.autoTransportPagination.currentOffset, query)
      .then(({ transport, pagination }) => {
        if (isLoadMore && this.autoTransport.length) {
          this.setAutoTransport([...this.autoTransport, ...transport]);
        } else {
          this.setAutoTransport(transport);
        }

        this.setPagination(pagination, TransportPaginationTypes.AUTO_TRANSPORT);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  getWaterTransport = (
    rentalId?: number | null,
    categoryId?: number | null,
    query?: string,
    isLoadMore: boolean = false,
  ) => {
    if ((!categoryId && categoryId !== 0) || (!rentalId && rentalId !== 0)) {
      return;
    }

    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
      this.setPagination({ meta: initialPagination } as PaginationModel, TransportPaginationTypes.WATER_TRANSPORT);
    }

    return this.transportService
      .getWaterTransport(
        rentalId,
        TransportService.LIMIT,
        this.waterTransportPagination.currentOffset,
        categoryId,
        query,
      )
      .then(({ transport, pagination }) => {
        if (isLoadMore && this.waterTransport.length) {
          this.setWaterTransport([...this.waterTransport, ...transport]);
        } else {
          this.setWaterTransport(transport);
        }

        this.setPagination(pagination, TransportPaginationTypes.WATER_TRANSPORT);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  getWaterTransportCategories = () => {
    this.setLoading(true);

    this.transportService
      .getWaterTransportCategories()
      .then(categories => {
        this.setWaterTransportCategories(categories);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getBrands = (isLoadMore: boolean = false, query?: string) => {
    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
    }

    const currentOffset = query || query === '' ? 0 : this.brandsPagination.currentOffset;

    return this.transportService
      .getBrands(TransportService.LIMIT, currentOffset, query)
      .then(({ brands, pagination }) => {
        if (isLoadMore && this.brands) {
          this.setBrands([...this.brands, ...brands]);
        } else {
          this.setBrands(brands);
        }

        this.setPagination(pagination, TransportPaginationTypes.BRANDS);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  getModels = (isLoadMore: boolean = false, query?: string) => {
    if (!this.currentBrandId && this.currentBrandId !== 0) {
      return;
    }

    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
    }

    const currentOffset = query || query === '' ? 0 : this.modelsPagination.currentOffset;

    if (query) {
      runInAction(() => {
        this.currentModelName = query;
      });
    }

    return this.transportService
      .getModels(this.currentBrandId, TransportService.LIMIT, currentOffset, query)
      .then(({ models, pagination }) => {
        if (isLoadMore && this.models) {
          this.setModels([...this.models, ...models]);
        } else {
          this.setModels(models);
        }

        this.setPagination(pagination, TransportPaginationTypes.MODELS);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  getAllDetails = async () => {
    await this.getFuelTypes();
    await this.getDriveTypes();
    await this.getBodyTypes();
    await this.getTransmissionTypes();
  };

  getFuelTypes = () => {
    if (this.fuelTypes.length) {
      return;
    }

    this.setLoading(true);

    return this.transportService
      .getFuelTypes()
      .then(fuelTypes => {
        this.setFuelTypes(fuelTypes);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getDriveTypes = () => {
    if (this.driveTypes.length) {
      return;
    }

    this.setLoading(true);

    return this.transportService
      .getDriveTypes()
      .then(driveTypes => {
        this.setDriveTypes(driveTypes);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getBodyTypes = () => {
    if (this.bodyTypes.length) {
      return;
    }

    this.setLoading(true);

    return this.transportService
      .getBodyTypes()
      .then(bodyTypes => {
        this.setBodyTypes(bodyTypes);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getTransmissionTypes = () => {
    if (this.transmissionTypes.length) {
      return;
    }

    this.setLoading(true);

    return this.transportService
      .getTransmissionTypes()
      .then(transmissionTypes => {
        this.setTransmissionTypes(transmissionTypes);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getTransportInfo = (id: number) => {
    this.setLoading(true);

    this.transportService
      .getTransportInfo(id)
      .then(data => {
        this.setCurrentTransportInfo(data);

        this.setTariffsColumns(TransportRenderHelper.getTariffsColumns(data.tariffs));
      })
      .catch(error => {
        if (error.response?.status === 404) {
          history.push(routes.MainScreen.path);
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateBrand = async (cb?: () => void) => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.UPDATE_BRAND, UpdateBrandFormFields.TRANSPORT_ID, this.currentTransportInfo.id);
    await this.setModel(TransportFormKeys.UPDATE_BRAND);

    this.transportService
      .updateBrand(this.updateBrandForm)
      .then(data => {
        this.setCurrentTransportInfo(data);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateAuto = (cb?: () => void) => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.ID, this.currentTransportInfo.id);

    this.transportService
      .updateAuto(this.updateAutoForm)
      .then(data => {
        this.setCurrentTransportInfo(data);
        this.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.PHOTOS, []);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateWater = (cb?: () => void) => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.ID, this.currentTransportInfo.id);

    this.transportService
      .updateWater(this.updateWaterForm)
      .then(data => {
        this.setCurrentTransportInfo(data);
        this.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.PHOTOS, []);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateTariffs = (cb?: () => void) => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.UPDATE_TARIFFS, UpdateTariffsFormFields.TRANSPORT_ID, this.currentTransportInfo.id);

    this.transportService
      .updateTariffs(this.updateTariffsForm)
      .then(data => {
        this.setCurrentTransportInfo(data);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateDeposit = (cb?: () => void, isAutoTransport = true) => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.UPDATE_DEPOSIT, UpdateDepositFormFields.TRANSPORT_ID, this.currentTransportInfo.id);

    this.transportService
      .updateDeposit(this.updateDepositForm, isAutoTransport)
      .then(data => {
        this.setCurrentTransportInfo(data);
        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateStatus = () => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);
    this.setForm(TransportFormKeys.UPDATE_STATUS, UpdateStatusFormFields.TRANSPORT_ID, this.currentTransportInfo.id);

    this.transportService
      .updateStatus(this.updateStatusForm)
      .then(data => {
        this.setCurrentTransportInfo(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  deleteTransport = () => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);

    this.transportService
      .deleteTransport(this.currentTransportInfo.id)
      .then(() => {
        history.push(routes.MainScreen.path);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  deleteTransportPhoto = (photoId: number) => {
    if (!this.currentTransportInfo?.id && this.currentTransportInfo?.id !== 0) {
      return;
    }

    this.setLoading(true);

    this.transportService
      .deleteTransportPhoto(this.currentTransportInfo.id, photoId)
      .then(data => {
        this.setCurrentTransportInfo(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setForm = (formKey: TransportFormKeys, key: TransportFormFields, value: any) => {
    set(this[formKey], key, value);
  };

  resetAddAutoForm = () => {
    this.setModels([]);
    this.setCurrentBrandId(null);

    Object.entries(AddAutoForm).forEach(([key, value]) => {
      this.setForm(TransportFormKeys.ADD_AUTO, key as TransportFormFields, value);
    });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setHotLoading = (value: boolean) => {
    this.hotLoading = value;
  };

  setAddModelLoading = (value: boolean) => {
    this.addModelLoading = value;
  };

  setPagination = (pagination: PaginationModel, paginationType: TransportPaginationTypes) => {
    if (pagination.meta) {
      this[paginationType] = pagination.meta;
    }
  };

  setBrands = (brands: Brand[]) => {
    this.brands = brands;
  };

  setModels = (models: Model[]) => {
    this.models = models;
  };

  setFuelTypes = (fuelTypes: DetailType[]) => {
    this.fuelTypes = fuelTypes;
  };

  setDriveTypes = (driveTypes: DetailType[]) => {
    this.driveTypes = driveTypes;
  };

  setBodyTypes = (bodyTypes: DetailType[]) => {
    this.bodyTypes = bodyTypes;
  };

  setTransmissionTypes = (transmissionTypes: DetailType[]) => {
    this.transmissionTypes = transmissionTypes;
  };

  setWaterTransport = (transport: WaterTransportModel[]) => {
    this.waterTransport = transport;
  };

  setAutoTransport = (transport: AutoTransportModel[]) => {
    this.autoTransport = transport;
  };

  setWaterTransportCategories = (data: WaterTransportCategory[]) => {
    this.waterTransportCategories = data;
  };

  setTariffsColumns = (data: ITariffsColumns) => {
    this.tariffsColumns = data;
  };

  setCurrentBrandId = (id: number | null) => {
    this.modelsPagination = initialPagination;
    this.currentBrandId = id;
  };

  setCurrentTransportInfo = (data: TransportInfo | null) => {
    this.currentTransportInfo = data;
  };
}
