import AbstractApiRepository from 'base/api/AbstractApiRepository';

import BookingDto from './dto/BookingDto';

export default class BookingApiRepository extends AbstractApiRepository {
  getBookingSchedule = (transportId: number, date: string) => {
    return this.apiClient.get({ url: `/lessor/rentals/booking/schedule/${transportId}?date=${date}` });
  };

  booking = (data: BookingDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/booking/`, data });
  };
}
