import AbstractDto from 'base/AbstractDto';

export default class BookingDto extends AbstractDto {
  transportId: number = 0;
  startDate: string = '';
  endDate: string = '';
  deposit: boolean = false;
  prePayment: boolean = false;
  delivery: boolean = false;
  clientName: string = '';
  clientPhone: string = '';
  address: Object = {};

  constructor(props: any) {
    super();
    this.load(props);
  }
}
