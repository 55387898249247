import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface IMainFeaturesItemProps {
  label: string;
  value: string | number;
  textClassName?: string;
}

const MainFeaturesItem: React.FC<IMainFeaturesItemProps> = ({ label, value, textClassName }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid xs={5} item>
        <Box mb={0.5}>
          <Typography className={clsx(classes.label, textClassName || '')} variant="body1">
            {label}
          </Typography>
        </Box>
      </Grid>
      <Grid xs item>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.custom.onSurface.secondary,
  },
}));

export default MainFeaturesItem;
