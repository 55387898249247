import { DialogProps, Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import { useCommonStyles } from 'styles/commonStyles';

interface IDeleteModalProps extends DialogProps {
  onClose: () => void;
}

const DeleteModal: React.FC<IDeleteModalProps> = observer(({ onClose, ...rest }) => {
  const { transportStore } = useRootStore();
  const commonClasses = useCommonStyles();

  // Handlers
  const handleDelete = () => {
    transportStore.deleteTransport();
  };

  // Renders
  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper
        saveBtnText="Удалить"
        title="Вы действительно хотите удалить:"
        onClose={onClose}
        onSave={handleDelete}
      >
        <Box pt={4} pb={4} mb={4}>
          <Box mb={0.5}>
            <Typography variant="h1">{transportStore.currentTransportInfo?.waterTransport?.name}</Typography>
          </Box>
          <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
            {transportStore.currentTransportInfo?.waterTransport?.category?.name}
          </Typography>
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

export default DeleteModal;
