import React from 'react';

import { AuthStore } from 'modules/auth/AuthStore';
import { BookingStore } from 'modules/booking/BookingStore';
import { CalendarStore } from 'modules/calendar/CalendarStore';
import { FilesStore } from 'modules/files/FilesStore';
import { OrdersStore } from 'modules/orders/OrdersStore';
import { RentalStore } from 'modules/rental/RentalStore';
import { DeliveryTariffsStore } from 'modules/rental/modules/deliveryTariffs/DeliveryTariffsStore';
import { TransportStore } from 'modules/transport/TransportStore';

class RootStore {
  authStore: AuthStore;
  rentalStore: RentalStore;
  transportStore: TransportStore;
  filesStore: FilesStore;
  bookingStore: BookingStore;
  ordersStore: OrdersStore;
  calendarStore: CalendarStore;
  deliveryTariffsStore: DeliveryTariffsStore;

  constructor() {
    this.authStore = new AuthStore();
    this.rentalStore = new RentalStore();
    this.transportStore = new TransportStore();
    this.filesStore = new FilesStore();
    this.bookingStore = new BookingStore();
    this.ordersStore = new OrdersStore();
    this.calendarStore = new CalendarStore();
    this.deliveryTariffsStore = new DeliveryTariffsStore();
  }
}

const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);
