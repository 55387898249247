import { DaysNumbers, ILocalScheduleData } from '../types/RentalTypes';

export const initAddress = {
  unrestrictedValue: '',
  house: '',
  flat: '',
  geoLat: '',
  geoLon: '',
  city: '',
  settlement: null,
};

export const initSchedule: ILocalScheduleData = {
  roundTheClock: false,
  schedule: [
    {
      dayNumber: DaysNumbers.MON,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
    {
      dayNumber: DaysNumbers.TUE,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
    {
      dayNumber: DaysNumbers.WED,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
    {
      dayNumber: DaysNumbers.THU,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
    {
      dayNumber: DaysNumbers.FRI,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
    {
      dayNumber: DaysNumbers.SAT,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
    {
      dayNumber: DaysNumbers.SUN,
      timeStart: null,
      timeEnd: null,
      shortDayName: null,
    },
  ],
};

export const prePaymentValues = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
