import { Box, DialogProps, FormControlLabel, Grid, makeStyles, Switch, Theme, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import { initSchedule } from 'modules/rental/consts/RentalConsts';
import { RentalRenderHelper } from 'modules/rental/helpers/RentalRenderHelper';
import { ILocalSchedule } from 'modules/rental/types/RentalTypes';

import WorkHoursInput from '../WorkHoursInput';

interface IRefreshScheduleModalProps extends DialogProps {
  onClose: () => void;
}

const RefreshScheduleModal: React.FC<IRefreshScheduleModalProps> = observer(({ onClose, ...rest }) => {
  const { rentalStore } = useRootStore();
  const classes = useStyles();

  const [schedule, setSchedule] = useState(initSchedule);

  // Effects
  useEffect(() => {
    if (rentalStore.formattedSchedule?.schedule?.length) {
      setSchedule(rentalStore.formattedSchedule);
    }
  }, [rentalStore.formattedSchedule]);

  // Handlers
  const handleRoundTheClock = (e: React.ChangeEvent<{}>, checked: boolean) => {
    setSchedule({ ...schedule, roundTheClock: checked });
  };

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>, dayId: number) => {
    setSchedule({ ...schedule, schedule: RentalRenderHelper.findScheduleDay(schedule.schedule, dayId, e) });
  };

  const handleSave = () => {
    rentalStore.refreshSchedule(schedule, onClose);
  };

  // Renders
  const renderInputs = (schedulePart: ILocalSchedule[], startIndex: number = 1) => {
    return schedulePart.map((day, index) => {
      return (
        <Box mb={2} key={day.shortDayName}>
          <WorkHoursInput
            dayId={index + startIndex}
            handleChangeTime={handleChangeTime}
            defaultValues={day}
            label={day.shortDayName || ''}
          />
        </Box>
      );
    });
  };

  return (
    <QModal maxWidth="md" onClose={onClose} {...rest}>
      <EditModalWrapper isForm={false} onClose={onClose} onSave={handleSave} title="Редактировать режим работы">
        <Box mb={5}>
          <FormControlLabel
            onChange={handleRoundTheClock}
            color="primary"
            control={<Switch color="primary" checked={!!schedule.roundTheClock} />}
            label="Круглосуточно 24/7"
          />
        </Box>
        <Box mb={5}>
          <Grid container>
            <Box mr={10}>{renderInputs(schedule?.schedule?.slice(0, 5) || [])}</Box>
            <Grid>
              {renderInputs(schedule?.schedule?.slice(-2) || [], 6)}
              <Typography className={classes.editWorkHoursText} variant="body2">
                Дни с незаданным временем отобразятся как выходные
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  editWorkHoursText: {
    width: 285,
    color: theme.palette.custom.onSurface.secondary,
  },
}));

export default RefreshScheduleModal;
