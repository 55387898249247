import DeliveryTariffsApiRepository from './DeliveryTariffsApiRepository';
import { DeliveryTariffsFactory } from './DeliveryTariffsFactory';
import UpdateDeliveryTariffDto from './dto/UpdateDeliveryTariffDto';
import { IDeliveryTariffsForm } from './forms/DeliveryTariffsForm';

export default class DeliveryTariffsService {
  api: DeliveryTariffsApiRepository;
  factory: DeliveryTariffsFactory;

  constructor() {
    this.api = new DeliveryTariffsApiRepository();
    this.factory = new DeliveryTariffsFactory();
  }

  getDeliveryTariffs = async (rentalId: number) => {
    const { data } = await this.api.getDeliveryTariffs(rentalId);

    return this.factory.createDeliveryTariffsList(data.data);
  };

  updateDeliveryTariffs = async (form: IDeliveryTariffsForm) => {
    const dto = UpdateDeliveryTariffDto.populate({ ...form, id: form.rentalId }) as UpdateDeliveryTariffDto;
    const { data } = await this.api.updateDeliveryTariffs(dto);

    return this.factory.createDeliveryTariffsList(data.data);
  };
}
