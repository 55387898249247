import { RefreshAddressAndPhoneFormFields } from '../forms/RefreshAddressAndPhoneForm';
import { RefreshDefaultSettingsFormFields } from '../forms/RefreshDefaultSettingsForm';

export enum RentalFormKeys {
  REFRESH_ADDRESS_AND_PHONE = 'refreshAddressAndPhoneForm',
  REFRESH_DEFAULT_SETTINGS = 'refreshDefaultSettingsForm',
}

export enum DaysNumbers {
  MON = 1,
  TUE,
  WED,
  THU,
  FRI,
  SAT,
  SUN,
}

export type RentalFormFields = RefreshAddressAndPhoneFormFields | RefreshDefaultSettingsFormFields;

export interface ILocalScheduleData {
  roundTheClock: boolean;
  schedule: ILocalSchedule[];
}

export interface ILocalSchedule {
  dayNumber: DaysNumbers;
  timeStart: string | null;
  timeEnd: string | null;
  shortDayName: string | null;
}

export enum TypesOfRental {
  AUTOMOBILE = 'automobile',
  WATER = 'water',
}

export enum RentalModals {
  REFRESH_PHONE = 'refreshPhone',
  REFRESH_SCHEDULE = 'refreshSchedule',
  REFRESH_DEFAULT_SETTINGS = 'refreshDefaultSettings',
  DELIVERY = 'delivery',
}
