import { Search24Regular } from '@fluentui/react-icons';
import { Icon, InputAdornment, makeStyles, OutlinedInput, Theme } from '@material-ui/core';
import React from 'react';

interface ISearchInput {
  placeholder: string;
  handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<ISearchInput> = ({ handleChangeValue, placeholder }) => {
  const classes = useStyles();

  return (
    <OutlinedInput
      endAdornment={
        <InputAdornment position="end">
          <Icon>
            <Search24Regular />
          </Icon>
        </InputAdornment>
      }
      placeholder={placeholder}
      className={classes.input}
      onChange={handleChangeValue}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: '320px',
  },
}));

export default SearchInput;
