import { Edit16Regular } from '@fluentui/react-icons';
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface IRentBlockWrapperProps {
  title: string;
  canEdit: boolean;
  onEdit?: () => void;
  children?: React.ReactNode;
}

const RentBlockWrapper: React.FC<IRentBlockWrapperProps> = ({ title, children, onEdit, canEdit }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box mb={3}>
        <Grid justifyContent="space-between" alignItems="center" container>
          <Typography variant="h5">{title}</Typography>
          {canEdit && (
            <Button onClick={onEdit} variant="contained">
              Редактировать
              <Box ml={1}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Edit16Regular />
                </Grid>
              </Box>
            </Button>
          )}
        </Grid>
      </Box>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 40,
    borderTop: `1px dashed ${theme.palette.custom.overlay}`,
  },
}));

export default RentBlockWrapper;
