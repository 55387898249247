import AbstractDto from 'base/AbstractDto';

export default class AddWaterDto extends AbstractDto {
  type: string = '';
  rentalId: number = 0;
  categoryId: number = 0;
  name: string = '';
  enginePower: number = 0;
  color: string = '';
  maxSpeed: number = 0;
  issueYear: number = 0;
  capacity: number = 0;
  cabinsCount: number = 0;
  needLicense: boolean = false;
  tariffs: Object[] = [];
  wrapper: string = '';
  photos: string[] = [];
  contract: string = '';
  insurances: string[] = [];
  offline: boolean = false;
  comment: string = '';
  deposit: number = 0;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
