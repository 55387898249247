import { Delete24Regular } from '@fluentui/react-icons';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import FileModel from 'modules/files/models/FileModel';

interface IPhotoItemProps {
  handleDelete: (id: number) => void;
  handleOpenViewer: () => void;
  image: FileModel | null;
  canEdit: boolean;
}

const PhotoItem: React.FC<IPhotoItemProps> = ({ handleDelete, handleOpenViewer, image, canEdit }) => {
  const classes = useStyles();

  return (
    <div className={classes.imgContainer}>
      <img onClick={handleOpenViewer} alt="img" src={image?.fullUrl || ''} className={classes.img} />
      {canEdit && (
        <div onClick={() => handleDelete(image?.id || 0)} className={classes.deleteContainer}>
          <Delete24Regular className={classes.icon} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imgContainer: {
    position: 'relative',
    width: 187,
    height: 120,
    borderRadius: 8,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  deleteContainer: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: 5,
    width: 30,
    height: 30,
    borderRadius: 8,
    cursor: 'pointer',
    backdropFilter: 'blur(7.48px)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    margin: 'auto',
  },
}));

export default PhotoItem;
