import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface ITransportBlockWrapperProps {
  children?: React.ReactNode;
}

const TransportBlockWrapper: React.FC<ITransportBlockWrapperProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.featuresContainer}>{children}</div>;
};

const useStyles = makeStyles((theme: Theme) => ({
  featuresContainer: {
    paddingTop: 24,
    borderTop: `1px dashed ${theme.palette.custom.overlay}`,
  },
}));

export default TransportBlockWrapper;
