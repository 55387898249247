import { CalendarFormFields as GlobalCalendarFormFields } from '../forms/CalendarForm';

export enum CalendarFormKeys {
  CALENDAR = 'calendarForm',
}

export type CalendarFormFields = GlobalCalendarFormFields;

export enum CalendarPaginationTypes {
  calendar = 'calendarPagination',
}
