export const UpdateBrandForm = {
  transportId: '',
  brandId: 0,
  modelId: 0,
  number: '',
};

export interface IUpdateBrandForm {
  transportId: string;
  brandId: number;
  modelId: number;
  number: string;
}

export enum UpdateBrandFormFields {
  TRANSPORT_ID = 'transportId',
  BRAND_ID = 'brandId',
  MODEL_ID = 'modelId',
  NUMBER = 'number',
}
