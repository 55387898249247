import {
  ContactCard24Regular,
  DocumentBulletList24Regular,
  DocumentPerson20Regular,
  VehicleCar24Regular,
} from '@fluentui/react-icons';
import React from 'react';

import { DocumentsTypesFields } from 'modules/orders/types/OrdersTypes';

import { OrderStatuses } from '../types/OrdersTypes';

export class OrdersRenderHelper {
  static getSubmitBtn = (status: OrderStatuses | null) => {
    if (!status) {
      return;
    }

    switch (status) {
      case OrderStatuses.RENT: {
        return {
          text: 'Завершить',
          changingStatus: OrderStatuses.COMPLETED,
        };
      }
      case OrderStatuses.CONFIRMED: {
        return {
          text: 'Машина отдана',
          changingStatus: OrderStatuses.RENT,
        };
      }
      case OrderStatuses.PAID: {
        return {
          text: 'Принять',
          changingStatus: OrderStatuses.CONFIRMED,
        };
      }
    }
  };

  static getIconByDocType = (docType: DocumentsTypesFields | null) => {
    switch (docType) {
      case DocumentsTypesFields.DriverLicenseFront:
        return React.createElement(ContactCard24Regular);
      case DocumentsTypesFields.DriverLicenseBack:
        return React.createElement(VehicleCar24Regular);
      case DocumentsTypesFields.PassportMainPage:
        return React.createElement(DocumentPerson20Regular);
      case DocumentsTypesFields.PassportRegistration:
        return React.createElement(DocumentBulletList24Regular);
      case DocumentsTypesFields.WaterLicenseFront:
        return React.createElement(ContactCard24Regular);
      case DocumentsTypesFields.WaterLicenseBack:
        return React.createElement(ContactCard24Regular);
    }
  };

  static getCountInStatuses = (count: number | undefined, statusType: string) => {
    if (count === undefined || statusType === OrderStatuses.COMPLETED || statusType === OrderStatuses.DECLINED) {
      return '';
    }

    return ` (${count})`;
  };

  static getPrepaymentSum = (prePayment: number, sum: number) => {
    return Math.ceil(sum * (prePayment / 100));
  };
}
