import AbstractDto from 'base/AbstractDto';

export default class RefreshDefaultSettingsDto extends AbstractDto {
  delivery: boolean = false;
  deliveryPrice: number = 0;
  prePayment: number = 0;
  deposit: boolean = false;
  speedLimit: boolean = false;
  mileageLimit: boolean = false;
  ageLimit: number = 0;
  experienceLimit: number = 0;
  fuelCostPerLiter: number = 0;
  comment: string = '';
  travelAboard: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
