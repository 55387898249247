import AbstractModel from 'base/AbstractModel';

export default class Period extends AbstractModel {
  start: string | null = null;
  end: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
