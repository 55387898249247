import { IDeliveryTariff } from '../types/DeliveryTariffsTypes';

export const DeliveryTariffsForm: IDeliveryTariffsForm = {
  rentalId: null,
  tariffs: [],
};

export interface IDeliveryTariffsForm {
  rentalId: number | null;
  tariffs: IDeliveryTariff[];
}

export enum DeliveryTariffsFormFields {
  RENTAL_ID = 'rentalId',
  TARIFFS = 'tariffs',
}

export enum DeliveryTariffFields {
  TRANSPORT_ID = 'transportId',
  ID = 'id',
  ZONE = 'zone',
  PRICE = 'price',
}
