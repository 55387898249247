import { makeAutoObservable, set } from 'mobx';
import { routes } from 'routes/routes';

import history from 'base/routes/history';
import Notification from 'base/ui/Notification';
import DateHelper from 'helpers/DateHelper';

import BookingService from './BookingService';
import { BookingForm } from './forms/BookingForm';
import BookingScheduleDay from './models/BookingScheduleDay';
import { BookingFormKeys, BookingModuleFormFields } from './types/BookingTypes';

export class BookingStore {
  loading = false;

  bookingSchedule: BookingScheduleDay[] = [];

  bookingForm = BookingForm;

  private bookingService: BookingService;

  constructor() {
    makeAutoObservable(this);
    this.bookingService = new BookingService();
  }

  getBookingSchedule = (transportId: number, date: string, cb?: () => void) => {
    this.setLoading(true);

    this.bookingService
      .getBookingSchedule(transportId, date)
      .then(bookingSchedule => {
        this.setBookingSchedule(bookingSchedule);

        cb && cb();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  booking = () => {
    const notValidPeriod = DateHelper.getNotValidPeriod(this.bookingForm.startDate, this.bookingForm.endDate);

    if (!!notValidPeriod) {
      Notification.showError(notValidPeriod + '');
      return;
    }

    this.setLoading(true);

    this.bookingService
      .booking(this.bookingForm)
      .then(() => {
        history.push(routes.MainScreen.path);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setBookingSchedule = (bookingSchedule: BookingScheduleDay[]) => {
    this.bookingSchedule = bookingSchedule;
  };

  setForm = (formKey: BookingFormKeys, key: BookingModuleFormFields, value: any) => {
    set(this[formKey], key, value);
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };
}
