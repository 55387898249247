import { Image24Regular } from '@fluentui/react-icons';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface ITransportItemImgProps {
  img?: string;
}

const TransportItemImg: React.FC<ITransportItemImgProps> = ({ img }) => {
  const classes = useStyles();

  return (
    <div className={classes.imgContainer}>
      {img ? (
        <img alt="изображение" className={classes.img} src={img} />
      ) : (
        <Image24Regular className={classes.imgIcon} />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imgContainer: {
    width: 248,
    height: 148,
    overflow: 'hidden',
    backgroundColor: theme.palette.custom.overlay,
    position: 'relative',
  },
  imgIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    fill: theme.palette.custom.onSurface.secondary,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
}));

export default TransportItemImg;
