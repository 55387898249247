import { makeAutoObservable, runInAction } from 'mobx';

import FilesService from './FilesService';
import FileModel from './models/FileModel';
import { FilesTypes, FileTypes } from './types/FileTypes';

export class FilesStore {
  loading = false;

  image: FileModel | null = null;
  pdf: FileModel | null = null;

  privateFile: string | null = null;

  images: FileModel[] = [];
  pdfs: FileModel[] = [];

  private filesService: FilesService;

  constructor() {
    makeAutoObservable(this);
    this.filesService = new FilesService();
  }

  uploadImage = (file: File, cb?: (data: FileModel) => void) => {
    this.setLoading(true);

    this.filesService
      .uploadImage(file)
      .then(data => {
        this.setFile(data, FileTypes.IMAGE);
        cb && cb(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  uploadImages = (file: FileList, isFullRefresh: boolean = false) => {
    this.setLoading(true);

    this.filesService
      .uploadImages(file, this.images.length)
      .then(data => {
        if (this.images.length && !isFullRefresh) {
          this.setFiles([...this.images, ...data], FilesTypes.IMAGE);
        } else {
          this.setFiles(data, FilesTypes.IMAGE);
        }
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  uploadPdf = (file: File) => {
    this.setLoading(true);

    this.filesService
      .uploadPdf(file)
      .then(data => {
        this.setFile(data, FileTypes.PDF);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  uploadPdfs = (file: FileList) => {
    this.setLoading(true);

    this.filesService
      .uploadPdfs(file)
      .then(data => {
        if (this.pdfs.length) {
          this.setFiles([...this.pdfs, ...data], FilesTypes.PDF);
        } else {
          this.setFiles(data, FilesTypes.PDF);
        }
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  getPrivateFile = (uuid: string) => {
    this.setLoading(true);

    this.filesService
      .getPrivateFile(uuid)
      .then(data => {
        runInAction(() => {
          this.privateFile = data;
        });
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  clearStore = () => {
    Object.values(FileTypes).forEach(type => {
      this.setFile(null, type);
    });

    Object.values(FilesTypes).forEach(type => {
      this.setFiles([], type);
    });
  };

  setFile = (data: FileModel | null, type: FileTypes) => {
    this[type] = data;
  };

  setFiles = (data: FileModel[], type: FilesTypes) => {
    this[type] = data;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };
}
