import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

import SwitchingForm from './SwitchingForm';

interface IEditModalWrapperProps {
  title: string;
  showBtns?: boolean;
  children?: React.ReactNode;
  onSave?: () => void;
  onClose?: () => void;
  saveBtnText?: string;
  isForm?: boolean;
}

const EditModalWrapper: React.FC<IEditModalWrapperProps> = props => {
  const { children, title, onClose, onSave, saveBtnText, showBtns = true, isForm = true } = props;

  // Renders
  return (
    <SwitchingForm isForm={isForm} onSave={onSave}>
      <Box mb={3}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      {children}
      {showBtns && onClose && onSave ? (
        <Grid container justifyContent="flex-end">
          <Box mr={2}>
            <Button onClick={onClose} variant="contained">
              Отменить
            </Button>
          </Box>
          <Button variant="contained" color="primary" type="submit" onClick={isForm ? () => {} : onSave}>
            {saveBtnText || 'Сохранить'}
          </Button>
        </Grid>
      ) : null}
    </SwitchingForm>
  );
};

export default EditModalWrapper;
