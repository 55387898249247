import { initTariff } from '../consts/TransportConsts';

export const UpdateTariffsForm = {
  transportId: 0,
  tariffs: [{ ...initTariff, id: 0 }],
};

export interface IUpdateTariffsForm {
  transportId: number;
  tariffs: Object[];
}

export enum UpdateTariffsFormFields {
  TRANSPORT_ID = 'transportId',
  TARIFFS = 'tariffs',
}
