import { AddAutoFormFields } from '../forms/AddAutoForm';
import { AddWaterFormFields } from '../forms/AddWaterForm';
import { UpdateAutoFormFields } from '../forms/UpdateAutoForm';
import { UpdateBrandFormFields } from '../forms/UpdateBrandForm';
import { UpdateDepositFormFields } from '../forms/UpdateDepositForm';
import { UpdateStatusFormFields } from '../forms/UpdateStatusForm';
import { UpdateTariffsFormFields } from '../forms/UpdateTariffsForm';
import { UpdateWaterFormFields } from '../forms/UpdateWaterForm';

export enum TransportFormKeys {
  ADD_AUTO = 'addAutoForm',
  ADD_WATER = 'addWaterForm',
  UPDATE_BRAND = 'updateBrandForm',
  UPDATE_AUTO = 'updateAutoForm',
  UPDATE_WATER = 'updateWaterForm',
  UPDATE_TARIFFS = 'updateTariffsForm',
  UPDATE_DEPOSIT = 'updateDepositForm',
  UPDATE_STATUS = 'updateStatusForm',
}

export type TransportFormFields =
  | AddAutoFormFields
  | AddWaterFormFields
  | UpdateBrandFormFields
  | UpdateAutoFormFields
  | UpdateWaterFormFields
  | UpdateTariffsFormFields
  | UpdateDepositFormFields
  | UpdateStatusFormFields;

export enum TransportPaginationTypes {
  BRANDS = 'brandsPagination',
  MODELS = 'modelsPagination',
  AUTO_TRANSPORT = 'autoTransportPagination',
  WATER_TRANSPORT = 'waterTransportPagination',
}

export enum TransportStatuses {
  FREE = 'free',
  IN_USE = 'in_use',
  OFF_LINE = 'off_line',
}

export enum TypesOfTransport {
  WATER = 'water',
  AUTO = 'auto',
}

export enum TransportCardModals {
  EDIT_MARK = 'editMark',
  EDIT_CATEGORY = 'editCategory',
  EDIT_FEATURES = 'editFeatures',
  EDIT_TARIFFS = 'editTariffs',
  EDIT_DEPOSIT = 'editDeposit',
  EDIT_DELIVERY = 'editDelivery',
  DELETE_TRANSPORT = 'deleteTransport',
  EDIT_MILEAGE_LIMIT = 'editMileageLimit',
  EDIT_SPEED_LIMIT = 'editSpeedLimit',
  EDIT_COMMENT = 'editComment',
  EDIT_RERUN = 'editRerun',
}
