import { Add20Regular, Dismiss20Regular } from '@fluentui/react-icons';
import {
  DialogProps,
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import { RenderHelper } from 'helpers/RenderHelper';
import { initDeliveryTariff } from 'modules/rental/modules/deliveryTariffs/consts/DeliveryTariffsConsts';
import {
  DeliveryTariffFields,
  DeliveryTariffsFormFields,
} from 'modules/rental/modules/deliveryTariffs/forms/DeliveryTariffsForm';
import { IDeliveryTariff } from 'modules/rental/modules/deliveryTariffs/types/DeliveryTariffsTypes';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';

interface IRefreshDeliveryModalProps extends DialogProps {
  onClose: () => void;
}

const RefreshDeliveryModal: React.FC<IRefreshDeliveryModalProps> = observer(({ onClose, ...rest }) => {
  const { TARIFFS } = DeliveryTariffsFormFields;
  const { deliveryTariffsStore } = useRootStore();
  const [newTariffId, setNewTariffId] = useState(1);
  const classes = useStyles();

  // Effects
  useEffect(() => {
    if (deliveryTariffsStore.deliveryTariffsForm[TARIFFS].length === 0 && rest.open) {
      handleAddTariff();
    }
  }, [rest.open]);

  // Handlers
  const handleAddTariff = () => {
    const tariffs = [...deliveryTariffsStore.deliveryTariffsForm[TARIFFS]];
    let id = newTariffId + 1;

    setNewTariffId(id);
    tariffs.push({ ...initDeliveryTariff, id: newTariffId });

    deliveryTariffsStore.setForm(TARIFFS, tariffs);
  };

  const handleDeleteTariff = (tariffId: number) => {
    const tariffs = deliveryTariffsStore.deliveryTariffsForm[TARIFFS];

    deliveryTariffsStore.setForm(TARIFFS, RenderHelper.deleteElem<IDeliveryTariff>(tariffs, tariffId, 'id'));
  };

  const handleChangeTariff = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tariffId: number) => {
    const { name, value } = event.target;
    const tariffs = deliveryTariffsStore.deliveryTariffsForm[TARIFFS];

    deliveryTariffsStore.setForm(
      TARIFFS,
      TransportRenderHelper.changeDeliveryTariff(tariffs, tariffId, name as DeliveryTariffFields, value),
    );
  };

  const handleClose = () => {
    deliveryTariffsStore.setForm(TARIFFS, deliveryTariffsStore.deliveryTariffs);

    onClose();
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    deliveryTariffsStore.updateDeliveryTariffs(onClose);
  };

  // Renders
  const renderTariffs = () => {
    return deliveryTariffsStore.deliveryTariffsForm[TARIFFS].map((item, index) => {
      return (
        <Box key={item.id} className={classes.container} mb={2}>
          <Grid spacing={1} container>
            <Grid className={classes.borderBottom} xs={7} item>
              <TextField
                placeholder="Зона доставки"
                name={DeliveryTariffFields.ZONE}
                defaultValue={item?.zone}
                onChange={e => handleChangeTariff(e, item.id || 0)}
                inputProps={{ maxLength: 25 }}
                fullWidth
                required
              />
            </Grid>
            <Box display="flex" alignItems="center" mx={1.5}>
              <Typography variant="h4">стоит</Typography>
            </Box>
            <Grid className={classes.borderBottom} xs={3} item>
              <TextField
                variant="standard"
                placeholder={`Цена`}
                name={DeliveryTariffFields.PRICE}
                defaultValue={item?.price}
                onChange={e => handleChangeTariff(e, item.id || 0)}
                type="number"
                onKeyDown={RenderHelper.disableInputKeyDownEvent}
                onWheel={RenderHelper.disableInputWheelEvent}
                inputProps={{
                  min: 0,
                  step: 0.01,
                  lang: 'en-US',
                }}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          {index !== 0 ? (
            <IconButton className={classes.iconBtn} onClick={() => handleDeleteTariff(item.id || 0)}>
              <Dismiss20Regular />
            </IconButton>
          ) : null}
        </Box>
      );
    });
  };

  return (
    <QModal onClose={handleClose} {...rest} modalClassName={classes.modal}>
      <EditModalWrapper isForm={false} title="Редактировать доставку" showBtns={false}>
        <form onSubmit={handleSubmit} className={classes.tariffsEditBtnsForm}>
          <Box mb={10}>{renderTariffs()}</Box>
          <div className={classes.tariffsEditBtns}>
            <Button onClick={handleAddTariff} variant="contained">
              <Box mr={1}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Add20Regular />
                </Grid>
              </Box>
              Добавить зону доставки
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Сохранить
            </Button>
          </div>
        </form>
      </EditModalWrapper>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  tariffsEditBtnsForm: {
    position: 'relative',
    height: '100%',
  },
  tariffsEditBtns: {
    display: 'flex',
    position: 'absolute',
    bottom: -96,
    width: '100%',
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  modal: {
    minHeight: 232,
  },
  iconBtn: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
  container: {
    position: 'relative',
    padding: '24px 16px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  borderBottom: {
    borderBottom: '1px solid',
  },
}));

export default RefreshDeliveryModal;
