interface ISwitchingFormProps {
  children: React.ReactNode;
  isForm?: boolean;
  onSave?: () => void;
  formClassName?: string;
}

const SwitchingForm: React.FC<ISwitchingFormProps> = ({ children, isForm = true, onSave, formClassName }) => {
  // Handlers
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSave && onSave();
  };

  // Renders
  if (isForm) {
    return (
      <form className={formClassName} onSubmit={handleSubmit}>
        {children}
      </form>
    );
  }

  return <div className={formClassName}>{children}</div>;
};

export default SwitchingForm;
