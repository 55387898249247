import { makeAutoObservable, set } from 'mobx';

import DeliveryTariffsService from './DeliveryTariffsService';
import { DeliveryTariffsForm, DeliveryTariffsFormFields } from './forms/DeliveryTariffsForm';
import DeliveryTariff from './models/DeliveryTariff';

export class DeliveryTariffsStore {
  loading = false;

  deliveryTariffs: DeliveryTariff[] = [];
  deliveryTariffsForm = DeliveryTariffsForm;

  private deliveryTariffsService: DeliveryTariffsService;

  constructor() {
    makeAutoObservable(this);
    this.deliveryTariffsService = new DeliveryTariffsService();
  }

  initDeliveryTariffs = (rentalId: number | null | undefined) => {
    if (!rentalId && rentalId !== 0) {
      return;
    }

    this.setForm(DeliveryTariffsFormFields.RENTAL_ID, rentalId);
    this.getDeliveryTariffs(rentalId);
  };

  getDeliveryTariffs = (rentalId: number) => {
    this.setLoading(true);

    this.deliveryTariffsService
      .getDeliveryTariffs(rentalId)
      .then(data => {
        this.setDeliveryTariffs(data);
        this.setForm(DeliveryTariffsFormFields.TARIFFS, data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  updateDeliveryTariffs = (cb?: () => void) => {
    this.setLoading(true);

    this.deliveryTariffsService
      .updateDeliveryTariffs(this.deliveryTariffsForm)
      .then(data => {
        this.setDeliveryTariffs(data);
        cb?.();
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setDeliveryTariffs = (tariffs: DeliveryTariff[]) => {
    this.deliveryTariffs = tariffs;
  };

  setForm = (key: DeliveryTariffsFormFields, value: any) => {
    set(this.deliveryTariffsForm, key, value);
  };
}
