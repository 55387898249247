import RouteHelper from 'base/routes/helpers/RouteHelper';
import { TypesOfRental } from 'modules/rental/types/RentalTypes';

export const navLinks = (rentalType: TypesOfRental = TypesOfRental.AUTOMOBILE) => [
  {
    to: RouteHelper.getTransportLink(rentalType),
    title: 'Транспорт',
    isActiveWithNested: true,
  },
  {
    to: '/orders?type=paid',
    title: 'Заказы',
  },
  {
    to: '/calendar',
    title: 'Календарь',
  },
  // {
  //   to: routes.PromoCodesScreen.path,
  //   title: 'Промокоды',
  // },
  // {
  //   to: routes.AnalyticsScreen.path,
  //   title: 'Аналитика',
  // },
];
