import { Box, Button, Collapse, debounce, DialogProps } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import { PhoneMaskInput } from 'components/UI/NumberMaskedInputs';
import QModal from 'components/UI/QModal';
import QInput from 'components/UI/textFields/QInput';
import { RefreshAddressAndPhoneFormFields } from 'modules/rental/forms/RefreshAddressAndPhoneForm';
import { RentalRenderHelper } from 'modules/rental/helpers/RentalRenderHelper';
import Address from 'modules/rental/models/Address';
import { RentalFormKeys } from 'modules/rental/types/RentalTypes';

interface IRefreshAddressAndPhoneModalProps extends DialogProps {
  onClose: () => void;
}

const RefreshAddressAndPhoneModal: React.FC<IRefreshAddressAndPhoneModalProps> = observer(({ onClose, ...rest }) => {
  const { rentalStore } = useRootStore();
  const [searchValue, setSearchValue] = useState('');

  const searchApiCall = useCallback(
    debounce((value: string) => {
      rentalStore.searchAddress(value);
    }, 400),
    [],
  );

  // Effects
  useEffect(() => {
    if (rentalStore.refreshAddressAndPhoneForm.address?.unrestrictedValue) {
      handleChangeAddress(rentalStore.refreshAddressAndPhoneForm.address);
      setSearchValue(rentalStore.refreshAddressAndPhoneForm.address?.unrestrictedValue);
    }
  }, [rentalStore.refreshAddressAndPhoneForm?.address?.unrestrictedValue, rest.open]);

  // Handlers
  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    rentalStore.setForm(RentalFormKeys.REFRESH_ADDRESS_AND_PHONE, RefreshAddressAndPhoneFormFields.PHONE, value);
  };

  const handleChangeAddress = (value: Address) => {
    rentalStore.setForm(RentalFormKeys.REFRESH_ADDRESS_AND_PHONE, RefreshAddressAndPhoneFormFields.ADDRESS, value);
  };

  const handleSearch = (value: string | null) => {
    if (!value && value !== '') {
      return;
    }

    setSearchValue(value);
    searchApiCall(value);
  };

  const handleSearchCardClick = (addressObject: Address) => {
    const { unrestrictedValue } = addressObject;

    handleSearch(RentalRenderHelper.getSearchAddressString([unrestrictedValue]));
    handleChangeAddress(addressObject);
  };

  const handleSave = () => {
    rentalStore.refreshAddressAndPhone(onClose);
  };

  // Renders
  const renderSearchResults = () => {
    return rentalStore.searchAddressData.map((item: Address) => {
      const { unrestrictedValue } = item;

      return (
        <Button key={item.id} onClick={() => handleSearchCardClick(item)}>
          <Box display="flex">
            {/* TODO: сверстать блок, когда будет дизайн (сейчас тестовая вёрстка) */}
            {unrestrictedValue && <h4>{unrestrictedValue} </h4>}
          </Box>
        </Button>
      );
    });
  };

  return (
    <QModal {...rest} onClose={onClose}>
      <EditModalWrapper onClose={onClose} onSave={handleSave} title="Редактировать адрес и телефон">
        <Box mb={3}>
          <QInput
            value={searchValue}
            name={RefreshAddressAndPhoneFormFields.ADDRESS}
            label="Адрес"
            onChange={e => handleSearch(e.target.value)}
            required
          />
        </Box>
        <Collapse in={!!rentalStore.searchAddressData?.length}>{renderSearchResults()}</Collapse>
        <Box mb={5}>
          <QInput
            name={RefreshAddressAndPhoneFormFields.PHONE}
            onChange={handleChangePhone}
            label="Телефон"
            variant="outlined"
            value={rentalStore.refreshAddressAndPhoneForm[RefreshAddressAndPhoneFormFields.PHONE]}
            fullWidth
            InputProps={{ inputComponent: PhoneMaskInput }}
            required
          />
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

export default RefreshAddressAndPhoneModal;
