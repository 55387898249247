export const ChangeOrderStatusForm = {
  status: '',
  orderId: 0,
  reason: '',
  comment: '',
};

export interface IChangeOrderStatusForm {
  status: string;
  orderId: number;
  reason: string;
  comment: string;
}

export enum ChangeOrderStatusFormFields {
  STATUS = 'status',
  ORDER_ID = 'orderId',
  REASON = 'reason',
  COMMENT = 'comment',
}
