import { CalendarLtr20Regular, ChevronRight20Filled, Edit16Regular } from '@fluentui/react-icons';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import DocumentItem from 'components/UI/DocumentItem';
import Loader from 'components/UI/Loader';
import UploadDocument from 'components/UI/UploadDocument';
import FileModel from 'modules/files/models/FileModel';
import { UpdateAutoFormFields } from 'modules/transport/forms/UpdateAutoForm';
import { UpdateStatusFormFields } from 'modules/transport/forms/UpdateStatusForm';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import {
  TransportCardModals,
  TransportFormKeys,
  TransportStatuses,
  TypesOfTransport,
} from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import MainFeaturesItem from '../components/MainFeaturesItem';
import PhotoList from '../components/PhotoList';
import TransportBlockWrapper from '../components/TransportBlockWrapper';
import EditCommentModal from '../components/modals/EditCommentModal';
import EditDepositModal from '../components/modals/EditDepositModal';
import EditTariffsModal from '../components/modals/EditTariffsModal';
import Feature from './components/Feature';
import DeleteModal from './components/modals/DeleteModal';
import EditDeliveryModal from './components/modals/EditDeliveryModal';
import EditFeaturesModal from './components/modals/EditFeaturesModal';
import EditMarkModal from './components/modals/EditMarkModal';
import EditMileageLimitModal from './components/modals/EditMileageLimitModal';
import EditRerunModal from './components/modals/EditRerunModal';
import EditSpeedLimitModal from './components/modals/EditSpeedLimitModal';

const AutoTransportCardScreen = observer(() => {
  const { transportStore, filesStore, authStore, rentalStore } = useRootStore();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const commonClasses = useCommonStyles();

  const [openedModals, setOpenedModals] = useState(
    Object.assign(Object.values(TransportCardModals).map(item => ({ [item]: false }))),
  );

  // Effects
  useEffect(() => {
    transportStore.getTransportInfo(Number(id));
    transportStore.getAllDetails();

    return () => {
      filesStore.clearStore();
    };
  }, []);

  useEffect(() => {
    if (filesStore.pdf) {
      transportStore.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.CONTRACT, filesStore.pdf?.uuid);

      transportStore.updateAuto();
    }
  }, [filesStore.pdf]);

  useEffect(() => {
    transportStore.initAutoTransportCardScreen();
  }, [
    transportStore.currentTransportInfo?.autoTransport,
    transportStore.fuelTypes,
    transportStore.driveTypes,
    transportStore.bodyTypes,
    transportStore.transmissionTypes,
  ]);

  // Handlers
  const handleOpenModal = (modal: TransportCardModals) => {
    setOpenedModals({ ...openedModals, [modal]: true });
  };

  const handleCloseModal = (modal: TransportCardModals) => {
    transportStore.initAutoTransportCardScreen();

    setOpenedModals({ ...openedModals, [modal]: false });
  };

  const handleToggleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    transportStore.setForm(
      TransportFormKeys.UPDATE_STATUS,
      UpdateStatusFormFields.STATUS,
      checked ? TransportStatuses.IN_USE : TransportStatuses.FREE,
    );
    transportStore.updateStatus();
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    transportStore.setForm(TransportFormKeys.UPDATE_AUTO, name as UpdateAutoFormFields, checked);
    transportStore.updateAuto();
  };

  const handleAddContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    filesStore.uploadPdf(e.target.files[0]);
  };

  const handleAddPhotos = (images: FileModel[]) => {
    const uuids = images.map(item => item.uuid);

    transportStore.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.PHOTOS, uuids);
    transportStore.updateAuto();
  };

  const handleAddWrapper = (image: FileModel) => {
    transportStore.setForm(TransportFormKeys.UPDATE_AUTO, UpdateAutoFormFields.WRAPPER, image.uuid);
    transportStore.updateAuto();
  };

  const handleDeletePhoto = (id: number) => {
    transportStore.deleteTransportPhoto(id);
  };

  // Renders
  const renderTariffs = () => {
    return transportStore.currentTransportInfo?.tariffs?.map(item => {
      return (
        <MainFeaturesItem
          key={item.id}
          label={TransportRenderHelper.getTariffLabel(TypesOfTransport.AUTO, item.start, item.end, item.isSingleDay)}
          value={`${item.price?.toLocaleString()} ₽${!item.isSingleDay ? ' / сутки' : ''}`}
        />
      );
    });
  };

  if (!transportStore.currentTransportInfo && transportStore.loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Box mb={6}>
        <Breadcrumbs separator={<ChevronRight20Filled />} aria-label="breadcrumb">
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={routes.AutoTransportScreen.path}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              Список автомобилей
            </Typography>
          </Link>
          <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
            {`${transportStore.currentTransportInfo?.autoTransport?.brand?.name} ${transportStore.currentTransportInfo?.autoTransport?.model?.name}`}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box mb={0.5}>
        <Grid alignItems="center" container>
          <Box mr={2}>
            <Typography variant="h1">
              {`${transportStore.currentTransportInfo?.autoTransport?.brand?.name} ${transportStore.currentTransportInfo?.autoTransport?.model?.name}`}
            </Typography>
          </Box>
          {authStore.userInfo?.canEdit && (
            <>
              <Button onClick={() => handleOpenModal(TransportCardModals.EDIT_MARK)} variant="contained">
                Изменить
              </Button>
              <Button
                onClick={() => handleOpenModal(TransportCardModals.DELETE_TRANSPORT)}
                className={classes.deleteBtn}
                variant="contained"
              >
                Удалить транспорт
              </Button>
            </>
          )}
        </Grid>
      </Box>
      <Box mb={5}>
        <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
          {transportStore.currentTransportInfo?.autoTransport?.stateNumber}
        </Typography>
      </Box>
      <Box mb={5}>
        <Link
          className={commonClasses.textDecorationNone}
          to={`/transport/auto/${transportStore.currentTransportInfo?.id}/booking`}
        >
          <Button variant="contained" color="primary">
            Забронировать
            <Box ml={1.5}>
              <Grid container alignItems="center" justifyContent="center">
                <CalendarLtr20Regular />
              </Grid>
            </Box>
          </Button>
        </Link>
      </Box>
      <Box mb={4}>
        <PhotoList
          handleApiDelete={handleDeletePhoto}
          handleAddPhotos={handleAddPhotos}
          handleAddWrapper={handleAddWrapper}
          isTransportCard
          files={transportStore.currentTransportInfo?.photos}
          defaultWrapper={transportStore.currentTransportInfo?.wrapper}
          canEdit={!!authStore.userInfo?.canEdit}
        />
      </Box>
      <Box mb={3}>
        <TransportBlockWrapper>
          <Box mb={3}>
            <Grid alignItems="center" container>
              <Box mr={3}>
                <Typography className={commonClasses.onSurfaceSecondary} variant="h5">
                  Основные характеристики
                </Typography>
              </Box>
              {authStore.userInfo?.canEdit && (
                <Button onClick={() => handleOpenModal(TransportCardModals.EDIT_FEATURES)} variant="contained">
                  Изменить
                </Button>
              )}
            </Grid>
          </Box>
          <Grid container>
            <Grid xs={5} item>
              <MainFeaturesItem
                label="Двигатель"
                value={`${transportStore.currentTransportInfo?.autoTransport?.engineVolume} л./${transportStore.currentTransportInfo?.autoTransport?.enginePower} л.с.`}
              />
              <MainFeaturesItem
                label="0-100 км/ч"
                value={`${transportStore.currentTransportInfo?.autoTransport?.racing} с`}
              />
              <MainFeaturesItem
                label="Топливо"
                value={`${transportStore.currentTransportInfo?.autoTransport?.fuelType}`}
              />
              <MainFeaturesItem label="Привод" value={`${transportStore.currentTransportInfo?.autoTransport?.drive}`} />
              <MainFeaturesItem
                label="Макс. скорость"
                value={`${transportStore.currentTransportInfo?.autoTransport?.maxSpeed} км/ч`}
              />
              <MainFeaturesItem
                label="Коробка"
                value={transportStore.currentTransportInfo?.autoTransport?.transmission || ''}
              />
              <MainFeaturesItem
                label="Расход"
                value={`${transportStore.currentTransportInfo?.autoTransport?.fuelConsumption} л. на 100 км`}
              />
            </Grid>
            <Grid xs={5} item>
              <MainFeaturesItem
                label="Кузов"
                value={transportStore.currentTransportInfo?.autoTransport?.bodyType || ''}
              />
              <MainFeaturesItem label="Цвет" value={transportStore.currentTransportInfo?.autoTransport?.color || ''} />
              <MainFeaturesItem
                label="Год выпуска"
                value={`${transportStore.currentTransportInfo?.autoTransport?.issueYear}`}
              />
              <MainFeaturesItem
                label="Музыка"
                value={transportStore.currentTransportInfo?.autoTransport?.music || ''}
              />
              <MainFeaturesItem
                label="Страховка"
                value={TransportRenderHelper.getInsurancesNames(
                  transportStore.currentTransportInfo?.autoTransport?.insurances,
                )}
              />
              <MainFeaturesItem
                label="Доплата за мойку"
                value={
                  transportStore.currentTransportInfo?.autoTransport?.washFee
                    ? `${transportStore.currentTransportInfo?.autoTransport?.washFee?.toLocaleString()} ₽`
                    : ''
                }
              />
            </Grid>
          </Grid>
        </TransportBlockWrapper>
      </Box>
      <Box mb={3}>
        <TransportBlockWrapper>
          <Box mb={3}>
            <Grid alignItems="center" container>
              <Box mr={3}>
                <Typography className={commonClasses.onSurfaceSecondary} variant="h5">
                  Тарифы
                </Typography>
              </Box>
              {authStore.userInfo?.canEdit && (
                <Button onClick={() => handleOpenModal(TransportCardModals.EDIT_TARIFFS)} variant="contained">
                  Изменить
                </Button>
              )}
            </Grid>
          </Box>
          <Grid container>
            <Grid xs={5} item>
              {renderTariffs()}
            </Grid>
          </Grid>
        </TransportBlockWrapper>
      </Box>
      <Box mb={3}>
        <TransportBlockWrapper>
          {rentalStore.currentRental?.delivery ? (
            <Box mb={2}>
              <Feature
                onClick={() => handleOpenModal(TransportCardModals.EDIT_DELIVERY)}
                title="Доставка машины"
                value={`${transportStore.currentTransportInfo?.autoTransport?.deliveryPrice || 0} ₽`}
                canEdit={!!authStore.userInfo?.canEdit}
              />
            </Box>
          ) : null}
          {rentalStore.currentRental?.deposit ? (
            <Box mb={2}>
              <Feature
                onClick={() => handleOpenModal(TransportCardModals.EDIT_DEPOSIT)}
                title="Залог"
                value={`${transportStore.currentTransportInfo?.autoTransport?.deposit || 0} ₽`}
                canEdit={!!authStore.userInfo?.canEdit}
              />
            </Box>
          ) : null}
          {rentalStore.currentRental?.mileageLimit ? (
            <Box mb={2}>
              <Feature
                onClick={() => handleOpenModal(TransportCardModals.EDIT_MILEAGE_LIMIT)}
                title="Ограничения км в день"
                value={`${transportStore.currentTransportInfo?.autoTransport?.mileageLimit || 0} км/день`}
                canEdit={!!authStore.userInfo?.canEdit}
              />
            </Box>
          ) : null}
          {rentalStore.currentRental?.speedLimit ? (
            <Box mb={2}>
              <Feature
                onClick={() => handleOpenModal(TransportCardModals.EDIT_SPEED_LIMIT)}
                title="Ограничения скорости"
                value={`${transportStore.currentTransportInfo?.autoTransport?.speedLimit || 0} км/ч`}
                canEdit={!!authStore.userInfo?.canEdit}
              />
            </Box>
          ) : null}
          <Feature
            onClick={() => handleOpenModal(TransportCardModals.EDIT_RERUN)}
            title="Доплата за перепробег"
            value={`${transportStore.currentTransportInfo?.autoTransport?.rerunPrice || 0} руб/км`}
            canEdit={!!authStore.userInfo?.canEdit}
          />
        </TransportBlockWrapper>
      </Box>
      <Box mb={3}>
        <TransportBlockWrapper>
          <Box mb={3} display="flex" alignItems="center">
            <Box mr={3}>
              <FormControlLabel
                color="primary"
                control={
                  <Switch
                    color={'primary'}
                    name={UpdateAutoFormFields.TRAVEL_OUT_OF_CITY}
                    onChange={handleToggleSwitches}
                    checked={transportStore.updateAutoForm[UpdateAutoFormFields.TRAVEL_OUT_OF_CITY]}
                  />
                }
                label="Выезд за город"
              />
            </Box>
            <FormControlLabel
              color="primary"
              control={
                <Switch
                  color={'primary'}
                  name={UpdateAutoFormFields.TRAVEL_ABOARD}
                  onChange={handleToggleSwitches}
                  checked={transportStore.updateAutoForm[UpdateAutoFormFields.TRAVEL_ABOARD]}
                />
              }
              label="Выезд за границу"
            />
          </Box>
          <Box mb={3}>
            <Box mb={2}>
              <Typography variant="h3">Загруженный документ:</Typography>
              <Typography variant="body1">Будет отображаться в карточке машины</Typography>
            </Box>
            <DocumentItem document={transportStore.currentTransportInfo?.contract || null} />
            {authStore.userInfo?.canEdit && (
              <Box mt={2}>
                <UploadDocument handleUploadDocument={handleAddContract} document={filesStore.pdf} />
              </Box>
            )}
          </Box>

          <Box maxWidth={592}>
            <Box display="flex">
              <Box mr={3}>
                <Typography variant="h3">Комментарий</Typography>
                <div className={classes.commentWrapper}>
                  <Typography variant="body1">{transportStore.currentTransportInfo?.comment || 'Нет'}</Typography>
                </div>
              </Box>
              {authStore.userInfo?.canEdit && (
                <Box>
                  <Button
                    onClick={() => handleOpenModal(TransportCardModals.EDIT_COMMENT)}
                    className={classes.editBtn}
                    variant="contained"
                  >
                    <Edit16Regular />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </TransportBlockWrapper>
      </Box>
      <TransportBlockWrapper>
        <FormControlLabel
          color="primary"
          control={
            <Switch
              checked={
                TransportRenderHelper.getTransportStatus(transportStore.updateStatusForm) === TransportStatuses.IN_USE
              }
              onChange={handleToggleStatus}
              color="primary"
            />
          }
          label="Снять машину с линии"
        />
        <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
          Машина не будет доступна для бронирования и не будет отображаться в каталоге, пока вы не вернёте ее на линию.
        </Typography>
      </TransportBlockWrapper>

      <EditMarkModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_MARK)}
        open={!!openedModals[TransportCardModals.EDIT_MARK]}
      />
      <EditFeaturesModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_FEATURES)}
        open={!!openedModals[TransportCardModals.EDIT_FEATURES]}
      />
      <DeleteModal
        onClose={() => handleCloseModal(TransportCardModals.DELETE_TRANSPORT)}
        open={!!openedModals[TransportCardModals.DELETE_TRANSPORT]}
      />
      <EditTariffsModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_TARIFFS)}
        open={!!openedModals[TransportCardModals.EDIT_TARIFFS]}
        transportType={TypesOfTransport.AUTO}
      />

      <EditDepositModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_DEPOSIT)}
        open={!!openedModals[TransportCardModals.EDIT_DEPOSIT]}
      />
      <EditDeliveryModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_DELIVERY)}
        open={!!openedModals[TransportCardModals.EDIT_DELIVERY]}
      />
      <EditMileageLimitModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_MILEAGE_LIMIT)}
        open={!!openedModals[TransportCardModals.EDIT_MILEAGE_LIMIT]}
      />
      <EditSpeedLimitModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_SPEED_LIMIT)}
        open={!!openedModals[TransportCardModals.EDIT_SPEED_LIMIT]}
      />
      <EditRerunModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_RERUN)}
        open={!!openedModals[TransportCardModals.EDIT_RERUN]}
      />

      <EditCommentModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_COMMENT)}
        open={!!openedModals[TransportCardModals.EDIT_COMMENT]}
        transportType={TypesOfTransport.AUTO}
      />
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  deleteBtn: {
    marginLeft: 'auto',
    color: theme.palette.custom.primary.red,
  },
  editBtn: {
    padding: 6,
    width: 32,
    height: 32,
    minWidth: 0,
  },
  commentWrapper: {
    wordBreak: 'break-word',
  },
}));

export default AutoTransportCardScreen;
