import PaginationModel from 'base/modules/pagination/PaginationModel';
import DateHelper from 'helpers/DateHelper';
import ParamsGenerationHelper from 'helpers/ParamsGenerationHelper';

import CalendarApiRepository from './CalendarApiRepository';
import { CalendarFactory } from './CalendarFactory';
import { ICalendarForm } from './forms/CalendarForm';
import { IDayToView } from './interfaces/CalendarInterfaces';
import AutoCalendarItem from './models/AutoCalendarItem';
import CalendarDayItem from './models/CalendarDayItem';
import WaterCalendarItem from './models/WaterCalendarItem';

export default class CalendarService {
  api: CalendarApiRepository;
  factory: CalendarFactory;

  constructor() {
    this.api = new CalendarApiRepository();
    this.factory = new CalendarFactory();
  }

  getAutoCalendar = async (calendarForm: ICalendarForm, offset: number) => {
    const params = ParamsGenerationHelper.getSerializedParams({
      ...calendarForm,
      date: DateHelper.formatDateForServer(calendarForm.date),
      limit: 20,
      offset,
    });
    const { data } = await this.api.getAutoCalendar(params);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    const autoCalendar = this.factory.createAutoCalendar(data.data.items);
    const daysMapToView = this.getCalendarToView(autoCalendar);

    return {
      autoCalendar,
      daysMapToView,
      pagination,
    };
  };

  getWaterCalendar = async (calendarForm: ICalendarForm, offset: number) => {
    const params = ParamsGenerationHelper.getSerializedParams({
      ...calendarForm,
      date: DateHelper.formatDateForServer(calendarForm.date),
      limit: 20,
      offset,
    });
    const { data } = await this.api.getWaterCalendar(params);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    const waterCalendar = this.factory.createWaterCalendar(data.data.items);
    const daysMapToView = this.getCalendarToView(waterCalendar);

    return {
      waterCalendar,
      daysMapToView,
      pagination,
    };
  };

  private getCalendarToView = (calendar: AutoCalendarItem[] | WaterCalendarItem[]) => {
    const res: Record<string, IDayToView[][]> = {};

    for (let i = 0; i < calendar.length; i++) {
      const calendarItem = calendar[i];
      res[calendarItem.id ?? ''] = this.getCalendarDaysToView(calendarItem.schedule.days);
    }

    return res;
  };

  private getCalendarDaysToView = (days: CalendarDayItem[]) => {
    const res: IDayToView[][] = [];

    let flagDateIndex: number | null = null;

    for (let i = 0; i < days.length; i++) {
      const prevDay = i === 0 ? null : days[i - 1];
      const nextDay = i === days.length - 1 ? null : days[i + 1];
      const currentDay = days[i];

      if (flagDateIndex === null) {
        flagDateIndex = i;
      }

      if (currentDay.empty) {
        res.push([]);
        flagDateIndex = null;

        continue;
      }

      let day: IDayToView[] = [];

      if (currentDay.fullFilled || currentDay.filledPeriods?.length === 1) {
        const dayInfo: IDayToView = currentDay.fullFilled
          ? { start: '00:00', end: '23:59' }
          : { start: currentDay?.filledPeriods?.[0]?.start ?? '', end: currentDay?.filledPeriods?.[0]?.end ?? '' };

        if (
          (prevDay?.filledPeriods?.[prevDay?.filledPeriods?.length - 1]?.end === '23:59' || prevDay?.fullFilled) &&
          dayInfo?.start === '00:00'
        ) {
          dayInfo.leftConnection = true;
        } else {
          dayInfo.leftConnection = false;
        }

        if ((nextDay?.filledPeriods?.[0]?.start === '00:00' || nextDay?.fullFilled) && dayInfo?.end === '23:59') {
          dayInfo.rightConnection = true;
        } else {
          dayInfo.rightConnection = false;
        }

        day = [dayInfo];
      }

      if ((currentDay.filledPeriods?.length ?? 0) > 1) {
        day =
          currentDay.filledPeriods?.map((item, idx, arr) => {
            const dayInfo: IDayToView = { ...item };

            if (
              idx === 0 &&
              (prevDay?.filledPeriods?.[prevDay?.filledPeriods?.length - 1]?.end === '23:59' || prevDay?.fullFilled) &&
              dayInfo?.start === '00:00'
            ) {
              dayInfo.leftConnection = true;
            } else {
              dayInfo.leftConnection = false;
            }

            if (
              idx === arr.length - 1 &&
              (nextDay?.filledPeriods?.[0]?.start === '00:00' || nextDay?.fullFilled) &&
              dayInfo?.end === '23:59'
            ) {
              dayInfo.rightConnection = true;
            } else {
              dayInfo.rightConnection = false;
            }

            return dayInfo;
          }) ?? [];
      }

      if (!day[0]?.leftConnection && !day[day.length - 1]?.rightConnection) {
        flagDateIndex = null;
      } else if (day[0].leftConnection && flagDateIndex !== null) {
        if (!day[day.length - 1].rightConnection) {
          res[flagDateIndex][res[flagDateIndex].length - 1].endDateIndex = i;
          flagDateIndex = null;
        } else if (day.length - 1 !== 0) {
          res[flagDateIndex][res[flagDateIndex].length - 1].endDateIndex = i;
          flagDateIndex = i;
        }
      }

      res.push(day);
    }

    return res;
  };
}
