import RentalApiRepository from './RentalApiRepository';
import { RentalFactory } from './RentalFactory';
import RefreshAddressAndPhoneDto from './dto/RefreshAddressAndPhoneDto';
import RefreshDefaultSettingsDto from './dto/RefreshDefaultSettingsDto';
import RefreshScheduleDto from './dto/RefreshScheduleDto';
import { IRefreshAddressAndPhoneForm } from './forms/RefreshAddressAndPhoneForm';
import { IRefreshDefaultSettingsForm } from './forms/RefreshDefaultSettingsForm';
import Address from './models/Address';
import { ILocalScheduleData } from './types/RentalTypes';

export default class RentalService {
  api: RentalApiRepository;
  factory: RentalFactory;

  constructor() {
    this.api = new RentalApiRepository();
    this.factory = new RentalFactory();
  }

  getRental = async () => {
    const { data } = await this.api.getRental();

    return this.factory.createRental(data.data);
  };

  refreshAddressAndPhone = async (values: IRefreshAddressAndPhoneForm) => {
    const dto = RefreshAddressAndPhoneDto.populate(values) as RefreshAddressAndPhoneDto;
    const refreshedData = await this.api.refreshAddressAndPhone(dto);

    return this.factory.createRental(refreshedData.data.data);
  };

  refreshDefaultSettings = async (values: IRefreshDefaultSettingsForm) => {
    const dto = RefreshDefaultSettingsDto.populate(values) as RefreshDefaultSettingsDto;
    const refreshedData = await this.api.refreshDefaultSettings(dto);

    return this.factory.createRental(refreshedData.data.data);
  };

  refreshSchedule = async (data: ILocalScheduleData) => {
    const dto = RefreshScheduleDto.populate(data) as RefreshScheduleDto;
    const refreshedData = await this.api.refreshSchedule(dto);

    return this.factory.createRental(refreshedData.data.data);
  };

  refreshPreview = async (uuid: string) => {
    const refreshedData = await this.api.refreshPreview(uuid);

    return this.factory.createRental(refreshedData.data.data);
  };

  refreshLogo = async (uuid: string) => {
    const refreshedData = await this.api.refreshLogo(uuid);

    return this.factory.createRental(refreshedData.data.data);
  };

  searchAddress = async (query: string) => {
    const { data } = await this.api.searchAddress(query);

    return this.factory.createList<Address>(Address, data.data);
  };
}
