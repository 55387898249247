import { DeliveryTariffFields } from 'modules/rental/modules/deliveryTariffs/forms/DeliveryTariffsForm';
import { IDeliveryTariff } from 'modules/rental/modules/deliveryTariffs/types/DeliveryTariffsTypes';
import { declOfNum } from 'utils/declOfNum';

import { ILocalTariff } from '../consts/TransportConsts';
import { TariffFields } from '../forms/AddAutoForm';
import { UpdateAutoFormFields } from '../forms/UpdateAutoForm';
import DetailType from '../models/DetailType';
import Tariff from '../models/Tariff';
import WaterTransportCategory from '../models/WaterTransportCategory';
import { Insurances } from '../types/AddAutoTypes';
import { TransportFormFields, TransportFormKeys, TransportStatuses, TypesOfTransport } from '../types/TransportTypes';

interface IStatusObj {
  status: TransportStatuses | null;
  lineStatus?: TransportStatuses | null;
}

export class TransportRenderHelper {
  static changeTariff = (tariffs: ILocalTariff[], tariffId: number, name: TariffFields, value: number | boolean) => {
    return tariffs.map(tariff => {
      if (tariff.id === tariffId) {
        if (tariff.isSingleDay && name === TariffFields.START) {
          return {
            ...tariff,
            [TariffFields.END]: value,
            [TariffFields.START]: value,
          };
        }

        return {
          ...tariff,
          [name]: value,
        };
      }

      return tariff;
    });
  };

  static changeDeliveryTariff = (
    tariffs: IDeliveryTariff[],
    tariffId: number,
    name: DeliveryTariffFields,
    value: number | string,
  ) => {
    return tariffs.map(tariff => {
      if (tariff.id === tariffId) {
        return {
          ...tariff,
          [name]: value,
        };
      }

      return tariff;
    });
  };

  static changeTariffPeriod = (tariffs: ILocalTariff[], tariffId: number, value: boolean) => {
    return tariffs.map(tariff => {
      if (tariff.id === tariffId) {
        return { ...tariff, [TariffFields.END]: !value ? tariff.start : 0, isSingleDay: !value };
      }

      return tariff;
    });
  };

  static getTariffPlaceholder = (transportType: TypesOfTransport) => {
    switch (transportType) {
      case TypesOfTransport.WATER:
        return {
          placeholder: 'Часов',
          in: 'час',
        };
      default:
        return {
          placeholder: 'Суток',
          in: 'сутки',
        };
    }
  };

  static getTariffLabel = (
    transportType: TypesOfTransport,
    start: number | null,
    end: number | null,
    isSingleDay?: boolean | null,
  ) => {
    let names: string[] = [];

    switch (transportType) {
      case TypesOfTransport.WATER:
        names = ['час', 'часа', 'часов'];
        break;
      default:
        names = ['сутки', 'суток', 'суток'];
        break;
    }

    if (start && (!end || isSingleDay)) {
      return `${start} ${declOfNum(start, names)}`;
    } else if (start && end) {
      return `${start} - ${end} ${declOfNum(end, names)}`;
    }

    return '';
  };

  static setDetailsForm = (
    key: string,
    value: string,
    fuelTypes: DetailType[],
    driveTypes: DetailType[],
    bodyTypes: DetailType[],
    transmissionTypes: DetailType[],
    setForm: (formKey: TransportFormKeys, key: TransportFormFields, value: any) => void,
  ) => {
    switch (key) {
      case UpdateAutoFormFields.FUEL_TYPE:
        setForm(
          TransportFormKeys.UPDATE_AUTO,
          UpdateAutoFormFields.FUEL_TYPE,
          fuelTypes.find(item => value === item.value)?.key,
        );
        return;
      case UpdateAutoFormFields.DRIVE:
        setForm(
          TransportFormKeys.UPDATE_AUTO,
          UpdateAutoFormFields.DRIVE,
          driveTypes.find(item => value === item.value)?.key,
        );
        return;
      case UpdateAutoFormFields.BODY_TYPE:
        setForm(
          TransportFormKeys.UPDATE_AUTO,
          UpdateAutoFormFields.BODY_TYPE,
          bodyTypes.find(item => value === item.value)?.key,
        );
        return;
      case UpdateAutoFormFields.TRANSMISSION:
        setForm(
          TransportFormKeys.UPDATE_AUTO,
          UpdateAutoFormFields.TRANSMISSION,
          transmissionTypes.find(item => value === item.value)?.key,
        );
        return;
    }
  };

  static getTransportStatus = (obj: IStatusObj): TransportStatuses => {
    if (obj.lineStatus === TransportStatuses.IN_USE) {
      return TransportStatuses.OFF_LINE;
    }

    if (obj.status === TransportStatuses.IN_USE) {
      return TransportStatuses.IN_USE;
    }

    return TransportStatuses.FREE;
  };

  static getFirstCategoryWithTransport = (categories: WaterTransportCategory[] | null) => {
    if (!categories?.length) {
      return;
    }

    return categories.find(item => item.transportCount);
  };

  static getInsurancesNames = (insurances?: Insurances[] | null) => {
    if (!insurances || !insurances.length) {
      return 'Нет';
    }

    const osago = insurances.find(item => item === Insurances.OSAGO) ? 'ОСАГО' : '';
    const kasko = insurances.find(item => item === Insurances.KASKO) ? 'КАСКО' : '';

    return insurances.length === 2 ? `${osago}, ${kasko}` : osago || kasko;
  };

  static getTariffsColumns = (tariffs: Tariff[] | null) => {
    const leftTariffsColumn =
      Number(tariffs?.length) < 4 ? tariffs || [] : tariffs?.slice(0, Math.ceil(tariffs.length / 2)) || [];

    const rightTariffsColumn =
      Number(tariffs?.length) < 4 ? [] : tariffs?.slice(Math.ceil(tariffs.length / 2), tariffs.length) || [];

    return { leftTariffsColumn, rightTariffsColumn };
  };
}
