import { DialogProps } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import { UpdateAutoFormFields } from 'modules/transport/forms/UpdateAutoForm';
import { TransportFormKeys } from 'modules/transport/types/TransportTypes';

import DefaultSettingsForm from '../DefaultSettingsForm';

interface IEditFeaturesModalProps extends DialogProps {
  onClose: () => void;
}

const EditFeaturesModal: React.FC<IEditFeaturesModalProps> = observer(({ onClose, ...rest }) => {
  const { transportStore } = useRootStore();

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    transportStore.setForm(TransportFormKeys.UPDATE_AUTO, name as UpdateAutoFormFields, value);
  };

  const handleSave = () => {
    transportStore.updateAuto(onClose);
  };

  // Renders
  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper title="Редактировать характеристики" onClose={onClose} onSave={handleSave}>
        <DefaultSettingsForm
          handleChangeValues={handleChangeValues}
          fuelTypes={transportStore.fuelTypes}
          driveTypes={transportStore.driveTypes}
          bodyTypes={transportStore.bodyTypes}
          transmissionTypes={transportStore.transmissionTypes}
          defaultValues={transportStore.updateAutoForm}
          isTransportCard
        />
      </EditModalWrapper>
    </QModal>
  );
});

export default EditFeaturesModal;
