import { Add20Regular } from '@fluentui/react-icons';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import { ILocalTariff } from 'modules/transport/consts/TransportConsts';
import { TypesOfTransport } from 'modules/transport/types/TransportTypes';

import TariffItem from './TariffItem';

interface ITariffsList {
  handleChangeTariff: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tariffId: number) => void;
  handleAddTariff: () => void;
  handleDeleteTariff: (event: React.MouseEvent<HTMLButtonElement>, tariffId: number) => void;
  handleSwitchTariffPeriod: (value: boolean, tariffId: number) => void;
  tariffs: ILocalTariff[];
  transportType: TypesOfTransport;
}

const TariffsList: React.FC<ITariffsList> = props => {
  const {
    handleChangeTariff,
    handleAddTariff,
    handleDeleteTariff,
    handleSwitchTariffPeriod,
    tariffs,
    transportType,
  } = props;

  // Renders
  const renderTariffs = () => {
    return tariffs.map(item => {
      return (
        <TariffItem
          key={item.id}
          tariffId={item.id}
          handleDeleteTariff={handleDeleteTariff}
          handleChangeTariff={handleChangeTariff}
          handleSwitchTariffPeriod={handleSwitchTariffPeriod}
          transportType={transportType}
        />
      );
    });
  };

  return (
    <div>
      <Box mb={3}>
        <Typography variant="h3">Тарифы</Typography>
      </Box>
      <Grid item xs={8}>
        {renderTariffs()}
      </Grid>
      <Button onClick={handleAddTariff} variant="contained">
        <Box mr={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Add20Regular />
          </Grid>
        </Box>
        Добавить тариф
      </Button>
    </div>
  );
};

export default TariffsList;
