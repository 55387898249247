import { CalendarLtr20Regular, ChevronRight20Filled, Edit16Regular } from '@fluentui/react-icons';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import DocumentItem from 'components/UI/DocumentItem';
import Loader from 'components/UI/Loader';
import UploadDocument from 'components/UI/UploadDocument';
import FileModel from 'modules/files/models/FileModel';
import { UpdateStatusFormFields } from 'modules/transport/forms/UpdateStatusForm';
import { UpdateWaterFormFields } from 'modules/transport/forms/UpdateWaterForm';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import {
  TransportCardModals,
  TransportFormKeys,
  TransportStatuses,
  TypesOfTransport,
} from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import Feature from '../cars/components/Feature';
import MainFeaturesItem from '../components/MainFeaturesItem';
import PhotoList from '../components/PhotoList';
import TransportBlockWrapper from '../components/TransportBlockWrapper';
import EditCommentModal from '../components/modals/EditCommentModal';
import EditDepositModal from '../components/modals/EditDepositModal';
import EditTariffsModal from '../components/modals/EditTariffsModal';
import DeleteModal from './components/modals/DeleteModal';
import EditCategoryModal from './components/modals/EditCategoryModal';
import EditWaterFeaturesModal from './components/modals/EditWaterFeaturesModal';

const WaterTransportCardScreen = observer(() => {
  const { transportStore, filesStore, authStore, rentalStore } = useRootStore();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const commonClasses = useCommonStyles();

  const [openedModals, setOpenedModals] = useState<Record<TransportCardModals, boolean>>(
    Object.assign(Object.values(TransportCardModals).map(item => ({ [item]: false }))),
  );

  // Effects
  useEffect(() => {
    transportStore.getTransportInfo(Number(id));
    if (!transportStore.waterTransportCategories.length) {
      transportStore.getWaterTransportCategories();
    }

    return () => {
      filesStore.clearStore();
    };
  }, []);

  useEffect(() => {
    if (filesStore.pdf) {
      transportStore.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.CONTRACT, filesStore.pdf?.uuid);

      transportStore.updateWater();
    }
  }, [filesStore.pdf]);

  useEffect(() => {
    transportStore.initWaterTransportCardScreen();
  }, [transportStore.currentTransportInfo?.waterTransport]);

  // Handlers
  const handleOpenModal = (modal: TransportCardModals) => {
    setOpenedModals({ ...openedModals, [modal]: true });
  };

  const handleCloseModal = (modal: TransportCardModals) => {
    transportStore.initWaterTransportCardScreen();

    setOpenedModals({ ...openedModals, [modal]: false });
  };

  const handleToggleStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    transportStore.setForm(
      TransportFormKeys.UPDATE_STATUS,
      UpdateStatusFormFields.STATUS,
      checked ? TransportStatuses.IN_USE : TransportStatuses.FREE,
    );
    transportStore.updateStatus();
  };

  const handleAddContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    filesStore.uploadPdf(e.target.files[0]);
  };

  const handleAddPhotos = (images: FileModel[]) => {
    const uuids = images.map(item => item.uuid);

    filesStore.clearStore();

    transportStore.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.PHOTOS, uuids);
    transportStore.updateWater();
  };

  const handleAddWrapper = (image: FileModel) => {
    filesStore.clearStore();

    transportStore.setForm(TransportFormKeys.UPDATE_WATER, UpdateWaterFormFields.WRAPPER, image.uuid);
    transportStore.updateWater();
  };

  const handleDeletePhoto = (id: number) => {
    transportStore.deleteTransportPhoto(id);
  };

  // Renders
  const renderTariffs = () => {
    return transportStore.currentTransportInfo?.tariffs?.map(item => {
      return (
        <MainFeaturesItem
          key={item.id}
          label={TransportRenderHelper.getTariffLabel(TypesOfTransport.WATER, item.start, item.end, item.isSingleDay)}
          value={`${item.price?.toLocaleString()} ₽${!item.isSingleDay ? ' / час' : ''}`}
        />
      );
    });
  };

  if (!transportStore.currentTransportInfo && transportStore.loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="lg">
      <Box mb={6}>
        <Breadcrumbs separator={<ChevronRight20Filled />} aria-label="breadcrumb">
          <Link
            className={clsx(commonClasses.onSurfaceSecondary, commonClasses.textDecorationNone)}
            to={routes.WaterTransportScreen.path}
          >
            <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
              Список водных транспортных средств
            </Typography>
          </Link>
          <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
            {transportStore.currentTransportInfo?.waterTransport?.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box mb={0.5}>
        <Grid alignItems="center" container>
          <Box mr={2}>
            <Typography variant="h1">{transportStore.currentTransportInfo?.waterTransport?.name}</Typography>
          </Box>
          {authStore.userInfo?.canEdit && (
            <>
              <Button onClick={() => handleOpenModal(TransportCardModals.EDIT_CATEGORY)} variant="contained">
                Изменить
              </Button>

              <Button
                onClick={() => handleOpenModal(TransportCardModals.DELETE_TRANSPORT)}
                className={classes.deleteBtn}
                variant="contained"
              >
                Удалить транспорт
              </Button>
            </>
          )}
        </Grid>
      </Box>
      <Box mb={5}>
        <Typography className={commonClasses.onSurfaceSecondary} variant="h4">
          {transportStore.currentTransportInfo?.waterTransport?.category?.name}
        </Typography>
      </Box>
      <Box mb={5}>
        <Link
          className={commonClasses.textDecorationNone}
          to={`/transport/water/${transportStore.currentTransportInfo?.id}/booking`}
        >
          <Button variant="contained" color="primary">
            Забронировать
            <Box ml={1.5}>
              <Grid container alignItems="center" justifyContent="center">
                <CalendarLtr20Regular />
              </Grid>
            </Box>
          </Button>
        </Link>
      </Box>
      <Box mb={4}>
        <PhotoList
          handleApiDelete={handleDeletePhoto}
          handleAddPhotos={handleAddPhotos}
          handleAddWrapper={handleAddWrapper}
          isTransportCard
          files={transportStore.currentTransportInfo?.photos}
          defaultWrapper={transportStore.currentTransportInfo?.wrapper}
          canEdit={!!authStore.userInfo?.canEdit}
        />
      </Box>
      <Box mb={3}>
        <TransportBlockWrapper>
          <Box mb={3}>
            <Grid alignItems="center" container>
              <Box mr={3}>
                <Typography className={commonClasses.onSurfaceSecondary} variant="h5">
                  Основные характеристики
                </Typography>
              </Box>
              {authStore.userInfo?.canEdit && (
                <Button onClick={() => handleOpenModal(TransportCardModals.EDIT_FEATURES)} variant="contained">
                  Изменить
                </Button>
              )}
            </Grid>
          </Box>
          <Grid container>
            <Grid xs={5} item>
              <MainFeaturesItem
                label="Двигатель"
                value={`${transportStore.currentTransportInfo?.waterTransport?.enginePower} л.с.`}
              />
              <MainFeaturesItem label="Цвет" value={transportStore.currentTransportInfo?.waterTransport?.color || ''} />

              <MainFeaturesItem
                label="Кол-во кают"
                value={`${transportStore.currentTransportInfo?.waterTransport?.cabinsCount}`}
              />
              <MainFeaturesItem
                label="Требуются права"
                value={transportStore.currentTransportInfo?.waterTransport?.needLicense ? 'Да' : 'Нет'}
              />
            </Grid>
            <Grid xs={5} item>
              <MainFeaturesItem
                label="Макс. скорость"
                value={`${transportStore.currentTransportInfo?.waterTransport?.maxSpeed} км/ч`}
              />
              <MainFeaturesItem
                label="Год выпуска"
                value={`${transportStore.currentTransportInfo?.waterTransport?.issueYear}`}
              />
              <MainFeaturesItem
                label="Пассажировместимость"
                value={`${transportStore.currentTransportInfo?.waterTransport?.capacity}`}
                textClassName={commonClasses.textOverflowEllipsis}
              />
            </Grid>
          </Grid>
        </TransportBlockWrapper>
      </Box>
      <Box mb={3}>
        <TransportBlockWrapper>
          <Box mb={3}>
            <Grid alignItems="center" container>
              <Box mr={3}>
                <Typography className={commonClasses.onSurfaceSecondary} variant="h5">
                  Тарифы
                </Typography>
              </Box>
              {authStore.userInfo?.canEdit && (
                <Button onClick={() => handleOpenModal(TransportCardModals.EDIT_TARIFFS)} variant="contained">
                  Изменить
                </Button>
              )}
            </Grid>
          </Box>
          <Grid container>
            <Grid xs={5} item>
              {renderTariffs()}
            </Grid>
          </Grid>
        </TransportBlockWrapper>
      </Box>
      {rentalStore.currentRental?.deposit ? (
        <Box mb={3}>
          <TransportBlockWrapper>
            <Feature
              onClick={() => handleOpenModal(TransportCardModals.EDIT_DEPOSIT)}
              title="Залог"
              value={`${transportStore.currentTransportInfo?.waterTransport?.deposit || 0} ₽`}
              canEdit={!!authStore.userInfo?.canEdit}
            />
          </TransportBlockWrapper>
        </Box>
      ) : null}
      <Box mb={3}>
        <TransportBlockWrapper>
          <Box mb={3}>
            <Box mb={2}>
              <Typography variant="h3">Загруженный документ:</Typography>
              <Typography variant="body1">Будет отображаться в карточке водного транспорта</Typography>
            </Box>
            <DocumentItem title="Правила аренды" document={transportStore.currentTransportInfo?.contract || null} />
            {authStore.userInfo?.canEdit && (
              <Box mt={2}>
                <UploadDocument handleUploadDocument={handleAddContract} document={filesStore.pdf} />
              </Box>
            )}
          </Box>

          <Box maxWidth={592}>
            <Box display="flex">
              <Box mr={3}>
                <Typography variant="h3">Комментарий</Typography>
                <div className={classes.commentWrapper}>
                  <Typography variant="body1">{transportStore.currentTransportInfo?.comment || 'Нет'}</Typography>
                </div>
              </Box>
              {authStore.userInfo?.canEdit && (
                <Box>
                  <Button
                    onClick={() => handleOpenModal(TransportCardModals.EDIT_COMMENT)}
                    className={classes.editBtn}
                    variant="contained"
                  >
                    <Edit16Regular />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </TransportBlockWrapper>
      </Box>
      <TransportBlockWrapper>
        <FormControlLabel
          color="primary"
          control={
            <Switch
              checked={
                TransportRenderHelper.getTransportStatus(transportStore.updateStatusForm) === TransportStatuses.IN_USE
              }
              onChange={handleToggleStatus}
              color="primary"
            />
          }
          label="Снять водный транспорт с линии"
        />
        <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
          Водный транспорт не будет доступен для бронирования и не будет отображаться в каталоге, пока вы не вернете его
          на линию.
        </Typography>
      </TransportBlockWrapper>

      <EditCategoryModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_CATEGORY)}
        open={!!openedModals[TransportCardModals.EDIT_CATEGORY]}
      />
      <EditWaterFeaturesModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_FEATURES)}
        open={!!openedModals[TransportCardModals.EDIT_FEATURES]}
      />
      <DeleteModal
        onClose={() => handleCloseModal(TransportCardModals.DELETE_TRANSPORT)}
        open={!!openedModals[TransportCardModals.DELETE_TRANSPORT]}
      />
      <EditTariffsModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_TARIFFS)}
        open={!!openedModals[TransportCardModals.EDIT_TARIFFS]}
        transportType={TypesOfTransport.WATER}
      />
      <EditDepositModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_DEPOSIT)}
        open={!!openedModals[TransportCardModals.EDIT_DEPOSIT]}
        transportType={TypesOfTransport.WATER}
      />

      <EditCommentModal
        onClose={() => handleCloseModal(TransportCardModals.EDIT_COMMENT)}
        open={!!openedModals[TransportCardModals.EDIT_COMMENT]}
        transportType={TypesOfTransport.WATER}
      />
    </Container>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  deleteBtn: {
    marginLeft: 'auto',
    color: theme.palette.custom.primary.red,
  },
  editBtn: {
    padding: 6,
    width: 32,
    height: 32,
    minWidth: 0,
  },
  commentWrapper: {
    wordBreak: 'break-word',
  },
}));

export default WaterTransportCardScreen;
