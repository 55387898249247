import AbstractApiRepository from 'base/api/AbstractApiRepository';

import UpdateDeliveryTariffDto from './dto/UpdateDeliveryTariffDto';

export default class DeliveryTariffsApiRepository extends AbstractApiRepository {
  getDeliveryTariffs = (rentalId: number) => {
    return this.apiClient.get({ url: `/lessor/rentals/transport/deliveryTariffs/${rentalId}` });
  };

  updateDeliveryTariffs = (data: UpdateDeliveryTariffDto) => {
    return this.apiClient.post({ url: `/lessor/rentals/transport/deliveryTariffs`, data });
  };
}
