import AbstractModel from 'base/AbstractModel';

import { TransportStatuses } from '../types/TransportTypes';
import WaterTransportCategory from './WaterTransportCategory';

export default class WaterInfoDetailed extends AbstractModel {
  cabinsCount: number | null = null;
  capacity: number | null = null;
  category: WaterTransportCategory | null = null;
  color: string | null = null;
  enginePower: number | null = null;
  id: number | null = null;
  issueYear: number | null = null;
  maxSpeed: number | null = null;
  name: string | null = null;
  needLicense: number | null = null;
  status: TransportStatuses | null = null;
  deposit: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
