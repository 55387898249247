import { RoutesType } from 'base/routes/types/RouteTypes';
import ChangePasswordScreen from 'screens/auth/ChangePasswordScreen';
import LoginScreen from 'screens/auth/LoginScreen';
import NewPasswordScreen from 'screens/auth/NewPasswordScreen';
import MainScreen from 'screens/main/MainScreen';
import NotFoundScreen from 'screens/notFound/NotFoundScreen';
import OrdersScreen from 'screens/orders/OrdersScreen';
import RentCalendarScreen from 'screens/rentCalendar/RentCalendarScreen';
import RentalScreen from 'screens/rental/RentalScreen';
import AddTransportScreen from 'screens/transport/cars/AddTransportScreen';
import AutoBookingScreen from 'screens/transport/cars/AutoBookingScreen';
import AutoTransportCardScreen from 'screens/transport/cars/AutoTransportCardScreen';
import AutoTransportScreen from 'screens/transport/cars/AutoTransportScreen';
import AddWaterTransportScreen from 'screens/transport/waterTransport/AddWaterTransportScreen';
import WaterBookingScreen from 'screens/transport/waterTransport/WaterBookingScreen';
import WaterTransportCardScreen from 'screens/transport/waterTransport/WaterTransportCardScreen';
import WaterTransportScreen from 'screens/transport/waterTransport/WaterTransportScreen';

const screens = {
  MainScreen,
  NotFoundScreen,
  WaterTransportScreen,
  AutoTransportScreen,
  RentalScreen,
  AutoTransportCardScreen,
  AddTransportScreen,
  AutoBookingScreen,
  AddWaterTransportScreen,
  WaterTransportCardScreen,
  WaterBookingScreen,
  OrdersScreen,
  RentCalendarScreen,
};
const authScreens = { LoginScreen, NotFoundScreen, ChangePasswordScreen, NewPasswordScreen };

type RoutesKeys = keyof typeof screens;
export const routes: RoutesType<RoutesKeys> = {
  MainScreen: {
    path: '/',
    exact: true,
    title: 'Главная',
    component: screens.MainScreen,
    // credentials: [roles.director],
  },
  AutoTransportScreen: {
    path: '/transport/auto',
    exact: true,
    title: 'Автомобили',
    component: screens.AutoTransportScreen,
    // credentials: [roles.director],
  },
  AutoTransportCardScreen: {
    path: '/transport/auto/:id',
    exact: true,
    title: 'Автомобили',
    component: screens.AutoTransportCardScreen,
    // credentials: [roles.director],
  },
  AutoBookingScreen: {
    path: '/transport/auto/:id/booking',
    exact: true,
    title: 'Бронирование',
    component: screens.AutoBookingScreen,
    // credentials: [roles.director],
  },
  AddTransportScreen: {
    path: '/transport/add-auto',
    exact: true,
    title: 'Автомобили',
    component: screens.AddTransportScreen,
    // credentials: [roles.director],
  },
  AddWaterTransportScreen: {
    path: '/transport/add-water',
    exact: true,
    title: 'Водный транспорт',
    component: screens.AddWaterTransportScreen,
    // credentials: [roles.director],
  },
  WaterTransportScreen: {
    path: '/transport/water',
    exact: true,
    title: 'Водный транспорт',
    component: screens.WaterTransportScreen,
    // credentials: [roles.director],
  },
  WaterTransportCardScreen: {
    path: '/transport/water/:id',
    exact: true,
    title: 'Водный транспорт',
    component: screens.WaterTransportCardScreen,
    // credentials: [roles.director],
  },
  WaterBookingScreen: {
    path: '/transport/water/:id/booking',
    exact: true,
    title: 'Бронирование',
    component: screens.WaterBookingScreen,
    // credentials: [roles.director],
  },
  OrdersScreen: {
    path: '/orders',
    exact: true,
    title: 'Заказы',
    component: screens.OrdersScreen,
    // credentials: [roles.director],
  },
  // PromoCodesScreen: {
  //   path: '/promo-codes',
  //   exact: true,
  //   title: 'Промокоды',
  //   component: screens.PromoCodesScreen,
  //   // credentials: [roles.director],
  // },
  // AnalyticsScreen: {
  //   path: '/analytics',
  //   exact: true,
  //   title: 'Аналитика',
  //   component: screens.AnalyticsScreen,
  //   // credentials: [roles.director],
  // },
  RentalScreen: {
    path: '/rental',
    exact: true,
    title: 'Прокат',
    component: screens.RentalScreen,
    // credentials: [roles.director],
  },
  RentCalendarScreen: {
    path: '/calendar',
    exact: true,
    title: 'Календарь',
    component: screens.RentCalendarScreen,
    // credentials: [roles.director],
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    component: screens.NotFoundScreen,
  },
};

// Auth Routes
type AuthRouteKeys = keyof typeof authScreens;
export const authRoutes: RoutesType<AuthRouteKeys> = {
  LoginScreen: {
    path: '/login',
    exact: true,
    title: 'Вход',
    component: authScreens.LoginScreen,
  },
  ChangePasswordScreen: {
    path: '/login/reset-password',
    exact: true,
    title: 'Восстановить пароль',
    component: authScreens.ChangePasswordScreen,
  },
  NewPasswordScreen: {
    path: '/login/new-password',
    exact: true,
    title: 'Новый пароль',
    component: authScreens.NewPasswordScreen,
  },
  NotFoundScreen: {
    path: '/not-found',
    title: '404 Страница не найдена',
    component: screens.NotFoundScreen,
  },
};
