import PaginationModel from 'base/modules/pagination/PaginationModel';
import { User } from 'modules/auth/models/User';
import { RentalFactory } from 'modules/rental/RentalFactory';
import { RentalRenderHelper } from 'modules/rental/helpers/RentalRenderHelper';
import { TypesOfRental } from 'modules/rental/types/RentalTypes';
import { TransportFactory } from 'modules/transport/TransportFactory';

import OrdersApiRepository from './OrdersApiRepository';
import { OrdersFactory } from './OrdersFactory';
import ChangeOrderStatusDto from './dto/ChangeOrderStatusDto';
import { IChangeOrderStatusForm } from './forms/ChangeOrderStatusForm';
import OrderModel from './models/OrderModel';
import { DeclineReasons } from './types/OrdersTypes';

export class OrdersService {
  static LIMIT = 10;

  api: OrdersApiRepository;

  factory: OrdersFactory;
  transportFactory: TransportFactory;
  rentalFactory: RentalFactory;

  constructor() {
    this.api = new OrdersApiRepository();

    this.factory = new OrdersFactory();
    this.transportFactory = new TransportFactory();
    this.rentalFactory = new RentalFactory();
  }

  getOrders = async (limit: number, offset: number, type?: string) => {
    const typeString = type && type.length > 0 ? `&type=${type}` : '';
    const { data } = await this.api.getOrders(limit, offset, typeString);

    const orders = this.createOrdersList(data.data.items);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    return { orders, pagination };
  };

  getStatuses = async (): Promise<Record<string, string>> => {
    const { data } = await this.api.getStatuses();

    return data.data;
  };

  getStatusesWithCount = async (): Promise<Record<string, number>> => {
    const { data } = await this.api.getStatusesWithCount();

    return data.data;
  };

  getDeclineReasons = async (rentalType?: TypesOfRental | null): Promise<Record<DeclineReasons, string>> => {
    const { data } = await this.api.getDeclineReasons();
    let preData = data.data;

    if (RentalRenderHelper.getIsAutoRental(rentalType)) {
      preData = data.data;
    } else {
      preData = Object.fromEntries(
        Object.entries(data.data).filter(
          ([key]) => !key.includes(DeclineReasons.AGE_REASON) && !key.includes(DeclineReasons.EXPERIENCE_REASON),
        ),
      );
    }

    return preData;
  };

  changeStatus = async (values: IChangeOrderStatusForm) => {
    const dto = ChangeOrderStatusDto.populate(values) as ChangeOrderStatusDto;
    const { data } = await this.api.changeStatus(dto);

    return this.createOrder(data.data);
  };

  getOrderStatus = (orders: OrderModel[], findingOrder: OrderModel) => {
    return orders.find(order => order.id === findingOrder.id)?.status || '';
  };

  removeOrder = (orders: OrderModel[], setOrders: (orders: OrderModel[]) => void, removingOrder: OrderModel) => {
    setOrders(orders.filter(order => order.id !== removingOrder.id));
  };

  private createOrder = (item: any) => {
    const model = item;
    model.deliveryAddress = this.rentalFactory.createAddress(item.deliveryAddress);
    model.transport = this.transportFactory.createTransportInfo(item.transport);
    model.user = this.factory.create<User>(User, item.user);

    return this.factory.createOrder(model);
  };

  private createOrdersList = (items: any[]) => {
    if (!items || !items.length) {
      return [];
    }

    return items.map(this.createOrder);
  };
}
