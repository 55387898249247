import AuthApiRepository from './AuthApiRepository';
import { AuthFactory } from './AuthFactory';
import LoginDto from './dto/LoginDto';
import RegisterDto from './dto/RegisterDto';
import SetNewPasswordDto from './dto/SetNewPasswordDto';
import { IForgotPasswordForm } from './forms/ForgotPasswordForm';
import { ILoginForm } from './forms/LoginForm';
import { IRegisterForm } from './forms/RegisterForm';
import { SuccessAuth } from './models/SuccessAuth';
import { User } from './models/User';
import { VerifyEmail } from './models/VerifyEmail';

export default class AuthService {
  api: AuthApiRepository;
  factory: AuthFactory;

  constructor() {
    this.api = new AuthApiRepository();
    this.factory = new AuthFactory();
  }

  register = async (values: IRegisterForm): Promise<SuccessAuth> => {
    const dto = RegisterDto.populate(values) as RegisterDto;
    const { data } = await this.api.register(dto);

    return this.factory.create<SuccessAuth>(SuccessAuth, data.data);
  };

  login = async (values: ILoginForm) => {
    const dto = LoginDto.populate(values) as LoginDto;
    const { data } = await this.api.login(dto);

    return this.factory.create<SuccessAuth>(SuccessAuth, data.data);
  };

  logout = () => {
    return this.api.logout();
  };

  forgotPassword = async (values: IForgotPasswordForm) => {
    const { data } = await this.api.forgotPassword(values.email);

    return this.factory.create<VerifyEmail>(VerifyEmail, data.data);
  };

  setNewPassword = async (values: IForgotPasswordForm) => {
    const dto = SetNewPasswordDto.populate(values) as SetNewPasswordDto;
    const { data } = await this.api.setNewPassword(dto);

    return this.factory.create<SuccessAuth>(SuccessAuth, data.data);
  };

  getUserInfo = async () => {
    const { data } = await this.api.getUserInfo();

    return this.factory.create<User>(User, data.data);
  };
}
