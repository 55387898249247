import AbstractFactory from 'base/AbstractFactory';
import FileModel from 'modules/files/models/FileModel';

import Address from './models/Address';
import Rental from './models/Rental';
import Schedule from './models/Schedule';

export class RentalFactory extends AbstractFactory {
  createRental = (data: any) => {
    const model = data;
    model.preview = this.createFile(data.preview);
    model.logo = this.createFile(data.logo);
    model.address = this.createAddress(data.address);
    model.schedule = this.createSchedule(data.schedule);

    return this.create<Rental>(Rental, data);
  };

  createFile = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<FileModel>(FileModel, data);
  };

  createAddress = (data: any) => {
    if (!data) {
      return null;
    }

    return this.create<Address>(Address, data);
  };

  createSchedule = (data: any[]) => {
    if (!data) {
      return;
    }

    return this.createList<Schedule>(Schedule, data);
  };
}
