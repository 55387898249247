import { ChevronDown24Filled } from '@fluentui/react-icons';
import { Box, debounce, Grid, InputLabel, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import PaginationModel from 'base/modules/pagination/PaginationModel';
import { RenderHelper } from 'helpers/RenderHelper';
import { useRefWithCallback } from 'hooks/useRefWithCallback';
import { initialPagination } from 'modules/transport/consts/TransportConsts';
import { AddAutoFormFields } from 'modules/transport/forms/AddAutoForm';
import Brand from 'modules/transport/models/Brand';
import Model from 'modules/transport/models/Model';
import { TransportFormKeys, TransportPaginationTypes } from 'modules/transport/types/TransportTypes';

import NumberInput from './NumberInput';

interface IMarkForm {
  handleChangeValuesNoEvent: (name: string, value: string | number) => void;
}

const MarkForm: React.FC<IMarkForm> = observer(({ handleChangeValuesNoEvent }) => {
  const { transportStore } = useRootStore();
  const classes = useStyles();

  const [searchBrandsValue, setSearchBrandsValue] = useState('');
  const [searchModelsValue, setSearchModelsValue] = useState('');
  const setBrandsListRef = useRefWithCallback(
    node => node.addEventListener('scroll', handleBrandsUpdate),
    node => node.removeEventListener('scroll', handleBrandsUpdate),
  );
  const setModelsListRef = useRefWithCallback(
    node => node.addEventListener('scroll', handleModelsUpdate),
    node => node.removeEventListener('scroll', handleModelsUpdate),
  );

  const defaultSearchInputProps = {
    disableClearable: true,
    autoHighlight: false,
    autoComplete: false,
    fullWidth: true,
    noOptionsText: 'Не найдено',
    popupIcon: <ChevronDown24Filled />,
    clearOnBlur: false,
    getOptionLabel: (option: any) => option.name,
    getOptionSelected: (option: any) => option.id,
  };

  const searchBrandsApiCall = useCallback(
    debounce((value: string) => {
      transportStore.getBrands(false, value);
    }, 400),
    [],
  );

  const searchModelsApiCall = useCallback(
    debounce((value: string) => {
      transportStore.getModels(false, value);
    }, 400),
    [],
  );

  // Effects
  useEffect(() => {
    const brandId = transportStore.currentTransportInfo?.autoTransport?.brand?.id;

    transportStore.getBrands();
    brandId && brandId !== 0 && transportStore.setCurrentBrandId(brandId);
  }, []);

  useEffect(() => {
    transportStore.getModels();
  }, [transportStore.currentBrandId]);

  // Handlers
  const handleBrandsUpdate = (e: Event) => {
    const isEndOfSearchBlock = RenderHelper.getEndOfBlock(e.target as HTMLUListElement, 20);

    if (
      !transportStore.getIsEndOfList(TransportPaginationTypes.BRANDS) &&
      isEndOfSearchBlock &&
      !transportStore.hotLoading &&
      transportStore.brands.length
    ) {
      transportStore.getBrands(true);
    }
  };

  const handleModelsUpdate = (e: Event) => {
    const isEndOfSearchBlock = RenderHelper.getEndOfBlock(e.target as HTMLUListElement, 20);

    if (
      !transportStore.getIsEndOfList(TransportPaginationTypes.MODELS) &&
      isEndOfSearchBlock &&
      !transportStore.hotLoading &&
      transportStore.models.length
    ) {
      transportStore.getModels(true);
    }
  };

  const handleChangeBrand = (event: React.ChangeEvent<{}>, newValue: Brand | null) => {
    if (!newValue?.id && newValue?.id !== 0) {
      return;
    }

    handleSearchBrands(newValue.name || '');
    handleSearchModels('');

    transportStore.setCurrentBrandId(Number(newValue?.id));
    handleChangeValuesNoEvent(AddAutoFormFields.BRAND_ID, newValue?.id);
  };

  const handleChangeModel = (event: React.ChangeEvent<{}>, newValue: Model | null) => {
    if (!newValue?.id && newValue?.id !== 0) {
      return;
    }

    handleSearchModels(newValue.name || '');

    handleChangeValuesNoEvent(AddAutoFormFields.MODEL_ID, newValue?.id);
  };

  const handleSaveNewModel = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && !transportStore.addModelLoading && searchModelsValue) {
      transportStore.setModel(TransportFormKeys.ADD_AUTO);
    }
  };

  const handleSearchBrands = (value: string) => {
    setSearchBrandsValue(value);
    searchBrandsApiCall(value);
  };

  const handleSearchModels = (value: string) => {
    setSearchModelsValue(value);
    searchModelsApiCall(value);
  };

  const handleCloseModelsList = () => {
    transportStore.setPagination({ meta: initialPagination } as PaginationModel, TransportPaginationTypes.MODELS);
  };

  // Renders
  return (
    <>
      <Box mb={3}>
        <Typography variant="h3">Марка, модель</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel>Марка</InputLabel>
          <Autocomplete
            {...defaultSearchInputProps}
            options={transportStore.brands}
            inputValue={searchBrandsValue}
            onChange={handleChangeBrand}
            ListboxProps={{ ref: setBrandsListRef, className: classes.menuList }}
            renderInput={params => (
              <TextField
                name={AddAutoFormFields.BRAND_ID}
                onChange={e => handleSearchBrands(e.target.value)}
                variant="outlined"
                required
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Модель</InputLabel>
          <Autocomplete
            {...defaultSearchInputProps}
            options={transportStore.models}
            inputValue={searchModelsValue}
            onChange={handleChangeModel}
            onClose={handleCloseModelsList}
            ListboxProps={{ ref: setModelsListRef, className: classes.menuList }}
            renderInput={params => (
              <TextField
                name={AddAutoFormFields.MODEL_ID}
                onChange={e => handleSearchModels(e.target.value)}
                variant="outlined"
                required
                onKeyDown={handleSaveNewModel}
                {...params}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput handleChangeValuesNoEvent={handleChangeValuesNoEvent} />
        </Grid>
      </Grid>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  menuList: {
    maxHeight: 340,
    overflow: 'auto',
    padding: 0,
    margin: 0,
    borderRadius: 8,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
    '& li': {
      padding: theme.spacing(1.5, 2),
    },
  },
}));

export default MarkForm;
