import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

import QInput from 'components/UI/textFields/QInput';
import { ILocalSchedule } from 'modules/rental/types/RentalTypes';

interface IWorkHoursInputProps {
  label: string;
  handleChangeTime: (e: React.ChangeEvent<HTMLInputElement>, dayId: number) => void;
  dayId: number;
  defaultValues: ILocalSchedule;
}

const WorkHoursInput: React.FC<IWorkHoursInputProps> = ({ label, handleChangeTime, dayId, defaultValues }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid className={classes.textContainer} item>
            <Typography variant="body1">с</Typography>
          </Grid>
          <Grid item>
            <div className={classes.input}>
              <QInput
                name="timeStart"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTime(e, dayId)}
                placeholder="00:00"
                type="time"
                value={defaultValues.timeStart}
                required
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          <Grid className={classes.textContainer} item>
            <Typography variant="body1">до</Typography>
          </Grid>
          <Grid item>
            <div className={classes.input}>
              <QInput
                name="timeEnd"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTime(e, dayId)}
                placeholder="00:00"
                type="time"
                value={defaultValues.timeEnd}
                required
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: 93,
  },
  textContainer: {
    margin: 'auto',
  },
}));

export default WorkHoursInput;
