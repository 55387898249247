import { Box, Typography } from '@material-ui/core';
import React from 'react';

interface IQHelperTextProps {
  errors?: string | string[] | null;
}

const QHelperText: React.FC<IQHelperTextProps> = ({ errors }) => {
  if (Array.isArray(errors)) {
    return (
      <Box mt={1} display="block" component="span">
        {errors?.map((item, index) => (
          <Box key={index} mb={0.2}>
            <Typography variant="caption" color="error" component="span">
              {item}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box mt={1} display="block" component="span">
      <Typography variant="caption" color="error" component="span">
        {errors}
      </Typography>
    </Box>
  );
};

export default QHelperText;
