import AbstractDto from 'base/AbstractDto';

export default class UpdateAutoDto extends AbstractDto {
  id: number = 0;
  enginePower: number = 0;
  engineVolume: number = 0;
  drive: string = '';
  transmission: string = '';
  racing: number = 0;
  maxSpeed: number = 0;
  fuelType: string = '';
  fuelConsumption: number = 0;
  bodyType: string = '';
  color: string = '';
  issueYear: number = 0;
  insurances: string[] = [];
  music: string = '';
  photos: string[] = [];
  wrapper: string = '';
  contract: string = '';
  speedLimit: number = 0;
  status: string = '';
  mileageLimit: number = 0;
  deliveryPrice: number = 0;
  fuelCostPerLiter: number = 0;
  comment: string = '';
  rerunPrice: number = 0;
  washFee: number = 0;
  travelAboard: boolean = false;
  travelOutOfCity: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
