import { Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import React from 'react';

const QInput: React.FC<TextFieldProps> = props => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      {props.label ? <InputLabel className={classes.label}>{props.label}</InputLabel> : null}
      <TextField {...props} variant="outlined" label="" fullWidth />
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
}));

export default QInput;
