import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useRootStore } from 'base/hooks/useRootStore';
import RouteHelper from 'base/routes/helpers/RouteHelper';

const MainScreen: React.FC = observer(() => {
  const { rentalStore } = useRootStore();
  const history = useHistory();

  // Effects
  useEffect(() => {
    if (rentalStore.currentRental?.type) {
      history.push(RouteHelper.getTransportLink(rentalStore.currentRental?.type));
    }
  }, [rentalStore.currentRental?.type]);

  // Renders
  return <></>;
});

export default MainScreen;
