import AbstractModel from 'base/AbstractModel';

export default class PaginationModel extends AbstractModel {
  meta: IMeta | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}

export interface IMeta {
  totalCount: number;
  currentOffset: number;
  limit?: number;
}
