import { CommonColors, PaletteOptions, SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';

// Указываем типы явно, что будем использовать объектом,
// чтобы можно было отдельно использовать цвет.
// Например: в теме, в стилях кастомных компонентов MUI
interface IPaletteOptions extends Omit<PaletteOptions, 'primary' | 'secondary' | 'error'> {
  primary: SimplePaletteColorOptions;
  secondary: SimplePaletteColorOptions;
  error: SimplePaletteColorOptions;
  common: CommonColors;
}

// Использовать только в теме MUI
export const palette: IPaletteOptions = {
  primary: {
    main: 'rgba(25, 25, 25, 1)',
  },
  secondary: {
    main: 'rgba(98, 98, 98, 0.08)',
  },
  error: {
    main: '#FF3B30',
  },
  background: {
    default: '#FAFAFA',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  custom: {
    // surface: '#FFFFFF',
    outline: 'rgba(34, 34, 34, 0.24)',
    outline2: 'rgba(34, 34, 34, 0.12)',
    overlay: 'rgba(33, 33, 33, 0.15)',
    primary: {
      default: '#F8F8F8',
      red: '#BC0C02',
      overlay: {
        main: 'rgba(98, 98, 98, 0.08)',
      },
    },
    onPrimary: {
      primary: '#FFFFFF',
      tertiary: 'rgba(250, 250, 250, 0.34)',
    },
    onSurface: {
      primary: 'rgba(25, 25, 25, 1)',
      secondary: 'rgba(34, 34, 34, 0.6)',
      tertiary: 'rgba(34, 34, 34, 0.34)',
      light: 'rgba(25, 25, 25, 0.16)',
    },
    status: {
      red: '#B00015',
      green: '#00875E',
    },
  },
};
