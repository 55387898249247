import { useCallback, useRef } from 'react';

export const useRefWithCallback = (onMount: (node: HTMLElement) => void, onUnmount: (node: HTMLElement) => void) => {
  const nodeRef = useRef<HTMLElement>(null);

  const setRef = useCallback(
    (node: HTMLElement | HTMLUListElement | null) => {
      if (nodeRef.current) {
        onUnmount(nodeRef.current);
      }

      if (node) {
        onMount(node);
      }
    },
    [onMount, onUnmount],
  );

  return setRef;
};
