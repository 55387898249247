import { Grid, Box, Typography, Button, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import DocumentItem from 'components/UI/DocumentItem';
import DateHelper from 'helpers/DateHelper';
import { RenderHelper } from 'helpers/RenderHelper';
import { documentsTypes } from 'modules/orders/consts/OrdersConsts';
import { OrdersRenderHelper } from 'modules/orders/helpers/OrdersRenderHelper';
import OrderModel from 'modules/orders/models/OrderModel';
import { DocumentsTypesFields, OrderStatuses } from 'modules/orders/types/OrdersTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { declOfNum } from 'utils/declOfNum';

interface IWaterOrderProps {
  handleRejectClick: () => void;
  handleDocumentClick: (uuid: string) => void;
  order: OrderModel;
  declineReasons: Record<string, string> | null;
  handleAcceptClick: () => void;
  prePaymentValue: number;
}

const WaterOrder: React.FC<IWaterOrderProps> = observer(props => {
  const { order, handleRejectClick, handleDocumentClick, handleAcceptClick, declineReasons, prePaymentValue } = props;
  const hoursNames = ['час', 'часа', 'часов'];

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Renders
  const renderDocuments = () => {
    return order?.user?.privateFiles?.map(file => {
      return (
        <Grid key={file.uuid} item xs={6}>
          <DocumentItem
            title={file?.type && documentsTypes[file?.type as DocumentsTypesFields]}
            handleClick={() => file.uuid && handleDocumentClick(file.uuid)}
            icon={OrdersRenderHelper.getIconByDocType(file.type as DocumentsTypesFields)}
            isOrdersScreen
          />
        </Grid>
      );
    });
  };

  return (
    <Grid item>
      <div className={classes.orderContainer}>
        {order.status === OrderStatuses.DECLINED ? (
          <Box mb={2}>
            <Grid spacing={1} container>
              <Grid item>
                <Typography variant="body1">Причина отказа:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {order.declineReason ? declineReasons?.[order.declineReason] : ''}
                </Typography>
              </Grid>
            </Grid>
            {order.declineComment ? (
              <Typography className={classes.secondaryText} variant="body2">
                {order.declineComment}
              </Typography>
            ) : null}
          </Box>
        ) : null}

        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item>
              <div className={classes.imgContainer}>
                {order.transport?.wrapper?.fullUrl ? (
                  <img alt="img" src={order.transport?.wrapper?.fullUrl} className={classes.img} />
                ) : null}
              </div>
            </Grid>
            <Grid xs container direction="column" justifyContent="space-between" item>
              <Box mb={2.5}>
                <Grid container justifyContent="space-between">
                  <Box maxWidth={400}>
                    <Link className={commonClasses.textDecorationNone} to={`/transport/water/${order.transport?.id}`}>
                      <Typography variant="h3">{`${order.transport?.waterTransport?.name}`}</Typography>
                      <Typography className={classes.secondaryText} variant="body1">
                        {order.transport?.waterTransport?.category?.name}
                      </Typography>
                    </Link>
                  </Box>
                  <Typography variant="body1">№ {order.id}</Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item className={classes.titleContainer}>
              <Typography className={classes.secondaryText} variant="h5">
                Сумма:
              </Typography>
            </Grid>
            <Grid item>
              <Box mb={0.5}>
                <Typography variant="h5">{order.price?.toLocaleString()} ₽</Typography>
              </Box>
              {order.prePayment ? (
                <Typography className={classes.secondaryText} variant="body1">
                  <>
                    Внесена предоплата {prePaymentValue}% —{' '}
                    {OrdersRenderHelper.getPrepaymentSum(prePaymentValue, order.price || 0)} ₽
                  </>
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item className={classes.titleContainer}>
              <Typography className={classes.secondaryText} variant="h5">
                Дата/Время:
              </Typography>
            </Grid>
            <Grid item>
              <Box mb={0.5}>
                <Typography variant="h5">{`${DateHelper.formatDateForBookingView(
                  order.startDate,
                )} – ${DateHelper.formatDateForBookingView(order.endDate)}`}</Typography>
              </Box>
              <Typography className={classes.secondaryText} variant="body1">
                На {DateHelper.getDaysOrderCount(order.startDate, order.endDate, true)}{' '}
                {declOfNum(DateHelper.getDaysOrderCount(order.startDate, order.endDate, true), hoursNames)}{' '}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {order.delivery ? (
          <Box mb={4}>
            <Grid container spacing={2}>
              <Grid item className={classes.titleContainer}>
                <Typography className={classes.secondaryText} variant="h5">
                  Место:
                </Typography>
              </Grid>
              <Grid xs container item spacing={3} direction="column">
                <Grid item>
                  <Box mb={0.5}>
                    <Typography variant="h5">{order.deliveryAddress?.unrestrictedValue}</Typography>
                  </Box>
                  <Typography className={classes.secondaryText} variant="body1">
                    Адрес доставки
                  </Typography>
                </Grid>
                {order.returnAddress ? (
                  <Grid item>
                    <Box mb={0.5}>
                      <Typography variant="h5">{order.returnAddress?.unrestrictedValue}</Typography>
                    </Box>
                    <Typography className={classes.secondaryText} variant="body1">
                      Адрес возврата
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        ) : null}
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item className={classes.titleContainer}>
              <Typography className={classes.secondaryText} variant="h5">
                Клиент:
              </Typography>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Box mb={0.5}>
                  <Typography variant="h5">{order.userName}</Typography>
                </Box>
                <Typography className={classes.secondaryText} variant="body1">
                  {RenderHelper.formatPhoneString(order.userPhone || null)}
                </Typography>
              </Box>
              {order?.user?.privateFiles?.length ? (
                <Box maxWidth={300} mb={4}>
                  <Grid container spacing={1}>
                    {renderDocuments()}
                  </Grid>
                </Box>
              ) : null}
              {order.status === OrderStatuses.PAID ||
              order.status === OrderStatuses.CONFIRMED ||
              order.status === OrderStatuses.RENT ? (
                <Grid spacing={2} container>
                  {order.status === OrderStatuses.PAID ? (
                    <Grid item>
                      <Button onClick={handleRejectClick} className={classes.rejectBtn} variant="contained">
                        Отклонить
                      </Button>
                    </Grid>
                  ) : null}

                  <Grid item>
                    <Button onClick={handleAcceptClick} variant="contained" color="primary">
                      {OrdersRenderHelper.getSubmitBtn(order.status as OrderStatuses)?.text}
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  secondaryText: {
    color: theme.palette.custom.onSurface.secondary,
  },
  tertiaryText: {
    color: theme.palette.custom.onSurface.tertiary,
  },
  orderContainer: {
    border: `1px solid ${theme.palette.custom.overlay}`,
    borderRadius: 8,
    padding: theme.spacing(4, 4, 0, 4),
  },
  imgContainer: {
    width: 187,
    height: 120,
    overflow: 'hidden',
  },
  img: {
    maxWidth: '100%',
    backgroundSize: 'cover',
  },
  titleContainer: {
    width: 203,
  },
  rejectBtn: {
    color: theme.palette.custom.primary.red,
  },
}));

export default WaterOrder;
