import AbstractFactory from 'base/AbstractFactory';
import AutoTransport from 'modules/transport/models/AutoTransport';
import Brand from 'modules/transport/models/Brand';
import Model from 'modules/transport/models/Model';
import WaterTransport from 'modules/transport/models/WaterTransport';
import WaterTransportCategory from 'modules/transport/models/WaterTransportCategory';

import AutoCalendarItem from './models/AutoCalendarItem';
import CalendarDayItem from './models/CalendarDayItem';
import CalendarDayItemPeriod from './models/CalendarDayItemPeriod';
import WaterCalendarItem from './models/WaterCalendarItem';

export class CalendarFactory extends AbstractFactory {
  createAutoCalendar = (data: any): AutoCalendarItem[] => {
    if (!data?.length) {
      return [];
    }

    return data.map(this.createAutoCalendarItem);
  };

  createWaterCalendar = (data: any): WaterCalendarItem[] => {
    if (!data?.length) {
      return [];
    }

    return data.map(this.createWaterCalendarItem);
  };

  createAutoCalendarItem = (data: any) => {
    const model = data;
    model.autoTransport = this.createAutoTransport(data.autoTransport);
    model.schedule.days = this.createDaysList(data.schedule?.days);

    return this.create<AutoCalendarItem>(AutoCalendarItem, model);
  };

  createWaterCalendarItem = (data: any) => {
    const model = data;
    model.waterTransport = this.createWaterTransport(data.waterTransport);
    model.schedule.days = this.createDaysList(data.schedule?.days);

    return this.create<WaterCalendarItem>(WaterCalendarItem, model);
  };

  createDaysList = (data: any): CalendarDayItem[] => {
    if (!data?.length) {
      return [];
    }

    return data.map(this.createDayItem);
  };

  createDayItem = (data: any) => {
    const model = data;

    model.filledPeriods = this.createList<CalendarDayItemPeriod>(CalendarDayItemPeriod, model.filledPeriods ?? []);

    return this.create<CalendarDayItem>(CalendarDayItem, model);
  };

  createAutoTransport = (data: any) => {
    const model = data;
    model.brand = this.createBrand(data?.brand);
    model.model = this.createModel(data?.model);

    return this.create<AutoTransport>(AutoTransport, model);
  };

  createWaterTransport = (data: any) => {
    const model = data;
    model.category = this.create<WaterTransportCategory>(WaterTransportCategory, data?.category);

    return this.create<WaterTransport>(WaterTransport, model);
  };

  createBrand = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<Brand>(Brand, data);
  };

  createModel = (data: any) => {
    if (!data) {
      return;
    }

    return this.create<Model>(Model, data);
  };
}
