import AbstractModel from 'base/AbstractModel';
import FileModel from 'modules/files/models/FileModel';

import { TransportStatuses } from '../types/TransportTypes';
import AutoTransport from './AutoTransport';

export default class AutoTransportModel extends AbstractModel {
  id: number | null = null;
  transport: AutoTransport | null = null;
  wrapper: FileModel | null = null;
  status: TransportStatuses | null = null;
  lineStatus: TransportStatuses | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
