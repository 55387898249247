import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class CalendarApiRepository extends AbstractApiRepository {
  getAutoCalendar = (params: string) => {
    return this.apiClient.get({ url: `/lessor/rentals/calendar/auto${params}` });
  };

  getWaterCalendar = (params: string) => {
    return this.apiClient.get({ url: `/lessor/rentals/calendar/water${params}` });
  };
}
