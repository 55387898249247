import AbstractModel from 'base/AbstractModel';
import AutoTransport from 'modules/transport/models/AutoTransport';

import CalendarDayItem from './CalendarDayItem';

export default class AutoCalendarItem extends AbstractModel {
  id: number | null = null;
  autoTransport: AutoTransport | null = null;
  schedule: { days: CalendarDayItem[] } = { days: [] };

  constructor(props: any) {
    super();
    this.load(props);
  }
}
