import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface ILogoutBtnProps extends BoxProps {}

const LogoutBtn: React.FC<ILogoutBtnProps> = props => {
  const classes = useStyles();

  return (
    <Box component="button" {...props} className={clsx(classes.btn, props.className)}>
      <Typography variant="h5" className={classes.btnText}>
        Войти
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    color: theme.palette.custom.onSurface.secondary,
    transition: theme.transitions.create('color'),
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  btnText: {
    color: 'inherit',
  },
}));

export default LogoutBtn;
