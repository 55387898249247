export const appConfig = {
  apiUrl: {
    dev: 'https://rentalapi-dev.netimob.com/api',
    prod: process.env.REACT_APP_API_URL, // this var require on server
  },
  tokenStorageKey: '_rentalAccessToken',
  siteName: 'Rental Club',
  sentrySettings: {
    dsn: 'project_dsn_link',
  },
  sitekey: '6Lf9sfIfAAAAAEaQ1dO3f8J1xbYGgwFxeSN3m_nT',
};
