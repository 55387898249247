import AbstractModel from 'base/AbstractModel';

import WaterTransportCategory from './WaterTransportCategory';

export default class WaterTransport extends AbstractModel {
  id: number | null = null;
  name: string | null = null;
  category: WaterTransportCategory | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
