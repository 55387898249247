export const LoginForm: ILoginForm = {
  email: '',
  password: '',
  g_recaptcha_response: '',
};

export interface ILoginForm {
  email: string;
  password: string;
  g_recaptcha_response: string;
}

export enum LoginFormFields {
  EMAIL = 'email',
  PASSWORD = 'password',
  G_RECAPTCHA_RESPONSE = 'g_recaptcha_response',
}
