import { Dismiss20Regular } from '@fluentui/react-icons';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';

import { RenderHelper } from 'helpers/RenderHelper';
import { ILocalTariff } from 'modules/transport/consts/TransportConsts';
import { TariffFields } from 'modules/transport/forms/AddAutoForm';
import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import { TypesOfTransport } from 'modules/transport/types/TransportTypes';

interface ITariffItem {
  handleChangeTariff: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tariffId: number) => void;
  handleDeleteTariff: (event: React.MouseEvent<HTMLButtonElement>, tariffId: number) => void;
  handleSwitchTariffPeriod: (value: boolean, tariffId: number) => void;
  tariffId: number;
  transportType: TypesOfTransport;
  defaultValues?: ILocalTariff | null;
}

const TariffItem: React.FC<ITariffItem> = props => {
  const {
    handleChangeTariff,
    handleDeleteTariff,
    handleSwitchTariffPeriod,
    tariffId,
    transportType,
    defaultValues,
  } = props;
  const [isPeriod, setIsPeriod] = useState(!defaultValues?.isSingleDay || false);
  const classes = useStyles();

  // Handlers
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setIsPeriod(checked);
    handleSwitchTariffPeriod(checked, tariffId);
  };

  // Renders
  return (
    <Box mb={2} className={classes.rateContainer}>
      <Box mb={3}>
        <Grid spacing={1} container>
          {isPeriod && (
            <Grid item>
              <Typography variant="h4">От</Typography>
            </Grid>
          )}
          <Grid xs={2} item>
            <TextField
              placeholder={TransportRenderHelper.getTariffPlaceholder(transportType).placeholder}
              name={TariffFields.START}
              defaultValue={defaultValues?.start}
              onChange={e => handleChangeTariff(e, tariffId)}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              inputProps={{
                min: 1,
              }}
              required
            />
          </Grid>
          {isPeriod && (
            <>
              <Grid item>
                <Typography variant="h4">до</Typography>
              </Grid>
              <Grid xs={2} item>
                <TextField
                  placeholder={TransportRenderHelper.getTariffPlaceholder(transportType).placeholder}
                  name={TariffFields.END}
                  defaultValue={defaultValues?.end}
                  onChange={e => handleChangeTariff(e, tariffId)}
                  type="number"
                  onKeyDown={RenderHelper.disableInputKeyDownEvent}
                  onWheel={RenderHelper.disableInputWheelEvent}
                  inputProps={{
                    min: 0,
                  }}
                  required
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="h4">стоит</Typography>
          </Grid>
          <Grid xs={4} item>
            <TextField
              variant="standard"
              placeholder={`Стоимость в ${TransportRenderHelper.getTariffPlaceholder(transportType).in}, ₽`}
              name={TariffFields.PRICE}
              defaultValue={defaultValues?.price}
              onChange={e => handleChangeTariff(e, tariffId)}
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              inputProps={{
                min: 0,
                step: 0.01,
                lang: 'en-US',
              }}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <FormControlLabel
        control={<Checkbox color="primary" checked={isPeriod} name={TariffFields.END} onChange={handleCheck} />}
        label="Период"
      />
      {tariffId !== 0 && (
        <IconButton className={classes.iconBtn} onClick={e => handleDeleteTariff(e, tariffId)}>
          <Dismiss20Regular />
        </IconButton>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  rateContainer: {
    position: 'relative',
    padding: '24px 16px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  iconBtn: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
}));

export default TariffItem;
