import { serialize } from 'object-to-formdata';

import FilesApiRepository from './FilesApiRepository';
import { FilesFactory } from './FilesFactory';
import FileModel from './models/FileModel';

export default class FilesService {
  api: FilesApiRepository;
  factory: FilesFactory;

  constructor() {
    this.api = new FilesApiRepository();
    this.factory = new FilesFactory();
  }

  uploadImage = async (file: File) => {
    const formData = serialize({ file });
    const { data } = await this.api.uploadImage(formData);

    return this.factory.create<FileModel>(FileModel, data.data);
  };

  uploadImages = async (files: FileList, currentFilesCount: number) => {
    const formData = serialize({ files });
    const { data } = await this.api.uploadImages(formData);

    // Присваивание значений для id нужно, чтобы при добавлении автотранспорта была возможность удалять изображения
    const dataWithId = data.data.map((item: FileModel, index: number) => {
      if (!item.id) {
        return { ...item, id: currentFilesCount + index };
      }

      return item;
    });

    return this.factory.createList<FileModel>(FileModel, dataWithId);
  };

  uploadPdf = async (file: File) => {
    const formData = serialize({ file });
    const { data } = await this.api.uploadPdf(formData);

    return this.factory.create<FileModel>(FileModel, data.data);
  };

  uploadPdfs = async (files: FileList) => {
    const formData = serialize({ files });
    const { data } = await this.api.uploadPdfs(formData);

    return this.factory.createList<FileModel>(FileModel, data.data);
  };

  getPrivateFile = async (uuid: string) => {
    const data = await this.api.getPrivateFile(uuid);
    const url = URL.createObjectURL(data.data);

    return url;
  };
}
