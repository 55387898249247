import { FormControlLabel, Switch } from '@material-ui/core';

interface IQSwitchProps {
  name: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  checked?: boolean;
}

const QSwitch: React.FC<IQSwitchProps> = props => {
  const { name, handleChange, checked, label } = props;

  return (
    <FormControlLabel
      color="primary"
      control={<Switch name={name} onChange={handleChange} checked={checked} color="primary" />}
      label={label}
    />
  );
};

export default QSwitch;
