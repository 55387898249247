import AbstractModel from 'base/AbstractModel';
import Address from 'modules/rental/models/Address';
import TransportInfo from 'modules/transport/models/TransportInfo';

import { User } from '../../auth/models/User';

export default class OrderModel extends AbstractModel {
  id: number | null = null;
  status: string | null = null;
  price: number | null = null;
  deposit: boolean | null = null;
  prePayment: boolean | null = null;
  delivery: boolean | null = null;
  deliveryAddress: Address | null = null;
  returnAddress: Address | null = null;
  declineComment: string | null = null;
  declineReason: string | null = null;
  createdAt: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  transport: TransportInfo | null = null;
  user: User | null = null;
  userName: string | null = null;
  userPhone: string | null = null;
  type: string | null = null;
  tariffStart: number | null = null;
  tariffPrice: number | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
