import { ForgotPasswordFormFields } from 'modules/auth/forms/ForgotPasswordForm';
import { LoginFormFields } from 'modules/auth/forms/LoginForm';
import { RegisterFormFields } from 'modules/auth/forms/RegisterForm';

export enum AuthScreens {
  LOGIN = 'login',
  REGISTRATION_ENTER_CODE = 'registrationEnterCode',
  REGISTRATION = 'registration',
  REGISTRATION_PICK_PASSWORD = 'registrationPickPassword',
  FORGOT_PASSWORD_EMAIL = 'forgotPasswordEmail',
  FORGOT_PASSWORD_CODE = 'forgotPasswordCode',
  FORGOT_PASSWORD_PASSWORD = 'forgotPasswordPassword',
}

export enum AuthFormKeys {
  LOGIN_FORM = 'loginForm',
  REGISTER_FORM = 'registerForm',
  FORGOT_PASSWORD_FORM = 'forgotPasswordForm',
}

export type AuthFormFields = LoginFormFields | RegisterFormFields | ForgotPasswordFormFields;

export enum ErrorTypes {
  RESTORE_PASSWORD = 'restorePassword',
  NEW_PASSWORD = 'newPassword',
  LOGIN = 'login',
}
