import { Box, debounce, makeStyles, Theme } from '@material-ui/core';
import { add, sub } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import QWeekPicker from 'components/UI/QWeekPicker';
import { TypesOfRental } from 'modules/rental/types/RentalTypes';

import Calendar from './components/Calendar';

interface IRentCalendarScreenProps {}

const RentCalendarScreen: React.FC<IRentCalendarScreenProps> = observer(() => {
  const { calendarStore, rentalStore } = useRootStore();

  const classes = useStyles();

  const date = calendarStore.calendarForm.date ?? new Date();

  // Effects
  useEffect(() => {
    if (!calendarStore.calendarForm.date) {
      calendarStore.setDate(new Date());

      return;
    }

    if (!rentalStore.currentRental?.type) {
      return;
    }

    handleGetCalendar();
  }, [calendarStore.calendarForm.date, rentalStore.currentRental?.type]);

  useEffect(() => {
    return () => {
      calendarStore.clearCalendars();
      calendarStore.setQuery('');
      calendarStore.setDate(null);
    };
  }, []);

  // Handlers
  const handleGetCalendar = useCallback(
    debounce(() => {
      calendarStore.clearCalendars();

      rentalStore.currentRental?.type === TypesOfRental.AUTOMOBILE
        ? calendarStore.getAutoCalendar()
        : calendarStore.getWaterCalendar();
    }, 1000),
    [rentalStore.currentRental?.type],
  );

  const handlePlusDate = () => {
    calendarStore.setDate(add(date, { days: 7 }));
  };

  const handleMinusDate = () => {
    calendarStore.setDate(sub(date, { days: 7 }));
  };

  // Renders
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Box mb={2}>
          <QWeekPicker
            date={date}
            disabled={calendarStore.loading || calendarStore.hotLoading}
            onPlusWeek={handlePlusDate}
            onMinusWeek={handleMinusDate}
          />
        </Box>
        <Calendar selectedDate={date} />
      </div>
    </div>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    overflowX: 'auto',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
}));

export default RentCalendarScreen;
