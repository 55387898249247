import { Edit16Regular } from '@fluentui/react-icons';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface IFeatureProps {
  title: string;
  value: string;
  onClick: () => void;
  canEdit: boolean;
}

const Feature: React.FC<IFeatureProps> = ({ title, value, onClick, canEdit }) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{value}</Typography>
      </Grid>
      {canEdit && (
        <Grid item>
          <Button onClick={onClick} className={classes.editBtn} variant="contained">
            <Edit16Regular />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  editBtn: {
    padding: 6,
    width: 32,
    height: 32,
    minWidth: 0,
  },
}));

export default Feature;
