import { DialogProps, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { UpdateAutoFormFields } from 'modules/transport/forms/UpdateAutoForm';
import { TransportFormKeys } from 'modules/transport/types/TransportTypes';

interface IEditSpeedLimitModalProps extends DialogProps {
  onClose: () => void;
}

const EditSpeedLimitModal: React.FC<IEditSpeedLimitModalProps> = observer(({ onClose, ...rest }) => {
  const { transportStore } = useRootStore();

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    transportStore.setForm(TransportFormKeys.UPDATE_AUTO, name as UpdateAutoFormFields, value);
  };

  const handleSave = () => {
    transportStore.updateAuto(onClose);
  };

  // Renders
  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper title="Настроить ограничения скорости" onClose={onClose} onSave={handleSave}>
        <Box mb={3}>
          <QInput
            name={UpdateAutoFormFields.SPEED_LIMIT}
            onChange={handleChangeValues}
            value={transportStore.updateAutoForm[UpdateAutoFormFields.SPEED_LIMIT]}
            label="Напишите значение"
            type="number"
            onKeyDown={RenderHelper.disableInputKeyDownEvent}
            onWheel={RenderHelper.disableInputWheelEvent}
            inputProps={{
              min: 0,
            }}
          />
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

export default EditSpeedLimitModal;
