import Address from 'modules/rental/models/Address';

import { initAddress } from '../consts/RentalConsts';

export const RefreshAddressAndPhoneForm = {
  phone: '',
  address: initAddress as Address,
};

export interface IRefreshAddressAndPhoneForm {
  phone: string;
  address: Address;
}

export enum RefreshAddressAndPhoneFormFields {
  PHONE = 'phone',
  ADDRESS = 'address',
}
