import { createTheme, darken, lighten } from '@material-ui/core';

import { montserratMedium, montserratRegular } from './fonts';
import { palette } from './palette';

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 576,
      md: 794 + 24 * 2,
      lg: 999 + 24 * 2,
      xl: 1280,
    },
  },
  palette,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: 34,
      lineHeight: '48px',
    },
    h3: {
      fontWeight: 500,
      fontSize: 22,
      lineHeight: '32px',
      letterSpacing: 0.18,
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    h5: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.1,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.25,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: 0.4,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [montserratRegular, montserratMedium],
        body: {
          minWidth: 375,
        },
        main: {
          flex: '1 0 auto',
        },
        '#root': {
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
        '.router-link': {
          textDecoration: 'none',
        },
      },
    },
    MuiTypography: {
      root: {
        color: palette.common.black,
      },
    },
    MuiButton: {
      root: {
        padding: 12,
        borderRadius: 8,
        textTransform: 'none',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: 0.15,
      },
      contained: {
        backgroundColor: palette.secondary.main,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: darken(palette.secondary.main, 2),
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          color: palette.primary.main,
          backgroundColor: palette.secondary.main,
          opacity: 0.48,
        },
      },
      sizeSmall: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: 0.1,
        padding: '8px 12px',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: lighten(palette.primary.main, 0.12),
        },
        '&.Mui-disabled': {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
        },
      },
      text: {
        padding: 12,
        backgroundColor: 'transparent',
        transition: 'color .25s ease',
        '&:hover': {
          color: lighten(palette.primary.main, 0.12),
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormLabel: {
      root: {
        display: 'block',
        fontSize: 12,
        lineHeight: '16px',
        marginBottom: 8,
      },
    },
    MuiInput: {
      input: {
        '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },
      underline: {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiInputBase: {
      input: {
        '&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&[type="time"]::-webkit-calendar-picker-indicator': {
          background: 'none',
          display: 'none',
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &::-webkit-clear-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '&.Mui-focused': {
          '& $notchedOutline': {
            borderWidth: 1,
          },
        },
      },
      input: {
        padding: '11px 12px 10px',
      },
      notchedOutline: {
        borderColor: palette.custom.outline,
      },
      multiline: {
        padding: 12,
      },
    },
    MuiSwitch: {
      switchBase: {
        color: '#B1B1B1',
        '&.Mui-checked + .MuiSwitch-track': {
          opacity: 1,
        },
        '&.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'rgba(236, 236, 236,1)',
        },
      },
      colorPrimary: {
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: 'rgba(33, 33, 33, 0.08)',
        },
        '&.Mui-disabled': {
          color: 'rgba(216, 216, 216, 1)',
        },
        '&.Mui-disabled.Mui-checked': {
          color: 'rgba(143, 143, 143, 1)',
        },
      },
      track: {
        backgroundColor: 'rgba(33, 33, 33, 0.08)',
      },
    },
    MuiAutocomplete: {
      root: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          padding: '5px',
          '& .MuiAutocomplete-input': {
            padding: '6px 9px 5px',
          },
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #c4c4c4',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        fontSize: '16px',
        marginRight: '24px',
        '&$selected': {
          borderBottom: '0 !important',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            width: '100%',
            height: '3px',
            borderRadius: '8px',
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: palette.common.white,
        boxShadow: '4px -4px 15px rgba(139, 139, 139, 0.03), -4px 4px 12px rgba(0, 0, 0, 0.05)',
        borderRadius: 8,
        padding: 8,
      },
    },
  },
});
