import { makeStyles, Theme } from '@material-ui/core';
import transitions from '@material-ui/core/styles/transitions';
import clsx from 'clsx';
import ruLocale from 'date-fns/locale/ru';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DateHelper from 'helpers/DateHelper';
import { BookingRenderHelper } from 'modules/booking/helpers/BookingRenderHelper';

interface IQDatePickerProps extends ReactDatePickerProps {
  onChange: (dates: any, event: React.SyntheticEvent<any, Event> | undefined) => void;
  isSelectingRange?: boolean;
  small?: boolean;
  partFilledDates?: Date[];
}

const QDatePicker: React.FC<IQDatePickerProps> = props => {
  const { small, calendarClassName, onChange, partFilledDates, isSelectingRange = false, ...rest } = props;
  const classes = useStyles();

  // Renders
  const renderDayContents = (day: number, date: Date) => {
    const dayOfYear = DateHelper.getDayOfYear(date);

    if (BookingRenderHelper.getIsPartFilledDate(dayOfYear, partFilledDates, rest.excludeDates)) {
      return <div className={classes.partFilledDate}>{day}</div>;
    }

    return <div>{day}</div>;
  };

  return (
    <DatePicker
      locale={ruLocale}
      dateFormatCalendar="LLLL yyyy"
      calendarClassName={clsx({ small: small }, classes.calendar, calendarClassName)}
      renderDayContents={renderDayContents}
      wrapperClassName={classes.wrapper}
      popperClassName={classes.popper}
      selectsRange={isSelectingRange}
      onChange={onChange}
      disabledKeyboardNavigation
      {...rest}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
  },
  partFilledDate: {
    background: `linear-gradient(90deg, ${theme.palette.custom.overlay} 50%, ${theme.palette.custom.onPrimary.primary} 50%)`,
    border: '1px solid rgba(33, 33, 33, 0.08)',
  },
  popper: {
    zIndex: 5,
    '& .react-datepicker__triangle': {
      borderBottomColor: `transparent !important`,
      '&:before': {
        borderBottomColor: `transparent !important`,
      },
    },
  },
  calendar: {
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    fontFamily: 'Montserrat, sans-serif',
    '& .react-datepicker': {
      '&__navigation': {
        top: 0,
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        color: theme.palette.common.black,
        border: 0,
        transition: transitions.create('color'),
        '&--previous': {
          left: 24,
        },
        '&--next': {
          right: 24,
        },
        '&:hover': {
          color: theme.palette.common.black,
        },
        '& > svg': {
          width: '100%',
          height: '100%',
        },
      },
      '&__header': {
        padding: '6px 0 25px',
        borderBottom: 0,
        backgroundColor: 'transparent',
      },
      '&__current-month': {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '20px',
        color: theme.palette.common.black,
        marginBottom: 24,
        textTransform: 'capitalize',
      },
      '&__day-name': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        marginRight: 1,
        color: theme.palette.custom.onSurface.secondary,
        width: 40,
        textTransform: 'capitalize',
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
      '&__month': {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        // In selecting range: reset selected styles
        '&:not(&--selecting-range)': {
          '& .react-datepicker__day': {
            '&:hover': {
              '&:not(&--selected), &:not(&--disabled)': {
                '& div': {
                  borderColor: theme.palette.custom.onSurface.secondary,
                },
              },
            },
          },
        },
      },
      '&__week': {
        display: 'flex',
        margin: '0',
        borderRadius: 0,
        overflow: 'hidden',
        '&:not(:last-child)': {
          marginBottom: 4,
        },
      },
      '&__day': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        textAlign: 'center',
        margin: theme.spacing(0, 0.5),
        color: theme.palette.common.black,
        padding: 0,
        outline: 'none',
        '&:hover': {
          '&:not(.react-datepicker__day--disabled)': {
            '& > div': {
              borderColor: theme.palette.custom.onSurface.secondary,
            },
          },
        },
        // day inner base
        '&--today': {
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.custom.onSurface.primary}`,
          borderRadius: '50%',
        },
        '&--excluded': {
          color: theme.palette.custom.onSurface.tertiary,
        },
        '& div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          border: `1px solid  transparent`,
          transition: transitions.create('border-color'),
        },
        '&--disabled': {
          color: theme.palette.custom.onSurface.tertiary,
        },
        '&--keyboard-selected': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.common.black,
          borderRadius: '50%',
        },
        '&--selected': {
          color: theme.palette.common.white,
          border: 'none',
          backgroundColor: theme.palette.common.white,
          '&.react-datepicker__day--selecting-range-start': {
            borderRadius: 0,
            margin: 0,
            padding: '0 4px',
            width: 44,
            background: `linear-gradient(90deg, ${theme.palette.custom.onPrimary.primary} 50%, ${theme.palette.custom.onSurface.light} 50%)`,
          },
          '& div': {
            background: theme.palette.common.black,
          },
          '&.react-datepicker__day--outside-month': {
            color: theme.palette.common.white,
          },
        },
        '&--outside-month': {
          visibility: 'hidden',
        },

        '&--in-selecting-range': {
          borderRadius: 0,
          margin: 0,
          padding: '0 4px',
          width: 44,
          '& div': {
            background: 'transparent',
          },
          '&:not(--range-start), &:not(--range-end)': {
            backgroundColor: theme.palette.custom.onSurface.light,
            color: theme.palette.custom.onPrimary.primary,
          },
        },
        '&--in-range': {
          backgroundColor: theme.palette.custom.onSurface.light,
          color: theme.palette.custom.onPrimary.primary,
          borderRadius: 0,
          margin: 0,
          padding: '0 4px',
          width: 44,
          '& div': {
            background: 'transparent',
          },
        },
        '&--range-start': {
          border: 'none',
          background: `linear-gradient(90deg, ${theme.palette.custom.onPrimary.primary} 50%, ${theme.palette.custom.onSurface.light} 50%)`,
          '& div': {
            background: theme.palette.common.black,
          },
        },
        '&--range-end': {
          border: 'none',
          background: `linear-gradient(90deg, ${theme.palette.custom.onSurface.light} 50%, ${theme.palette.custom.onPrimary.primary} 50%)`,
          '& div': {
            background: `${theme.palette.common.black}!important`,
          },
        },
      },
    },
    '&.small': {
      '& .react-datepicker': {
        '&__header': {
          padding: '0 0 22px',
        },
        '&__day-name': {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px',
          margin: '0 3.83px',
          width: 32,
        },
        '&__day': {
          width: 32,
          height: 32,
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px',
          margin: '0 3.83px',
        },
        '&__week': {
          '&:not(:last-child)': {
            marginBottom: theme.spacing(1),
          },
        },
      },
    },
  },
}));

export default QDatePicker;
