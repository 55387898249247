import { DialogProps, Grid, Box, Typography, Select, MenuItem, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import NumberFormat from 'react-number-format';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import QSwitch from 'components/UI/QSwitch';
import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { prePaymentValues } from 'modules/rental/consts/RentalConsts';
import { RefreshDefaultSettingsFormFields } from 'modules/rental/forms/RefreshDefaultSettingsForm';
import { RentalRenderHelper } from 'modules/rental/helpers/RentalRenderHelper';
import { RentalFormKeys } from 'modules/rental/types/RentalTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { declOfNum } from 'utils/declOfNum';

interface IRefreshDefaultSettingsModalProps extends DialogProps {
  onClose: () => void;
}

const RefreshDefaultSettingsModal: React.FC<IRefreshDefaultSettingsModalProps> = observer(({ onClose, ...rest }) => {
  const { rentalStore } = useRootStore();
  const {
    DELIVERY,
    DELIVERY_PRICE,
    PRE_PAYMENT,
    DEPOSIT,
    SPEED_LIMIT,
    MILEAGE_LIMIT,
    AGE_LIMIT,
    EXPERIENCE_LIMIT,
    COMMENT,
    FUEL_COST_PER_LITER,
  } = RefreshDefaultSettingsFormFields;

  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const yearNames = ['года', 'лет', 'лет'];

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    if (name === DELIVERY_PRICE) {
      rentalStore.setForm(RentalFormKeys.REFRESH_DEFAULT_SETTINGS, DELIVERY, !!Number(value));
    }

    rentalStore.setForm(RentalFormKeys.REFRESH_DEFAULT_SETTINGS, name as RefreshDefaultSettingsFormFields, value);
  };

  const handleToggleSwitches = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === DELIVERY) {
      if (checked) {
        return;
      } else {
        rentalStore.setForm(RentalFormKeys.REFRESH_DEFAULT_SETTINGS, DELIVERY_PRICE, 0);
      }
    }

    rentalStore.setForm(RentalFormKeys.REFRESH_DEFAULT_SETTINGS, name as RefreshDefaultSettingsFormFields, checked);
  };

  const handleZeroingValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      return;
    }

    rentalStore.setForm(RentalFormKeys.REFRESH_DEFAULT_SETTINGS, name as RefreshDefaultSettingsFormFields, 0);
  };

  const handleSave = () => {
    rentalStore.refreshDefaultSettings(onClose);
  };

  // Renders
  const renderPrePaymentValues = () => {
    return prePaymentValues.map(item => {
      return <MenuItem key={item} value={item}>{`${item}%`}</MenuItem>;
    });
  };

  return (
    <QModal maxWidth="md" onClose={onClose} {...rest}>
      <EditModalWrapper onClose={onClose} onSave={handleSave} title="Редактировать основные настройки">
        <Grid container justifyContent="space-between">
          <Grid xs={5} item>
            <Box mb={1.5}>
              <QSwitch
                name={PRE_PAYMENT}
                handleChange={handleZeroingValues}
                label="Предоплата"
                checked={!!rentalStore.refreshDefaultSettingsForm[PRE_PAYMENT]}
              />
            </Box>
            <Box mb={5}>
              <Select
                name={PRE_PAYMENT}
                onChange={handleChangeValues}
                value={rentalStore.refreshDefaultSettingsForm[PRE_PAYMENT]}
                fullWidth={true}
                variant="outlined"
              >
                {renderPrePaymentValues()}
              </Select>
            </Box>
            <Box mb={5}>
              <QSwitch
                name={DEPOSIT}
                handleChange={handleToggleSwitches}
                label="Залог"
                checked={!!rentalStore.refreshDefaultSettingsForm[DEPOSIT]}
              />
              <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
                {RentalRenderHelper.getSettingDescription(rentalStore.currentRental?.type)}
              </Typography>
            </Box>
            {RentalRenderHelper.getIsAutoRental(rentalStore.currentRental?.type) ? (
              <>
                <Box mb={5}>
                  <QSwitch
                    name={SPEED_LIMIT}
                    handleChange={handleToggleSwitches}
                    label="Ограничения скорости"
                    checked={!!rentalStore.refreshDefaultSettingsForm[SPEED_LIMIT]}
                  />
                  <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
                    Редактируется для каждой машины отдельно в карточке машины
                  </Typography>
                </Box>
                <Box mb={5}>
                  <QSwitch
                    name={MILEAGE_LIMIT}
                    handleChange={handleToggleSwitches}
                    label="Ограничения км в день"
                    checked={!!rentalStore.refreshDefaultSettingsForm[MILEAGE_LIMIT]}
                  />
                  <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
                    Редактируется для каждой машины отдельно в карточке машины
                  </Typography>
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid xs={5} item>
            {RentalRenderHelper.getIsAutoRental(rentalStore.currentRental?.type) ? (
              <>
                <Box mb={1.5}>
                  <QSwitch
                    name={AGE_LIMIT}
                    handleChange={handleZeroingValues}
                    label="Ограничения по возрасту"
                    checked={!!rentalStore.refreshDefaultSettingsForm[AGE_LIMIT]}
                  />
                </Box>
                <Box mb={5}>
                  <Grid alignContent="center" spacing={1} container>
                    <Grid className={classes.inputContainer} item>
                      <Typography variant="body1">с</Typography>
                    </Grid>
                    <Grid xs={2} item>
                      <NumberFormat
                        name={AGE_LIMIT}
                        value={rentalStore.refreshDefaultSettingsForm[AGE_LIMIT]}
                        onChange={handleChangeValues}
                        customInput={QInput}
                        displayType="input"
                        format="##"
                      />
                    </Grid>
                    <Grid className={classes.inputContainer} item>
                      <Typography variant="body1">
                        {declOfNum(rentalStore.refreshDefaultSettingsForm[AGE_LIMIT] || 0, yearNames)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={1.5}>
                  <QSwitch
                    name={FUEL_COST_PER_LITER}
                    handleChange={handleZeroingValues}
                    label="Дозаправка на 1 литр топлива"
                    checked={!!rentalStore.refreshDefaultSettingsForm[FUEL_COST_PER_LITER]}
                  />
                </Box>
                <Box mb={5}>
                  <Grid alignContent="center" spacing={1} container>
                    <QInput
                      name={FUEL_COST_PER_LITER}
                      value={rentalStore.refreshDefaultSettingsForm[FUEL_COST_PER_LITER]}
                      onChange={handleChangeValues}
                      type="number"
                      onKeyDown={RenderHelper.disableInputKeyDownEvent}
                      onWheel={RenderHelper.disableInputWheelEvent}
                      inputProps={{
                        min: 0,
                        step: '0.01',
                      }}
                    />
                  </Grid>
                </Box>
                <Box mb={1.5}>
                  <QSwitch
                    name={EXPERIENCE_LIMIT}
                    handleChange={handleZeroingValues}
                    label="Ограничения по стажу"
                    checked={!!rentalStore.refreshDefaultSettingsForm[EXPERIENCE_LIMIT]}
                  />
                </Box>
                <Box mb={5}>
                  <Grid alignContent="center" spacing={1} container>
                    <Grid className={classes.inputContainer} item>
                      <Typography variant="body1">от</Typography>
                    </Grid>
                    <Grid xs={2} item>
                      <NumberFormat
                        name={EXPERIENCE_LIMIT}
                        onChange={handleChangeValues}
                        value={rentalStore.refreshDefaultSettingsForm[EXPERIENCE_LIMIT]}
                        customInput={QInput}
                        displayType="input"
                        format="##"
                      />
                    </Grid>
                    <Grid className={classes.inputContainer} item>
                      <Typography variant="body1">
                        {declOfNum(rentalStore.refreshDefaultSettingsForm[EXPERIENCE_LIMIT] || 0, yearNames)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Box mb={5}>
          <Box mb={1}>
            <QInput
              name={COMMENT}
              onChange={handleChangeValues}
              value={rentalStore.refreshDefaultSettingsForm[COMMENT]}
              label="Оставить комментарий (не обязательно)"
            />
          </Box>
          <Typography className={commonClasses.onSurfaceSecondary} variant="caption">
            Применимо ко всему прокату. Отобразится в карточке{' '}
            {RentalRenderHelper.getIsAutoRental(rentalStore.currentRental?.type) ? 'машины' : 'водного транспорта'}.
          </Typography>
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    margin: 'auto 0',
  },
}));

export default RefreshDefaultSettingsModal;
