import { Box, Button, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import QModal from 'components/UI/QModal';
import { RenderHelper } from 'helpers/RenderHelper';
import useQuery from 'hooks/useQuery';
import { ordersHeaderLinks } from 'modules/orders/consts/OrdersConsts';
import { ChangeOrderStatusFormFields } from 'modules/orders/forms/ChangeOrderStatusForm';
import { OrdersRenderHelper } from 'modules/orders/helpers/OrdersRenderHelper';
import OrderModel from 'modules/orders/models/OrderModel';
import { OrderStatuses } from 'modules/orders/types/OrdersTypes';
import { TypesOfRental } from 'modules/rental/types/RentalTypes';
import { useCommonStyles } from 'styles/commonStyles';

import AutoOrder from './components/AutoOrder';
import DeclineReasonForm from './components/DeclineReasonForm';
import WaterOrder from './components/WaterOrder';

interface IOrdersScreenProps {}

const OrdersScreen: React.FC<IOrdersScreenProps> = observer(() => {
  const { ordersStore, rentalStore, filesStore } = useRootStore();

  const query = useQuery();
  const orderType = query.get('type');

  const [isDeclineReasonModalOpen, setIsDeclineReasonModalOpen] = useState(false);
  const [isPhotoViewModalOpen, setIsPhotoViewModalOpen] = useState(false);

  const commonClasses = useCommonStyles();
  const classes = useStyles();

  // Effects
  useEffect(() => {
    ordersStore.initOrdersScreen(orderType as OrderStatuses, rentalStore.currentRental?.type);
  }, []);

  useEffect(() => {
    if (orderType) {
      ordersStore.getOrders(false, orderType as OrderStatuses);
    }

    document.addEventListener('scroll', handleUpdate);

    return () => {
      document.removeEventListener('scroll', handleUpdate);
    };
  }, [orderType]);

  // Handlers
  const handleSetDeclineReasonModalState = (state: boolean, orderId?: number | null) => {
    ordersStore.resetChangeStatusForm();

    if (orderId) {
      ordersStore.setChangeStatusForm(ChangeOrderStatusFormFields.ORDER_ID, orderId);
      ordersStore.setChangeStatusForm(ChangeOrderStatusFormFields.STATUS, OrderStatuses.DECLINED);
    }

    setIsDeclineReasonModalOpen(state);
  };

  const handleSetPhotoViewModalState = (state: boolean, uuid?: string) => {
    if (uuid) {
      filesStore.getPrivateFile(uuid);
    }

    setIsPhotoViewModalOpen(state);
  };

  const handleUpdate = () => {
    const isEndOfPage = RenderHelper.getEndOfPage();

    if (isEndOfPage && !ordersStore.isEndOfList && ordersStore.orders.length && !ordersStore.hotLoading) {
      ordersStore.getOrders(true, orderType as OrderStatuses);
    }
  };

  const handleAcceptClick = (order: OrderModel | null) => {
    if (!order) {
      return;
    }

    ordersStore.resetChangeStatusForm();
    ordersStore.setChangeStatusForm(ChangeOrderStatusFormFields.ORDER_ID, order.id);
    ordersStore.setChangeStatusForm(
      ChangeOrderStatusFormFields.STATUS,
      OrdersRenderHelper.getSubmitBtn(order.status as OrderStatuses)?.changingStatus as string,
    );

    ordersStore.changeStatus();
  };

  const handleSaveReason = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    ordersStore.changeStatus().then(() => {
      if (isDeclineReasonModalOpen) {
        handleSetDeclineReasonModalState(false);
      }
    });
  };

  // Renders
  const renderOrdersList = () => {
    return ordersStore.orders.map(order => {
      if (rentalStore.currentRental?.type === TypesOfRental.WATER) {
        return (
          <WaterOrder
            handleDocumentClick={uuid => handleSetPhotoViewModalState(true, uuid)}
            handleRejectClick={() => handleSetDeclineReasonModalState(true, order.id)}
            handleAcceptClick={() => handleAcceptClick(order)}
            order={order}
            prePaymentValue={rentalStore.currentRental?.prePayment || 0}
            declineReasons={ordersStore.declineReasons}
            key={order.id}
          />
        );
      }

      return (
        <AutoOrder
          handleDocumentClick={uuid => handleSetPhotoViewModalState(true, uuid)}
          handleRejectClick={() => handleSetDeclineReasonModalState(true, order.id)}
          handleAcceptClick={() => handleAcceptClick(order)}
          order={order}
          prePaymentValue={rentalStore.currentRental?.prePayment || 0}
          declineReasons={ordersStore.declineReasons}
          key={order.id}
        />
      );
    });
  };

  const renderHeader = () => {
    return ordersHeaderLinks.map(({ type, label }) => {
      return (
        <Grid item key={type}>
          <Link className={commonClasses.textDecorationNone} to={`/orders?type=${type}`}>
            <Button color="primary" variant={orderType === type ? 'contained' : 'text'}>
              {`${label}${OrdersRenderHelper.getCountInStatuses(ordersStore.statusesWithCount?.[type], type)}`}
            </Button>
          </Link>
        </Grid>
      );
    });
  };

  return (
    <>
      <Box mx="auto" maxWidth={900} mb={6}>
        <Grid alignItems="center" justifyContent="center" container spacing={1}>
          {ordersStore.statusesLoading ? <Loader /> : renderHeader()}
        </Grid>
      </Box>
      <Container maxWidth="md">
        <Grid container direction="column" spacing={6}>
          {ordersStore.loading ? <Loader /> : renderOrdersList()}
          {ordersStore.hotLoading ? <Loader /> : null}
        </Grid>
      </Container>

      <QModal onClose={() => handleSetDeclineReasonModalState(false)} open={isDeclineReasonModalOpen}>
        <DeclineReasonForm
          handleClose={() => handleSetDeclineReasonModalState(false)}
          handleSave={handleSaveReason}
          reasons={ordersStore.declineReasons}
          setForm={ordersStore.setChangeStatusForm}
          currentForm={ordersStore.changeOrderStatusForm}
        />
      </QModal>
      <QModal onClose={() => handleSetPhotoViewModalState(false)} open={isPhotoViewModalOpen}>
        <Box pt={6}>
          {filesStore.loading ? (
            <Loader />
          ) : (
            <Grid container justifyContent="center">
              <img className={classes.img} alt="img" src={filesStore.privateFile || ''} />
            </Grid>
          )}
        </Box>
      </QModal>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    height: '100%',
    width: '100%',
  },
}));

export default OrdersScreen;
