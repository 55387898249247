import { IMeta } from 'base/modules/pagination/PaginationModel';

export const initTariff = {
  start: undefined,
  end: undefined,
  price: undefined,
  isSingleDay: false,
};

export interface ILocalTariff {
  start: number | undefined;
  end: number | undefined;
  price: number | undefined;
  id: number;
  isSingleDay: boolean;
}

export const initialPagination: IMeta = {
  totalCount: 0,
  currentOffset: 0,
};
