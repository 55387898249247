import { RentalFactory } from './../rental/RentalFactory';
import BookingApiRepository from './BookingApiRepository';
import { BookingFactory } from './BookingFactory';
import BookingDto from './dto/BookingDto';
import { IBookingForm } from './forms/BookingForm';

export default class BookingService {
  api: BookingApiRepository;
  factory: BookingFactory;
  rentalFactory: RentalFactory;

  constructor() {
    this.api = new BookingApiRepository();
    this.factory = new BookingFactory();
    this.rentalFactory = new RentalFactory();
  }

  getBookingSchedule = async (transportId: number, date: string) => {
    const { data } = await this.api.getBookingSchedule(transportId, date);

    return this.factory.createBookingSchedule(data.data.days);
  };

  booking = async (data: IBookingForm) => {
    const dto = BookingDto.populate({ ...data, address: this.rentalFactory.createAddress(data.address) }) as BookingDto;

    return await this.api.booking(dto);
  };
}
