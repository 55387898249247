import AbstractModel from 'base/AbstractModel';

export default class Brand extends AbstractModel {
  id: number | null = null;
  name: string | null = null;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
