import AbstractFactory from 'base/AbstractFactory';

import DeliveryTariff from './models/DeliveryTariff';

export class DeliveryTariffsFactory extends AbstractFactory {
  createDeliveryTariffsList = (data: any[]) => {
    if (data.length) {
      return data.map(this.createDeliveryTariff);
    }

    return [];
  };

  createDeliveryTariff = (data: any) => {
    const model = this.create<DeliveryTariff>(DeliveryTariff, data);
    model.zone = data.deliveryZone;
    model.price = data.deliveryPrice;

    return model;
  };
}
