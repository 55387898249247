import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { useCommonStyles } from 'styles/commonStyles';

import { navLinks } from '../navLinks';
import NavLink from './NavLink';

interface INavListProps {}

const NavList: React.FC<INavListProps> = observer(() => {
  const { rentalStore } = useRootStore();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  return (
    <nav>
      <ul className={classes.list}>
        {navLinks(rentalStore.currentRental?.type || undefined).map((item, index) => (
          <li key={index} className={classes.item}>
            <NavLink
              isActiveWithNested={item.isActiveWithNested}
              className={isActive => clsx({ [commonClasses.fontWeight500]: isActive })}
              to={item.to}
              exact
            >
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(0, -3),
  },
  item: {
    margin: theme.spacing(0, 3),
  },
}));

export default NavList;
