import { makeAutoObservable, set, runInAction } from 'mobx';

import PaginationModel from 'base/modules/pagination/PaginationModel';

import CalendarService from './CalendarService';
import { initialPagination } from './consts/CalendarConsts';
import { CalendarForm, CalendarFormFields } from './forms/CalendarForm';
import { IDayToView } from './interfaces/CalendarInterfaces';
import AutoCalendarItem from './models/AutoCalendarItem';
import WaterCalendarItem from './models/WaterCalendarItem';
import { CalendarFormKeys } from './types/CalendarTypes';

export class CalendarStore {
  loading = false;
  hotLoading = false;

  autoCalendar: AutoCalendarItem[] | null = null;
  waterCalendar: WaterCalendarItem[] | null = null;
  daysMapToView: Record<string, IDayToView[][]> | null = null;

  calendarForm = CalendarForm;
  calendarPagination = initialPagination;

  private calendarService: CalendarService;

  get isEndOfList() {
    const { totalCount, currentOffset } = this.calendarPagination;

    return totalCount <= currentOffset;
  }

  constructor() {
    makeAutoObservable(this);
    this.calendarService = new CalendarService();
  }

  getAutoCalendar = (isLoadMore: boolean = false) => {
    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
      this.setPagination({ meta: initialPagination } as PaginationModel);
    }

    this.calendarService
      .getAutoCalendar(this.calendarForm, this.calendarPagination.currentOffset)
      .then(({ autoCalendar, daysMapToView, pagination }) => {
        runInAction(() => {
          if (isLoadMore && this.autoCalendar?.length) {
            this.autoCalendar = [...this.autoCalendar, ...autoCalendar];
            this.daysMapToView = { ...this.daysMapToView, ...daysMapToView };
          } else {
            this.autoCalendar = autoCalendar;
            this.daysMapToView = daysMapToView;
          }

          this.setPagination(pagination);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  getWaterCalendar = (isLoadMore: boolean = false) => {
    if (isLoadMore) {
      this.setHotLoading(true);
    } else {
      this.setLoading(true);
      this.setPagination({ meta: initialPagination } as PaginationModel);
    }

    this.calendarService
      .getWaterCalendar(this.calendarForm, this.calendarPagination.currentOffset)
      .then(({ waterCalendar, daysMapToView, pagination }) => {
        runInAction(() => {
          if (isLoadMore && this.waterCalendar?.length) {
            this.waterCalendar = [...this.waterCalendar, ...waterCalendar];
            this.daysMapToView = { ...this.daysMapToView, ...daysMapToView };
          } else {
            this.waterCalendar = waterCalendar;
            this.daysMapToView = daysMapToView;
          }

          this.setPagination(pagination);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoading(false);
        this.setHotLoading(false);
      });
  };

  setQuery = (query: string) => {
    this.setForm(CalendarFormKeys.CALENDAR, CalendarFormFields.QUERY, query);
  };

  setDate = (date: Date | null) => {
    this.setForm(CalendarFormKeys.CALENDAR, CalendarFormFields.DATE, date);
  };

  setForm = (formKey: CalendarFormKeys, key: CalendarFormFields, value: any) => {
    set(this[formKey], key, value);
  };

  setPagination = (pagination: PaginationModel) => {
    if (pagination.meta) {
      this.calendarPagination = pagination.meta;
    }
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setHotLoading = (value: boolean) => {
    this.hotLoading = value;
  };

  clearCalendars = () => {
    this.autoCalendar = null;
    this.waterCalendar = null;
    this.daysMapToView = null;
  };
}
