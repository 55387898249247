import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

import { TransportStatuses } from 'modules/transport/types/TransportTypes';

interface IRentStatusProps {
  status: TransportStatuses;
}

const RentStatus: React.FC<IRentStatusProps> = ({ status }) => {
  const classes = useStyles();

  // Renders
  const renderText = () => {
    switch (status) {
      case TransportStatuses.OFF_LINE:
        return 'Снят с линии';
      case TransportStatuses.IN_USE:
        return 'Занято';
      default:
        return 'Свободно';
    }
  };

  const renderColor = () => {
    switch (status) {
      case TransportStatuses.OFF_LINE:
        return classes.isOffLine;
      case TransportStatuses.IN_USE:
        return classes.isNotFree;
      default:
        return classes.isFree;
    }
  };

  return (
    <Typography className={renderColor()} variant="subtitle1" color="secondary">
      {renderText()}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  isFree: {
    color: theme.palette.custom.status.green,
  },
  isNotFree: {
    color: theme.palette.custom.status.red,
  },
  isOffLine: {
    color: theme.palette.custom.onSurface.primary,
  },
}));

export default RentStatus;
