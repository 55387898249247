import { BookingFormFields } from '../forms/BookingForm';

export enum BookingFormKeys {
  BOOKING = 'bookingForm',
}

export type BookingModuleFormFields = BookingFormFields;

export enum TimeTypes {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
}

export enum BookingDates {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum BookingFillTypes {
  FULL_FILLED = 'fullFilled',
  PART_FILLED = 'partFilled',
}

export enum BookingPeriodTypes {
  FILLED_PERIODS = 'filledPeriods',
  EMPTY_PERIODS = 'emptyPeriods',
}
