import { Box, Button } from '@material-ui/core';

import FileModel from 'modules/files/models/FileModel';
import { useCommonStyles } from 'styles/commonStyles';

interface IUploadDocument {
  handleUploadDocument: (e: React.ChangeEvent<HTMLInputElement>) => void;
  document: FileModel | null;
}

const UploadDocument: React.FC<IUploadDocument> = ({ handleUploadDocument, document }) => {
  const commonClasses = useCommonStyles();

  return (
    <>
      <input
        accept="application/pdf"
        id="uploadDocument"
        type="file"
        className={commonClasses.displayNone}
        onChange={handleUploadDocument}
      />
      <label htmlFor="uploadDocument">
        <Button variant="contained" component="span">
          Загрузить PDF
        </Button>
      </label>
      {document?.fileName && <Box mt={2}>{document?.fileName}</Box>}
    </>
  );
};

export default UploadDocument;
