export const RefreshDefaultSettingsForm: IRefreshDefaultSettingsForm = {
  delivery: false,
  deliveryPrice: 0,
  prePayment: 0,
  deposit: false,
  speedLimit: false,
  mileageLimit: false,
  ageLimit: 0,
  experienceLimit: 0,
  comment: '',
  fuelCostPerLiter: 0,
};

export interface IRefreshDefaultSettingsForm {
  delivery: boolean;
  deliveryPrice: number;
  prePayment: number;
  deposit: boolean;
  speedLimit: boolean;
  mileageLimit: boolean;
  ageLimit: number;
  experienceLimit: number;
  comment: string;
  fuelCostPerLiter: number;
}

export enum RefreshDefaultSettingsFormFields {
  DELIVERY = 'delivery',
  DELIVERY_PRICE = 'deliveryPrice',
  PRE_PAYMENT = 'prePayment',
  DEPOSIT = 'deposit',
  SPEED_LIMIT = 'speedLimit',
  MILEAGE_LIMIT = 'mileageLimit',
  AGE_LIMIT = 'ageLimit',
  EXPERIENCE_LIMIT = 'experienceLimit',
  COMMENT = 'comment',
  FUEL_COST_PER_LITER = 'fuelCostPerLiter',
}
