import { Dismiss20Regular } from '@fluentui/react-icons';
import { makeStyles, Theme, IconButton, Dialog } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import clsx from 'clsx';

interface IQModalProps extends DialogProps {
  disableCloseBtn?: boolean;
  modalClassName?: string;
}

const QModal: React.FC<IQModalProps> = props => {
  const classes = useStyles(props);
  const { children, disableCloseBtn, modalClassName, ...rest } = props;

  const handleClose = (e: any, r: any) => {
    e.stopPropagation();

    if (props.onClose) {
      props.onClose(e, r);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      PaperProps={{ className: clsx(classes.modal, modalClassName) }}
      scroll="body"
      disableRestoreFocus
      {...rest}
    >
      {!disableCloseBtn && (
        <IconButton
          size="medium"
          onClick={e => handleClose(e, null)}
          className={clsx(classes.closeButton)}
          classes={{ label: classes.label }}
        >
          <Dismiss20Regular />
        </IconButton>
      )}

      {children}
    </Dialog>
  );
};

const useStyles = makeStyles<Theme, IQModalProps>((theme: Theme) => ({
  modal: ({ maxWidth }) => ({
    padding: theme.spacing(3),
    maxWidth: maxWidth || 590,
    minHeight: 180,
    width: '100%',
    outline: 'none',
    borderRadius: 12,
    margin: theme.spacing(4),
    background: theme.palette.common.white,
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
      maxWidth: 'calc(100% - 16px) !important',
      padding: theme.spacing(2, 2, 4),
    },
  }),
  closeButton: {
    position: 'absolute',
    top: 26,
    right: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    padding: theme.spacing(0),
    color: theme.palette.primary.main,
    zIndex: 1,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  label: {
    height: '100%',
  },
}));

export default QModal;
