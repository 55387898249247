import {
  FormLabel,
  FormLabelProps,
  TextField,
  InputBaseComponentProps,
  StandardTextFieldProps,
} from '@material-ui/core';
import React from 'react';

import QHelperText from './QHelperText';

interface IQTextFieldProps extends Omit<StandardTextFieldProps, 'variant'> {
  errors?: string | string[] | null;
  labelProps?: FormLabelProps;
  variant?: 'standard' | 'outlined' | 'filled';
  maskComponent?: React.ElementType<InputBaseComponentProps>;
}

const QTextField: React.FC<IQTextFieldProps> = props => {
  const { errors, label, labelProps, variant, maskComponent, ...rest } = props;

  // Renders
  return (
    <>
      {label && <FormLabel {...labelProps}>{label}</FormLabel>}
      <TextField
        variant={variant}
        fullWidth
        error={!!errors}
        helperText={errors ? <QHelperText errors={errors} /> : undefined}
        InputProps={{ ...rest.InputProps, inputComponent: maskComponent }}
        {...rest}
      />
    </>
  );
};

export default QTextField;
