import PaginationModel from 'base/modules/pagination/PaginationModel';

import TransportApiRepository from './TransportApiRepository';
import { TransportFactory } from './TransportFactory';
import AddAutoDto from './dto/AddAutoDto';
import AddWaterDto from './dto/AddWaterDto';
import UpdateAutoDto from './dto/UpdateAutoDto';
import UpdateBrandDto from './dto/UpdateBrandDto';
import UpdateDepositDto from './dto/UpdateDepositDto';
import UpdateStatusDto from './dto/UpdateStatusDto';
import UpdateTariffsDto from './dto/UpdateTariffsDto';
import UpdateWaterDto from './dto/UpdateWaterDto';
import { IAddAutoForm } from './forms/AddAutoForm';
import { IAddWaterForm } from './forms/AddWaterForm';
import { IUpdateAutoForm } from './forms/UpdateAutoForm';
import { IUpdateBrandForm } from './forms/UpdateBrandForm';
import { IUpdateDepositForm } from './forms/UpdateDepositForm';
import { IUpdateStatusForm } from './forms/UpdateStatusForm';
import { IUpdateTariffsForm } from './forms/UpdateTariffsForm';
import { IUpdateWaterForm } from './forms/UpdateWaterForm';
import Brand from './models/Brand';
import DetailType from './models/DetailType';
import Model from './models/Model';

export default class TransportService {
  static LIMIT = 12;

  api: TransportApiRepository;
  factory: TransportFactory;

  constructor() {
    this.api = new TransportApiRepository();
    this.factory = new TransportFactory();
  }

  addAuto = async (data: IAddAutoForm) => {
    const dto = AddAutoDto.populate(data) as AddAutoDto;

    return await this.api.addAuto(dto);
  };

  addWater = async (data: IAddWaterForm) => {
    const dto = AddWaterDto.populate(data) as AddWaterDto;

    return await this.api.addWater(dto);
  };

  addModel = async (brandId: number, name: string) => {
    const { data } = await this.api.addModel(brandId, name);

    return this.factory.createModel(data.data);
  };

  getAutoTransport = async (id: number, limit: number, offset: number, query?: string) => {
    const queryString = query && query.length > 0 ? `&query=${query}` : '';
    const { data } = await this.api.getAutoTransport(id, limit, offset, queryString);

    const transport = this.factory.createAutoTransportList(data.data.items);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    return { transport, pagination };
  };

  getWaterTransport = async (id: number, limit: number, offset: number, categoryId?: number, query?: string) => {
    const queryString = query && query.length > 0 ? `&query=${query}` : '';
    const categoryIdString = categoryId || categoryId === 0 ? `&categoryId=${categoryId}` : '';
    const { data } = await this.api.getWaterTransport(id, limit, offset, categoryIdString, queryString);

    const transport = this.factory.createWaterTransportList(data.data.items);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    return { transport, pagination };
  };

  getWaterTransportCategories = async () => {
    const { data } = await this.api.getWaterTransportCategories();

    return this.factory.createCategoriesList(data.data);
  };

  getBrands = async (limit: number, offset: number, query?: string) => {
    const queryString = query && query.length > 0 ? `&query=${query}` : '';
    const { data } = await this.api.getBrands(limit, offset, queryString);

    const brands = this.factory.createList<Brand>(Brand, data.data.items);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    return { brands, pagination };
  };

  getModels = async (id: number, limit: number, offset: number, query?: string) => {
    const queryString = query && query.length > 0 ? `&query=${query}` : '';
    const { data } = await this.api.getModels(id, limit, offset, queryString);

    const models = this.factory.createList<Model>(Model, data.data.items);
    const pagination = this.factory.create<PaginationModel>(PaginationModel, data.data.pagination);

    return { models, pagination };
  };

  getFuelTypes = async () => {
    const { data } = await this.api.getFuelTypes();

    return this.factory.createList<DetailType>(DetailType, data.data);
  };

  getDriveTypes = async () => {
    const { data } = await this.api.getDriveTypes();

    return this.factory.createList<DetailType>(DetailType, data.data);
  };

  getBodyTypes = async () => {
    const { data } = await this.api.getBodyTypes();

    return this.factory.createList<DetailType>(DetailType, data.data);
  };

  getTransmissionTypes = async () => {
    const { data } = await this.api.getTransmissionTypes();

    return this.factory.createList<DetailType>(DetailType, data.data);
  };

  getTransportInfo = async (id: number) => {
    const { data } = await this.api.getTransportInfo(id);

    return this.factory.createTransportInfo(data.data);
  };

  updateBrand = async (data: IUpdateBrandForm) => {
    const dto = UpdateBrandDto.populate(data) as UpdateBrandDto;
    const updatedData = await this.api.updateBrand(dto);

    return this.factory.createTransportInfo(updatedData.data.data);
  };

  updateAuto = async (data: IUpdateAutoForm) => {
    const dto = UpdateAutoDto.populate({
      ...data,
      status: data.lineStatus,
      insurances: data.insurances.length ? data.insurances : null,
    }) as UpdateAutoDto;
    const updatedData = await this.api.updateAuto(dto);

    return this.factory.createTransportInfo(updatedData.data.data);
  };

  updateWater = async (data: IUpdateWaterForm) => {
    const dto = UpdateWaterDto.populate({ ...data, status: data.lineStatus }) as UpdateWaterDto;
    const updatedData = await this.api.updateWater(dto);

    return this.factory.createTransportInfo(updatedData.data.data);
  };

  updateTariffs = async (data: IUpdateTariffsForm) => {
    const dto = UpdateTariffsDto.populate(data) as UpdateTariffsDto;
    const updatedData = await this.api.updateTariffs(dto);

    return this.factory.createTransportInfo(updatedData.data.data);
  };

  updateDeposit = async (data: IUpdateDepositForm, isAutoTransport: boolean) => {
    const dto = UpdateDepositDto.populate({ ...data, amount: Number(data.amount) }) as UpdateDepositDto;
    const updatedData = await this.api.updateDeposit(dto, isAutoTransport);

    return this.factory.createTransportInfo(updatedData.data.data);
  };

  updateStatus = async (data: IUpdateStatusForm) => {
    const dto = UpdateStatusDto.populate(data) as UpdateStatusDto;
    const updatedData = await this.api.updateStatus(dto);

    return this.factory.createTransportInfo(updatedData.data.data);
  };

  deleteTransport = async (transportId: number) => {
    return await this.api.deleteTransport(transportId);
  };

  deleteTransportPhoto = async (transportId: number, photoId: number) => {
    const { data } = await this.api.deleteTransportPhoto(transportId, photoId);

    return this.factory.createTransportInfo(data.data);
  };
}
