import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink as RouterNavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import RouteHelper from 'base/routes/helpers/RouteHelper';
import { useCommonStyles } from 'styles/commonStyles';

interface INavLinkProps extends NavLinkProps {
  menuItems?: { title: string; to: string }[];
  mb0?: boolean;
  isActiveWithNested?: boolean;
}

const NavLink: React.FC<INavLinkProps> = props => {
  const { children, menuItems, mb0 = false, isActiveWithNested, ...rest } = props;
  const { pathname } = useLocation();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Renders
  const renderMenuItems = () => {
    return menuItems?.map(({ to, title }, index) => {
      return (
        <Link key={index} className={classes.link} to={to}>
          <div className={classes.item}>
            <Typography variant="h5">{title}</Typography>
          </div>
        </Link>
      );
    });
  };

  return (
    <>
      {menuItems?.length ? (
        <div className={classes.link}>
          <Box mb={3}>
            <Typography
              variant="h5"
              className={
                pathname.match(routes.AutoTransportScreen.path)?.length
                  ? commonClasses.onSurfacePrimary
                  : classes.linkText
              }
            >
              {children}
            </Typography>
          </Box>
          <div className={classes.dropdownMenu}>{renderMenuItems()}</div>
        </div>
      ) : (
        <RouterNavLink {...rest} className={classes.link}>
          <Box mb={mb0 ? 0 : 3}>
            <Typography
              variant="h5"
              className={
                isActiveWithNested && RouteHelper.getIsCurrentRoute(pathname, rest.to.toString())
                  ? commonClasses.onSurfacePrimary
                  : classes.linkText
              }
            >
              {children}
            </Typography>
          </Box>
        </RouterNavLink>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dropdownMenu: {
    position: 'absolute',
    left: 0,
    width: 267,
    opacity: 0,
    visibility: 'hidden',
    backgroundColor: theme.palette.common.white,
    transition: '.2s all linear',
    zIndex: 10,
  },
  link: {
    position: 'relative',
    textDecoration: 'none',
    color: theme.palette.custom.onSurface.secondary,
    cursor: 'pointer',
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: theme.palette.primary.main,
      '& $dropdownMenu': {
        opacity: 1,
        visibility: 'visible',
      },
    },
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  linkText: {
    color: 'inherit',
  },
  item: {
    textDecoration: 'none',
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      borderBottom: `1px dashed ${theme.palette.custom.overlay}`,
    },
  },
}));

export default NavLink;
