import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import Loader from 'components/UI/Loader';
import { RenderHelper } from 'helpers/RenderHelper';
import { RentalRenderHelper } from 'modules/rental/helpers/RentalRenderHelper';
import Schedule from 'modules/rental/models/Schedule';
import { RentalModals, TypesOfRental } from 'modules/rental/types/RentalTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { declOfNum } from 'utils/declOfNum';

import RentalBlockWrapper from './components/RentalBlockWrapper';
import RentalPhoto from './components/RentalPhoto';
import RefreshAddressAndPhoneModal from './components/modals/RefreshAddressAndPhoneModal';
import RefreshDeliveryModal from './components/modals/RefreshDeliveryModal';
import RefreshDefaultSettingsModal from './components/modals/RefreshMainSettingsModal';
import RefreshScheduleModal from './components/modals/RefreshScheduleModal';

interface IRentScreenProps {}

const RentScreen: React.FC<IRentScreenProps> = observer(() => {
  const { rentalStore, authStore, filesStore, deliveryTariffsStore } = useRootStore();
  const commonClasses = useCommonStyles();

  const [openedModals, setOpenedModals] = useState<Record<RentalModals, boolean>>(
    Object.assign(Object.values(RentalModals).map(item => ({ [item]: false }))),
  );

  const yearNames = ['года', 'лет', 'лет'];

  // Effects
  useEffect(() => {
    return () => {
      rentalStore.setSearchAddressData([]);
      filesStore.clearStore();
    };
  }, []);

  useEffect(() => {
    rentalStore.initRentalScreen();
    deliveryTariffsStore.initDeliveryTariffs(rentalStore.currentRental?.id);
  }, [rentalStore.currentRental]);

  // Handlers
  const handleUploadLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    filesStore.uploadImage(event.target.files[0], data => rentalStore.refreshLogo(data.uuid || ''));
  };

  const handleUploadPreview = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    filesStore.uploadImage(event.target.files[0], data => rentalStore.refreshPreview(data.uuid || ''));
  };

  const handleOpenModal = (modal: RentalModals) => {
    setOpenedModals({ ...openedModals, [modal]: true });
  };

  const handleCloseModal = (modal: RentalModals) => {
    setOpenedModals({ ...openedModals, [modal]: false });
  };

  // Renders
  const renderDeliveryTariffs = () => {
    return deliveryTariffsStore.deliveryTariffs.map(tariff => {
      return (
        <Box key={tariff.id} display="flex" alignItems="center" mb={2}>
          <Box mr={2} minWidth={210}>
            <Typography variant="body1" className={commonClasses.onSurfaceSecondary}>
              {tariff.zone}
            </Typography>
          </Box>
          <Typography variant="body1" className={commonClasses.onSurfacePrimary}>
            {tariff.price?.toLocaleString()}&nbsp;₽
          </Typography>
        </Box>
      );
    });
  };

  const renderSchedule = (schedulePart: Schedule[]) => {
    return schedulePart.map(schedule => {
      return (
        <Grid item container spacing={3} key={schedule?.shortDayName}>
          <Grid item>
            <Typography variant="body1">{schedule?.shortDayName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {schedule ? RentalRenderHelper.getScheduleItem(schedule) : 'Выходной'}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  if (rentalStore.globalLoading) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <Container maxWidth="md">
      <Box mb={1}>
        <Typography variant="h1">{rentalStore.currentRental?.title}</Typography>
      </Box>
      <Box mb={5}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item>
            <Typography variant="body1">Вы вошли как:</Typography>
          </Grid>
          {authStore.userInfo?.name && (
            <Grid item>
              <Typography className={commonClasses.onSurfacePrimary} variant="body1">
                {authStore.userInfo.name}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography className={commonClasses.onSurfacePrimary} variant="body1">
              {authStore.userInfo?.email}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={commonClasses.onSurfacePrimary} variant="body1">
              {RenderHelper.formatPhoneString(authStore.userInfo?.phone || null)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mb={5}>
        <Grid container spacing={6}>
          <Grid item>
            <RentalPhoto
              title="Фото-превью проката, JPG, PNG не больше 500Мб"
              uploadLabel="uploadPreview"
              img={rentalStore.currentRental?.preview}
              handleUpload={handleUploadPreview}
              width={328}
              height={208}
              canEdit={!!authStore.userInfo?.canEdit}
            />
          </Grid>
          <Grid item>
            <RentalPhoto
              title="Загрузите лого проката, JPG, PNG не больше 500Мб"
              uploadLabel="uploadLogo"
              img={rentalStore.currentRental?.logo}
              handleUpload={handleUploadLogo}
              width={240}
              height={81}
              canEdit={!!authStore.userInfo?.canEdit}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={5}>
        <RentalBlockWrapper
          canEdit={!!authStore.userInfo?.canEdit}
          onEdit={() => handleOpenModal(RentalModals.REFRESH_PHONE)}
          title="Адрес и телефон"
        >
          <Grid container direction="column" spacing={2}>
            <Grid item container spacing={3}>
              <Grid item>
                <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                  Адрес:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {rentalStore.currentRental?.address?.unrestrictedValue || 'Это отобразится в карточке салона'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item>
                <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                  Телефон:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {RenderHelper.formatPhoneString(rentalStore.currentRental?.phone || null) ||
                    'Он будет отображаться для связи с вами из карточки заказа'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </RentalBlockWrapper>
      </Box>
      <Box mb={5}>
        <RentalBlockWrapper
          canEdit={!!authStore.userInfo?.canEdit}
          onEdit={() => handleOpenModal(RentalModals.REFRESH_DEFAULT_SETTINGS)}
          title="Основные настройки"
        >
          <Grid container justifyContent="space-between">
            <Grid xs={6} container direction="column" spacing={2} item>
              <Grid item>
                <Grid item container spacing={3}>
                  <Grid item>
                    <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                      Предоплата
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {rentalStore.currentRental?.prePayment ? `${rentalStore.currentRental?.prePayment} %` : 'Нет'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid item container spacing={3}>
                  <Grid item>
                    <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                      Залог
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1">
                      {RenderHelper.getIsAvailable(rentalStore.currentRental?.deposit)}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className={commonClasses.onSurfaceTertiary} variant="body2">
                  {RentalRenderHelper.getSettingDescription(rentalStore.currentRental?.type)}
                </Typography>
              </Grid>
              {RentalRenderHelper.getIsAutoRental(rentalStore.currentRental?.type) ? (
                <>
                  <Grid item>
                    <Grid item container spacing={3}>
                      <Grid item>
                        <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                          Ограничения скорости
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          {RenderHelper.getIsAvailable(rentalStore.currentRental?.speedLimit)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography className={commonClasses.onSurfaceTertiary} variant="body2">
                      {RentalRenderHelper.getSettingDescription(rentalStore.currentRental?.type)}
                    </Typography>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item>
                      <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                        Ограничения км в день
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {RenderHelper.getIsAvailable(rentalStore.currentRental?.mileageLimit) || 'Нет'}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid xs={6} container direction="column" spacing={2} item>
              {RentalRenderHelper.getIsAutoRental(rentalStore.currentRental?.type) ? (
                <>
                  <Grid item container spacing={3}>
                    <Grid item>
                      <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                        Ограничения по возрасту
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {rentalStore.currentRental?.ageLimit
                          ? `с ${rentalStore.currentRental?.ageLimit} ${declOfNum(
                              rentalStore.currentRental?.ageLimit || 0,
                              yearNames,
                            )}`
                          : 'Нет'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item>
                      <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                        Дозаправка на 1 литр топлива
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {rentalStore.currentRental?.fuelCostPerLiter
                          ? `${rentalStore.currentRental?.fuelCostPerLiter} ₽`
                          : 'Нет'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item>
                      <Typography className={commonClasses.onSurfaceSecondary} variant="body1">
                        Ограничения по стажу
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1">
                        {rentalStore.currentRental?.experienceLimit
                          ? `от ${rentalStore.currentRental?.experienceLimit} ${declOfNum(
                              rentalStore.currentRental?.experienceLimit || 0,
                              yearNames,
                            )}`
                          : 'Нет'}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>

          {rentalStore.currentRental?.type === TypesOfRental.AUTOMOBILE ? (
            <Box my={3}>
              <Box mb={2}>
                <Grid justifyContent="space-between" alignItems="center" container>
                  <Typography variant="h5">Доставка автомобиля</Typography>
                  {authStore.userInfo?.canEdit ? (
                    <Button onClick={() => handleOpenModal(RentalModals.DELIVERY)} variant="contained">
                      Изменить
                    </Button>
                  ) : null}
                </Grid>
              </Box>
              {renderDeliveryTariffs()}
            </Box>
          ) : null}

          {rentalStore.currentRental?.comment ? (
            <>
              <Box mb={0.5} mt={2}>
                <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
                  Ваш комментарий для ваших клиентов:
                </Typography>
              </Box>
              <Typography variant="body1">{rentalStore.currentRental?.comment}</Typography>
            </>
          ) : (
            <>
              <Box mb={0.5} mt={1}>
                <Typography variant="body1">Так же вы можете оставить комментарий</Typography>
              </Box>
              <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
                Применимо ко всему прокату. Отобразится в карточке{' '}
                {RentalRenderHelper.getIsAutoRental(rentalStore.currentRental?.type) ? 'машины' : 'водного транспорта'}.
              </Typography>
            </>
          )}
        </RentalBlockWrapper>
      </Box>

      <RentalBlockWrapper
        canEdit={!!authStore.userInfo?.canEdit}
        onEdit={() => handleOpenModal(RentalModals.REFRESH_SCHEDULE)}
        title="Режим работы"
      >
        {rentalStore.currentRental?.isRoundTheClock ? (
          <Typography variant="body1">Круглосуточно</Typography>
        ) : !!rentalStore.currentRental?.schedule?.length ? (
          <Grid container spacing={10}>
            <Grid item>{renderSchedule(rentalStore.currentRental?.schedule?.slice(0, 5) || [])}</Grid>
            <Grid item>
              <Grid item container spacing={3}>
                {renderSchedule(rentalStore.currentRental?.schedule?.slice(-2) || [])}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Box mb={0.5}>
              <Typography variant="body1">Не задан</Typography>
            </Box>
            <Typography className={commonClasses.onSurfaceSecondary} variant="body2">
              Перейдите в редактирование, чтобы задать режим работы
            </Typography>
          </>
        )}
      </RentalBlockWrapper>

      <RefreshAddressAndPhoneModal
        onClose={() => handleCloseModal(RentalModals.REFRESH_PHONE)}
        open={!!openedModals[RentalModals.REFRESH_PHONE]}
      />
      <RefreshDefaultSettingsModal
        onClose={() => handleCloseModal(RentalModals.REFRESH_DEFAULT_SETTINGS)}
        open={!!openedModals[RentalModals.REFRESH_DEFAULT_SETTINGS]}
      />
      <RefreshScheduleModal
        maxWidth="md"
        onClose={() => handleCloseModal(RentalModals.REFRESH_SCHEDULE)}
        open={!!openedModals[RentalModals.REFRESH_SCHEDULE]}
      />
      <RefreshDeliveryModal
        onClose={() => handleCloseModal(RentalModals.DELIVERY)}
        open={!!openedModals[RentalModals.DELIVERY]}
      />
    </Container>
  );
});

export default RentScreen;
