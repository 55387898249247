import { format, isValid, parseISO, startOfWeek, lastDayOfWeek, getWeek, add } from 'date-fns';
import { ru } from 'date-fns/locale';

export default class DateHelper {
  static formatDate = (date: string | null | undefined, customFormat = 'dd.MM.yyyy') => {
    if (!date || !isValid(date)) {
      return '';
    }

    return format(new Date(parseISO(date)), customFormat, { locale: ru, useAdditionalDayOfYearTokens: true });
  };

  static format = (date: Date | null, customFormat = 'dd.MM.yyyy', options?: Object): string | null => {
    if (date) {
      return format(date, customFormat, { locale: ru, ...options });
    }

    return null;
  };

  static formatDateForServer = (date?: Date | null, time?: string | null) => {
    if (!date) {
      return '';
    }

    if (time) {
      return date?.toLocaleString('sv').split(' ')[0] + 'T' + time + ':00';
    }

    return date?.toLocaleString('sv').replace(' ', 'T');
  };

  static formatDateForBookingView = (fullDate?: string | null) => {
    if (!fullDate) {
      return '';
    }

    const date = format(parseISO(fullDate), 'dd.MM');
    const time = format(parseISO(fullDate), 'HH:mm');

    return date + ', ' + time;
  };

  static getBookingDaysCount = (
    startDate: Date | null,
    endDate: Date | null,
    startTime: string | null,
    endTime: string | null,
  ) => {
    if (!startDate || !startTime || !endTime) {
      return;
    }

    if (!endDate) {
      return 1;
    }

    const startSeconds = this.getSeconds(startTime);
    const endSeconds = this.getSeconds(endTime);

    return Math.round(
      (endDate.getTime() + endSeconds - startDate.getTime() + startSeconds) / (3600 * 24 * 1000) +
        (startSeconds < endSeconds ? 1 : 0),
    );
  };

  static getBookingHoursCount = (startTime: string | null, endTime: string | null) => {
    if (!startTime || !endTime) {
      return;
    }

    return Math.ceil((this.getSeconds(endTime) - this.getSeconds(startTime)) / 3600);
  };

  static getSeconds = (time: string) => {
    const hours = Number(time.split(':')[0]);
    const minutes = Number(time.split(':')[1]);

    return hours * 3600 + minutes * 60;
  };

  static getDayOfYear = (date: Date) => {
    let now = new Date(date);
    let start = new Date(now.getFullYear(), 0, 0);
    let diff = now.getTime() - start.getTime() + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    let oneDay = 1000 * 60 * 60 * 24;

    return Math.floor(diff / oneDay);
  };

  static getDaysOrderCount = (startDate: string | null, endDate: string | null, isReturnHours: boolean = false) => {
    if (!startDate || !endDate) {
      return 0;
    }

    return Math.ceil(
      (parseISO(endDate).getTime() - parseISO(startDate).getTime()) / (1000 * 3600 * (isReturnHours ? 1 : 24)),
    );
  };

  static getWeekByDay = (date: Date, format = 'dd LLL') => {
    const startDate = startOfWeek(date, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(date, { weekStartsOn: 1 });

    const week = getWeek(date, { weekStartsOn: 1 });

    return {
      week,
      startDate,
      endDate,
      startDateFormatted: DateHelper.format(startDate, format),
      endDateFormatted: DateHelper.format(endDate, format),
      weekDates: DateHelper.getWeekDatesArray(startDate),
    };
  };

  static getWeekDatesArray = (startDate: Date) => {
    return [0, 1, 2, 3, 4, 5, 6].map(item => add(startDate, { days: item }));
  };

  static getNotValidPeriod = (startDate: string, endDate: string): boolean | string => {
    if (parseISO(startDate).getTime() >= parseISO(endDate).getTime()) {
      return 'Дата конца аренды меньше или равна дате начала аренды';
    }

    return false;
  };
}
