import { DialogProps, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import QInput from 'components/UI/textFields/QInput';
import { UpdateAutoFormFields } from 'modules/transport/forms/UpdateAutoForm';
import { UpdateWaterFormFields } from 'modules/transport/forms/UpdateWaterForm';
import { TransportFormKeys, TypesOfTransport } from 'modules/transport/types/TransportTypes';

interface IEditCommentModalProps extends DialogProps {
  onClose: () => void;
  transportType: TypesOfTransport;
}

const EditCommentModal: React.FC<IEditCommentModalProps> = observer(({ onClose, transportType, ...rest }) => {
  const { transportStore } = useRootStore();
  const { COMMENT } = transportType === TypesOfTransport.WATER ? UpdateWaterFormFields : UpdateAutoFormFields;

  // Handlers
  const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name, value } = event.target;

    transportType === TypesOfTransport.WATER
      ? transportStore.setForm(TransportFormKeys.UPDATE_WATER, name as UpdateWaterFormFields, value)
      : transportStore.setForm(TransportFormKeys.UPDATE_AUTO, name as UpdateAutoFormFields, value);
  };

  const handleSave = () => {
    transportType === TypesOfTransport.WATER ? transportStore.updateWater(onClose) : transportStore.updateAuto(onClose);
  };

  // Renders
  return (
    <QModal onClose={onClose} {...rest}>
      <EditModalWrapper title="Настроить комментарий" onClose={onClose} onSave={handleSave}>
        <Box mb={3}>
          <QInput
            name={COMMENT}
            onChange={handleChangeValues}
            value={
              transportType === TypesOfTransport.WATER
                ? transportStore.updateWaterForm[COMMENT]
                : transportStore.updateAutoForm[COMMENT]
            }
            label="Напишите комментарий"
          />
        </Box>
      </EditModalWrapper>
    </QModal>
  );
});

export default EditCommentModal;
