import MontserratMediumEot from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.eot';
import MontserratMediumTtf from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.ttf';
import MontserratMediumWoff from '../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff';
import MontserratRegularEot from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.eot';
import MontserratRegularTtf from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.ttf';
import MontserratRegularWoff from '../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff';

export const montserratRegular = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${MontserratRegularTtf}) format('ttf'),
    url(${MontserratRegularEot}) format('eot'),
    url(${MontserratRegularWoff}) format('woff2')
  `,
};

export const montserratMedium = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('Montserrat'),
    local('Montserrat-Medium'),
    url(${MontserratMediumTtf}) format('ttf'),
    url(${MontserratMediumEot}) format('eot'),
    url(${MontserratMediumWoff}) format('woff')
  `,
};
