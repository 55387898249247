import NumberFormat, { NumberFormatProps } from 'react-number-format';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

import { BaseNumberInput } from './NumberInputs';

const BaseMaskInput = (props: any, format: string, otherProps?: NumberFormatProps) => {
  return BaseNumberInput(props, {
    format,
    mask: otherProps?.mask || '_',
    ...otherProps,
  });
};

export const RenderWithMask = (value: string, mask: string) => {
  return <NumberFormat value={value} displayType={'text'} format={mask} />;
};

export const PhoneMaskInput = (props: any) => {
  return BaseMaskInput(props, '+# ### ###-##-##');
};

export const cleanUpPhoneMask = (maskedPhone: string) => {
  return maskedPhone.replace(/\D+/g, '');
};

export const BirthDateMaskInput = (props: any) => {
  return BaseMaskInput(props, '##.##.####');
};

export const InnMaskInput = (props: any) => {
  return BaseMaskInput(props, '############');
};

export const SnilsMaskInput = (props: any) => {
  return BaseMaskInput(props, '###-###-### ##');
};

export const RFPassportSeriesMaskInput = (props: any) => {
  return BaseMaskInput(props, '####');
};

export const RFPassportNumberMaskInput = (props: any) => {
  return BaseMaskInput(props, '######');
};

export const RFPassportDepartmentCodeMaskInput = (props: any) => {
  return BaseMaskInput(props, '###-###');
};

export const RFBirthCertificateMaskInput = (props: any) => {
  return BaseMaskInput(props, '##########');
};

export const CurrencyMaskInput = (props: any) => {
  const currencyMask = createNumberMask({
    prefix: '',
    suffix: '₽',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 12,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  return <MaskedInput mask={currencyMask} {...props} />;
};
