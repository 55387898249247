import AbstractDto from 'base/AbstractDto';

import Schedule from '../models/Schedule';

export default class RefreshScheduleDto extends AbstractDto {
  schedule: Schedule[] = [];
  roundTheClock: boolean = false;

  constructor(props: any) {
    super();
    this.load(props);
  }
}
