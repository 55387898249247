import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { TransportStatuses } from 'modules/transport/types/TransportTypes';
import { useCommonStyles } from 'styles/commonStyles';

import RentStatus from './RentStatus';
import TransportItemImg from './TransportItemImg';

interface ITransportItem {
  wrapper: string;
  status: TransportStatuses;
  isWaterTransport?: boolean;
  brandName?: string;
  modelName?: string;
  stateNumber?: string;
  name?: string;
  transportId: number;
}

const TransportItem: React.FC<ITransportItem> = props => {
  const { wrapper, status, brandName, modelName, stateNumber, isWaterTransport = false, name, transportId } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const itemTitle = isWaterTransport ? `${name}` : `${brandName} ${modelName}`;

  return (
    <div className={clsx(classes.cardContainer, { off_line: status === TransportStatuses.OFF_LINE })}>
      <Link
        className={commonClasses.textDecorationNone}
        to={`/transport/${isWaterTransport ? 'water' : 'auto'}/${transportId}`}
      >
        <TransportItemImg img={wrapper} />
        <div className={classes.contentContainer}>
          <Box mb={0.5}>
            <RentStatus status={status} />
          </Box>
          <Box mb={0.5}>
            <Typography variant="h4" color="primary">
              {itemTitle}
            </Typography>
          </Box>
          {!isWaterTransport && (
            <Box>
              <Typography className={classes.transportNumberText} variant="body1" color="secondary">
                {stateNumber}
              </Typography>
            </Box>
          )}
        </div>
      </Link>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    width: '248px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.custom.outline}`,
    overflow: 'hidden',
    '&.off_line': {
      opacity: 0.5,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 24px 16px',
  },
  transportNumberText: {
    color: theme.palette.custom.onSurface.secondary,
  },
}));

export default TransportItem;
