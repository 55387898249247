import AbstractDto from 'base/AbstractDto';

export default class ChangeOrderStatusDto extends AbstractDto {
  status: string = '';
  orderId: number = 0;
  reason: string = '';
  comment: string = '';

  constructor(props: any) {
    super();
    this.load(props);
  }
}
