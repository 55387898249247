import { TransportStatuses } from '../types/TransportTypes';

export const UpdateStatusForm = {
  transportId: 0,
  status: TransportStatuses.FREE,
};

export interface IUpdateStatusForm {
  transportId: number;
  status: TransportStatuses;
}

export enum UpdateStatusFormFields {
  TRANSPORT_ID = 'transportId',
  STATUS = 'status',
}
