export const RegisterForm: IRegisterForm = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  token: '',
  type: 'email',
  code: '',
};

export interface IRegisterForm {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  token: string;
  type: string;
  code: string;
}

export enum RegisterFormFields {
  NAME = 'name',
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'passwordConfirmation',
  TOKEN = 'token',
  TYPE = 'type',
  CODE = 'code',
}
