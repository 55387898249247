import { Search24Regular } from '@fluentui/react-icons';
import { Box, debounce, Grid, Icon, InputAdornment, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isSameDay } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import RouteHelper from 'base/routes/helpers/RouteHelper';
import Loader from 'components/UI/Loader';
import QTextField from 'components/UI/textFields/QTextField';
import DateHelper from 'helpers/DateHelper';
import { RenderHelper } from 'helpers/RenderHelper';
import { useRefWithCallback } from 'hooks/useRefWithCallback';
import { CalendarHelper } from 'modules/calendar/helpers/CalendarHelper';
import { IDayToView } from 'modules/calendar/interfaces/CalendarInterfaces';
import { TypesOfRental } from 'modules/rental/types/RentalTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { palette } from 'styles/palette';

import CalendarTooltip from './CalendarTooltip';

interface ICalendarProps {
  selectedDate: Date;
}

const Calendar: React.FC<ICalendarProps> = observer(props => {
  const { calendarStore, rentalStore } = useRootStore();
  const { selectedDate } = props;

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const week = DateHelper.getWeekByDay(selectedDate);
  const currentDate = new Date();

  const autoListRef = useRefWithCallback(
    node => node.addEventListener('scroll', handleUpdateCalendarScroll),
    node => node.removeEventListener('scroll', handleUpdateCalendarScroll),
  );

  const waterListRef = useRefWithCallback(
    node => node.addEventListener('scroll', handleUpdateCalendarScroll),
    node => node.removeEventListener('scroll', handleUpdateCalendarScroll),
  );

  // Handlers
  const handleGetCalendar = useCallback(
    debounce((isLoadMore = false) => {
      rentalStore.currentRental?.type === TypesOfRental.AUTOMOBILE
        ? calendarStore.getAutoCalendar(isLoadMore)
        : calendarStore.getWaterCalendar(isLoadMore);
    }, 1000),
    [rentalStore.currentRental?.type],
  );

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    calendarStore.setQuery(event.target.value);
    handleGetCalendar();
  };

  const handleUpdateCalendarScroll = (e: Event) => {
    const isEndOfBlock = RenderHelper.getEndOfBlock(e.target as HTMLDivElement, 100);

    if (isEndOfBlock && !calendarStore.isEndOfList) {
      handleGetCalendar(true);
    }
  };

  // Renders
  const renderDates = () => (
    <>
      {week.weekDates.map(date => (
        <Box
          key={date.getUTCDate()}
          className={clsx(classes.cell, classes.cellData)}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box mr={2}>
            <Typography variant="subtitle2" className={classes.cellHeaderDateText}>
              {DateHelper.format(date, 'EEEEEE')}
            </Typography>
          </Box>
          <Box className={clsx(classes.cellHeaderDay, { active: isSameDay(date, currentDate) })}>
            <Typography variant="subtitle2" color="inherit">
              {DateHelper.format(date, 'dd')}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );

  const renderAutoTable = () => (
    <div className={classes.bodyRows} ref={autoListRef}>
      {calendarStore.autoCalendar?.map(item => {
        const daysToView = calendarStore.daysMapToView?.[item.id ?? ''] ?? [];
        const route = RouteHelper.makePath(routes.AutoBookingScreen.path, [{ p: 'id', v: item.id ?? '' }]);

        return (
          <Box className={clsx(classes.row, classes.bodyRow)}>
            <Box className={clsx(classes.cell, classes.cellHeader)}>
              <Link className={clsx(commonClasses.onSurfaceSecondary)} to={route}>
                <Typography className={commonClasses.onSurfacePrimary} variant="subtitle1">
                  {item.autoTransport?.brand?.name ?? ''} {item.autoTransport?.model?.name ?? ''}
                </Typography>
              </Link>
              <Typography className={commonClasses.onSurfacePrimary} variant="caption">
                {item.autoTransport?.stateNumber ?? ''}
              </Typography>
            </Box>
            {renderDays(daysToView)}
          </Box>
        );
      })}
    </div>
  );

  const renderWaterTable = () => (
    <div className={classes.bodyRows} ref={waterListRef}>
      {calendarStore.waterCalendar?.map(item => {
        const daysToView = calendarStore.daysMapToView?.[item.id ?? ''] ?? [];
        const route = RouteHelper.makePath(routes.WaterBookingScreen.path, [{ p: 'id', v: item.id ?? '' }]);

        return (
          <Box className={clsx(classes.row, classes.bodyRow)}>
            <Box className={clsx(classes.cell, classes.cellHeader)}>
              <Link className={clsx(commonClasses.onSurfaceSecondary)} to={route}>
                <Typography className={commonClasses.onSurfacePrimary} variant="subtitle1">
                  {item.waterTransport?.name ?? ''}
                </Typography>
              </Link>
              <Typography className={commonClasses.onSurfacePrimary} variant="caption">
                {item.waterTransport?.category?.name ?? ''}
              </Typography>
            </Box>
            {renderDays(daysToView)}
          </Box>
        );
      })}
    </div>
  );

  const renderDays = (daysToView: IDayToView[][]) => (
    <>
      {daysToView.map((item, idx, arr) => {
        if (item.length === 0) {
          return <Box className={clsx(classes.cell, classes.cellData)}></Box>;
        }

        if (item.length === 1) {
          const { start, end, hasDate, fullDateWrap } = CalendarHelper.getText(item[0], idx, arr, week.weekDates);

          return (
            <Box className={clsx(classes.cell, classes.cellData)}>
              <div className={classes.dayItem}>
                {!item[0].leftConnection && (
                  <Typography
                    className={clsx(commonClasses.onSurfaceSecondary, {
                      [classes.text]: hasDate,
                      [classes.textWrap]: fullDateWrap,
                    })}
                    variant="subtitle2"
                  >
                    {!hasDate || fullDateWrap ? (
                      `${start} – ${end}`
                    ) : (
                      <>
                        <div className={!fullDateWrap ? classes.textWrap : undefined}>{start} – </div>
                        <div className={!fullDateWrap ? classes.textWrap : undefined}>{end}</div>
                      </>
                    )}
                  </Typography>
                )}
              </div>
              {item[0].leftConnection && <div className={classes.leftConnection} />}
              {item[0].rightConnection && <div className={classes.rightConnection} />}
            </Box>
          );
        }

        const { start, end, hasDate, fullDateWrap } = CalendarHelper.getText(
          item[item.length - 1],
          idx,
          arr,
          week.weekDates,
        );

        return (
          <Tooltip
            disableHoverListener={item.length < 3}
            title={<CalendarTooltip {...CalendarHelper.getTooltipText(item)} />}
          >
            <Box className={clsx(classes.cell, classes.cellData)}>
              <Grid container>
                <Grid item xs={6}>
                  <Box height="100%" mr={0.5}>
                    <div className={classes.dayItem}>
                      {!item[0].leftConnection && (
                        <Typography className={commonClasses.onSurfaceSecondary} variant="subtitle2">
                          {item[0].start} – {item[0].end}
                        </Typography>
                      )}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box height="100%" ml={0.5}>
                    <div className={classes.dayItem}>
                      <Typography
                        className={clsx(commonClasses.onSurfaceSecondary, {
                          [classes.text]: hasDate,
                          [classes.textWrap]: fullDateWrap,
                        })}
                        variant="subtitle2"
                      >
                        {!hasDate || fullDateWrap ? (
                          `${start} – ${end}`
                        ) : (
                          <>
                            <div className={!fullDateWrap ? classes.textWrap : undefined}>{start} – </div>
                            <div className={!fullDateWrap ? classes.textWrap : undefined}>{end}</div>
                          </>
                        )}
                      </Typography>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              {item[0].leftConnection && <div className={classes.leftConnection} />}
              {item[item.length - 1].rightConnection && <div className={classes.rightConnection} />}
            </Box>
          </Tooltip>
        );
      })}
    </>
  );

  return (
    <Box className={classes.table}>
      <Box className={classes.row}>
        <Box className={clsx(classes.cell, classes.cellHeader)}>
          <QTextField
            value={calendarStore.calendarForm.query}
            disabled={calendarStore.loading || calendarStore.hotLoading}
            placeholder={rentalStore.currentRental?.type === TypesOfRental.AUTOMOBILE ? 'Марка, модель' : 'Название'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.searchIcon}>
                    <Search24Regular />
                  </Icon>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeInput}
          />
        </Box>
        {renderDates()}
      </Box>

      {rentalStore.currentRental?.type === TypesOfRental.AUTOMOBILE && !calendarStore.loading && renderAutoTable()}
      {rentalStore.currentRental?.type === TypesOfRental.WATER && !calendarStore.loading && renderWaterTable()}

      {(calendarStore.loading || calendarStore.hotLoading) && (
        <Box className={classes.row} justifyContent="center" padding={1}>
          <Loader size={32} />
        </Box>
      )}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    border: `1px solid ${palette.custom.outline2}`,
    borderRadius: theme.spacing(1),
  },
  cell: {
    borderRight: `1px solid ${palette.custom.outline2}`,
    padding: theme.spacing(1),
    '&:last-child': {
      borderRight: 'none',
    },
  },
  cellHeader: {
    width: 208,
  },
  cellHeaderDateText: {
    color: palette.custom.onSurface.secondary,
  },
  cellHeaderDay: {
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '&.active': {
      color: palette.common.white,
      backgroundColor: palette.custom.onSurface.primary,
    },
  },
  cellData: {
    width: 155,
    position: 'relative',
  },
  row: {
    display: 'flex',
    borderBottom: `1px solid ${palette.custom.outline2}`,
    '&:last-of-type': {
      border: 'none',
    },
  },
  bodyRow: {
    minHeight: 58,
  },
  bodyRows: {
    maxHeight: `calc(100vh - ${theme.spacing(37)}px)`,
    overflowY: 'auto',
  },
  searchIcon: {
    color: palette.custom.onSurface.tertiary,
  },
  dayItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
    backgroundColor: '#E9E9E9',
    borderRadius: 4,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  leftConnection: {
    position: 'absolute',
    top: theme.spacing(1),
    bottom: theme.spacing(1),
    left: -1,
    width: 11,
    backgroundColor: '#E9E9E9',
  },
  rightConnection: {
    position: 'absolute',
    top: theme.spacing(1),
    bottom: theme.spacing(1),
    right: -1,
    width: 11,
    backgroundColor: '#E9E9E9',
  },
  text: {
    zIndex: 2,
  },
  textWrap: {
    whiteSpace: 'nowrap',
  },
}));

export default Calendar;
