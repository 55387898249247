import { ILocalTariff } from '../consts/TransportConsts';

export const AddWaterForm: IAddWaterForm = {
  type: 'water',
  rentalId: 0,
  categoryId: 0,
  name: '',
  enginePower: 0,
  color: '',
  maxSpeed: 0,
  issueYear: 0,
  capacity: 0,
  cabinsCount: 0,
  needLicense: true,
  tariffs: [],
  wrapper: '',
  photos: [],
  contract: '',
  insurances: [],
  offline: false,
  comment: '',
  deposit: 0,
};

export interface IAddWaterForm {
  type: string;
  rentalId: number;
  categoryId: number;
  name: string;
  enginePower: number;
  color: string;
  maxSpeed: number;
  issueYear: number;
  capacity: number;
  cabinsCount: number;
  needLicense: boolean;
  tariffs: ILocalTariff[];
  wrapper: string;
  photos: string[];
  contract: string;
  insurances: string[];
  offline: boolean;
  comment: string;
  deposit: number;
}

export enum AddWaterFormFields {
  TYPE = 'type',
  RENTAL_ID = 'rentalId',
  CATEGORY_ID = 'categoryId',
  NAME = 'name',
  ENGINE_POWER = 'enginePower',
  COLOR = 'color',
  MAX_SPEED = 'maxSpeed',
  ISSUE_YEAR = 'issueYear',
  CAPACITY = 'capacity',
  CABINS_COUNT = 'cabinsCount',
  NEED_LICENSE = 'needLicense',
  TARIFFS = 'tariffs',
  WRAPPER = 'wrapper',
  PHOTOS = 'photos',
  CONTRACT = 'contract',
  INSURANCES = 'insurances',
  OFFLINE = 'offline',
  COMMENT = 'comment',
  DEPOSIT = 'deposit',
}

export enum TariffFields {
  START = 'start',
  END = 'end',
  PRICE = 'price',
}
