import { ChangeOrderStatusFormFields } from '../forms/ChangeOrderStatusForm';

export enum OrdersFormKeys {
  CHANGE_ORDER_STATUS_FORM = 'changeOrderStatusForm',
}

export type OrdersFormFields = ChangeOrderStatusFormFields;

export enum OrderStatuses {
  COMPLETED = 'completed',
  CONFIRMED = 'confirmed',
  CONSIDERATION = 'consideration',
  DECLINED = 'declined',
  PAID = 'paid',
  RENT = 'rent',
}

export enum DocumentsTypesFields {
  PassportMainPage = 'passport_main_page',
  PassportRegistration = 'passport_registration',
  DriverLicenseFront = 'driver_license_front',
  DriverLicenseBack = 'driver_license_back',
  WaterLicenseFront = 'water_license_front',
  WaterLicenseBack = 'water_license_back',
}

export enum DeclineReasons {
  AGE_REASON = 'ageReason',
  EXPERIENCE_REASON = 'experienceReason',
  OTHER_REASON = 'otherReason',
  SECURE_REASON = 'secureReason',
  WRONG_DOCUMENTS_REASON = 'wrongDocumentsReason',
}
