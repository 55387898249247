import { Grid } from '@material-ui/core';
import React from 'react';

import { TransportRenderHelper } from 'modules/transport/helpers/TransportRenderHelper';
import AutoTransportModel from 'modules/transport/models/AutoTransportModel';
import WaterTransportModel from 'modules/transport/models/WaterTransportModel';

import TransportItem from './TransportItem';

interface ITransportListProps {
  waterTransportList?: WaterTransportModel[];
  autoTransportList?: AutoTransportModel[];
}

const TransportList: React.FC<ITransportListProps> = ({ waterTransportList, autoTransportList }) => {
  // Renders
  const renderAutoTransport = () => {
    return autoTransportList?.map(transport => {
      return (
        <Grid key={transport.id} item>
          <TransportItem
            transportId={transport.id || 0}
            wrapper={transport.wrapper?.fullUrl || ''}
            status={TransportRenderHelper.getTransportStatus(transport)}
            brandName={transport.transport?.brand?.name || ''}
            modelName={transport.transport?.model?.name || ''}
            stateNumber={transport.transport?.stateNumber || ''}
          />
        </Grid>
      );
    });
  };

  const renderWaterTransport = () => {
    return waterTransportList?.map(transport => {
      return (
        <Grid key={transport.id} item>
          <TransportItem
            transportId={transport.id || 0}
            wrapper={transport.wrapper?.fullUrl || ''}
            status={TransportRenderHelper.getTransportStatus(transport)}
            name={transport.transport?.name || ''}
            isWaterTransport
          />
        </Grid>
      );
    });
  };

  return (
    <Grid container spacing={3}>
      {!!waterTransportList?.length ? renderWaterTransport() : renderAutoTransport()}
    </Grid>
  );
};

export default TransportList;
