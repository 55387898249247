import { Building24Regular } from '@fluentui/react-icons';
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import { routes, authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { useCommonStyles } from 'styles/commonStyles';

import LoginBtn from './components/LoginBtn';
import LogoutBtn from './components/LogoutBtn';
import NavLink from './components/NavLink';
import NavList from './components/NavList';

const Header: React.FC = observer(() => {
  const { authStore, rentalStore } = useRootStore();
  const classes = useStyles();
  const history = useHistory();
  const commonClasses = useCommonStyles();

  // Handlers
  const handleLogout = () => {
    authStore.logout(rentalStore.resetCurrentRental);

    history.push(routes.MainScreen.path);
  };

  const handleLogin = () => {
    history.push(authRoutes.LoginScreen.path);
  };

  // Renders
  return (
    <header className={classes.header}>
      <Grid container spacing={6} alignItems="center">
        <Grid className={classes.headerGrid} item>
          {!!rentalStore.currentRental?.logo && (
            <Box display="flex" mr={2} mb={3}>
              <Link to={routes.MainScreen.path}>
                <img src={rentalStore.currentRental?.logo?.fullUrl || ''} alt="Rental logo" className={classes.logo} />
              </Link>
            </Box>
          )}
        </Grid>
        <Grid className={classes.headerGrid} item xs>
          <NavList />
        </Grid>
        <Grid className={classes.headerGrid} item>
          <NavLink
            className={isActive => clsx({ [commonClasses.fontWeight500]: isActive })}
            to={routes.RentalScreen.path}
            exact
          >
            <div className={classes.linkInner}>
              <Box display="flex" alignItems="center" mr={2}>
                <Building24Regular />
              </Box>
              Личный кабинет
            </div>
          </NavLink>
          <div className={classes.linkInner}>
            {authStore.isAuth ? <LogoutBtn onClick={handleLogout} /> : <LoginBtn onClick={handleLogin} />}
          </div>
        </Grid>
      </Grid>
    </header>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    padding: theme.spacing(2.69, 6),
    marginBottom: theme.spacing(3),
  },
  logo: {
    height: '100%',
    width: '100%',
    maxHeight: 40,
    maxWidth: 150,
  },
  headerGrid: {
    paddingBottom: '0 !important',
    display: 'flex',
    alignItems: 'center',
  },
  linkInner: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 3),
    marginBottom: theme.spacing(3),
  },
}));

export default Header;
