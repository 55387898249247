import { makeAutoObservable, set } from 'mobx';
import { routes } from 'routes/routes';

import ErrorsService from 'base/modules/errors/ErrorsService';
import history from 'base/routes/history';

import AuthService from './AuthService';
import { ForgotPasswordForm } from './forms/ForgotPasswordForm';
import { LoginForm } from './forms/LoginForm';
import { RegisterForm } from './forms/RegisterForm';
import { User } from './models/User';
import TokenService from './modules/token/TokenService';
import { AuthFormFields, AuthFormKeys, ErrorTypes } from './types/AuthTypes';

const initErrors = {
  [ErrorTypes.RESTORE_PASSWORD]: null,
  [ErrorTypes.NEW_PASSWORD]: null,
  [ErrorTypes.LOGIN]: null,
};

export class AuthStore {
  loading = false;
  isAuth = false;
  completeCheckAuth = false;
  errorMessages: Record<ErrorTypes, string[] | null> = initErrors;

  loginForm = LoginForm;
  registerForm = RegisterForm;
  forgotPasswordForm = ForgotPasswordForm;

  userInfo: User | null = null;

  private authService: AuthService;
  private tokenService: TokenService;
  private errorsService: ErrorsService;

  constructor() {
    makeAutoObservable(this);
    this.authService = new AuthService();
    this.tokenService = new TokenService();
    this.errorsService = new ErrorsService();
  }

  register = (values: any) => {
    this.setLoading(true);

    this.authService
      .register(values)
      .then(({ accessToken }) => {
        if (accessToken) {
          this.tokenService.saveToken(accessToken);
          this.setIsAuth(true);
        }
      })
      .catch(error => {
        this.setErrors(this.errorsService.getErrors(error));
      })
      .finally(() => this.setLoading(false));
  };

  login = (cb?: () => void) => {
    this.setLoading(true);

    this.authService
      .login(this.loginForm)
      .then(({ accessToken }) => {
        if (accessToken) {
          this.tokenService.saveToken(accessToken);
          this.setIsAuth(true);
          this.getUserInfo();
          history.push(routes.MainScreen.path);
          cb && cb();
        }
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;
        const message = error?.response?.data?.message;
        this.setErrors({ [ErrorTypes.LOGIN]: errors?.length ? errors : message || '' });
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  logout = (resetCurrentRental?: () => void) => {
    resetCurrentRental && resetCurrentRental();
    this.tokenService.deleteToken();
    this.setIsAuth(false);
  };

  forgotPassword = () => {
    this.setLoading(true);

    this.authService
      .forgotPassword(this.forgotPasswordForm)
      .then(() => {
        this.setErrors({});
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;
        errors && this.setErrors({ [ErrorTypes.RESTORE_PASSWORD]: errors });
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  setNewPassword = () => {
    this.setLoading(true);

    this.authService
      .setNewPassword(this.forgotPasswordForm)
      .then(() => {
        history.push(routes.MainScreen.path);
      })
      .catch(error => {
        const errors = error?.response?.data?.errors;
        errors && this.setErrors({ [ErrorTypes.NEW_PASSWORD]: errors });
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  checkAuth = () => {
    const accessToken = this.tokenService.getToken();

    if (accessToken) {
      this.tokenService.saveToken(accessToken);
      this.setIsAuth(true);
      this.setCompleteCheckAuth(true);

      return;
    }

    this.setIsAuth(false);
    this.setCompleteCheckAuth(true);
  };

  getUserInfo = () => {
    this.setLoading(true);

    this.authService
      .getUserInfo()
      .then(data => {
        this.setUserInfo(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setLoading(false);
      });
  };

  setCompleteCheckAuth = (value: boolean) => {
    this.completeCheckAuth = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setIsAuth = (state: boolean) => {
    this.isAuth = state;
  };

  setErrors = (errors: any | null) => {
    this.errorMessages = errors;
  };

  setForm = (formKey: AuthFormKeys, key: AuthFormFields, value: any) => {
    set(this[formKey], key, value);
  };

  setUserInfo = (data: User) => {
    this.userInfo = data;
  };

  removeError = (fieldName: ErrorTypes) => {
    delete this.errorMessages[fieldName];
  };

  resetStore<T>(formKey: AuthFormKeys, defaultFormFields: T) {
    this.loading = false;
    this.errorMessages = initErrors;
    this.completeCheckAuth = true;

    Object.entries(defaultFormFields).forEach(([key, value]) => {
      this.setForm(formKey, key as AuthFormFields, value);
    });
  }
}
