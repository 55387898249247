import { ILocalTariff } from './../consts/TransportConsts';

export const AddAutoForm: IAddAutoForm = {
  type: 'automobile',
  rentalId: 0,
  brandId: 0,
  modelId: 0,
  stateNumber: '',
  enginePower: 0,
  engineVolume: 0,
  color: '',
  racing: 0,
  maxSpeed: 0,
  speedLimit: 0,
  mileageLimit: 0,
  issueYear: 0,
  music: '',
  deliveryPrice: 0,
  deposit: 0,
  drive: '',
  transmission: '',
  bodyType: '',
  fuelType: '',
  fuelConsumption: 0,
  fuelCostPerLiter: 0,
  tariffs: [],
  wrapper: '',
  photos: [],
  contract: '',
  insurances: [],
  offline: false,
  comment: '',
  rerunPrice: 0,
  washFee: 0,
  travelAboard: false,
  travelOutOfCity: false,
};

export interface IAddAutoForm {
  type: string;
  rentalId: number;
  brandId: number;
  modelId: number;
  stateNumber: string;
  enginePower: number;
  engineVolume: number;
  color: string;
  racing: number;
  maxSpeed: number;
  speedLimit: number;
  mileageLimit: number;
  issueYear: number;
  music: string;
  deliveryPrice: number;
  deposit: number;
  drive: string;
  transmission: string;
  bodyType: string;
  fuelType: string;
  fuelConsumption: number;
  fuelCostPerLiter: number;
  tariffs: ILocalTariff[];
  wrapper: string;
  photos: string[];
  contract: string;
  insurances: string[];
  offline: boolean;
  washFee: number;
  travelAboard: boolean;
  travelOutOfCity: boolean;
  comment: string;
  rerunPrice: number;
}

export enum AddAutoFormFields {
  TYPE = 'type',
  RENTAL_ID = 'rentalId',
  BRAND_ID = 'brandId',
  MODEL_ID = 'modelId',
  STATE_NUMBER = 'stateNumber',
  ENGINE_POWER = 'enginePower',
  ENGINE_VOLUME = 'engineVolume',
  COLOR = 'color',
  RACING = 'racing',
  MAX_SPEED = 'maxSpeed',
  SPEED_LIMIT = 'speedLimit',
  MILEAGE_LIMIT = 'mileageLimit',
  ISSUE_YEAR = 'issueYear',
  MUSIC = 'music',
  DELIVERY_PRICE = 'deliveryPrice',
  DEPOSIT = 'deposit',
  DRIVE = 'drive',
  TRANSMISSION = 'transmission',
  BODY_TYPE = 'bodyType',
  FUEL_TYPE = 'fuelType',
  FUEL_CONSUMPTION = 'fuelConsumption',
  FUEL_COST_PER_LITER = 'fuelCostPerLiter',
  TARIFFS = 'tariffs',
  WRAPPER = 'wrapper',
  PHOTOS = 'photos',
  CONTRACT = 'contract',
  INSURANCES = 'insurances',
  OFFLINE = 'offline',
  COMMENT = 'comment',
  RERUN_PRICE = 'rerunPrice',
  WASH_FEE = 'washFee',
  TRAVEL_ABOARD = 'travelAboard',
  TRAVEL_OUT_OF_CITY = 'travelOutOfCity',
}

export enum TariffFields {
  START = 'start',
  END = 'end',
  PRICE = 'price',
  IS_SINGLE_DAY = 'isSingleDay',
}
