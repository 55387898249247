import AbstractDto from 'base/AbstractDto';

import DeliveryTariff from '../models/DeliveryTariff';

export default class UpdateDeliveryTariffDto extends AbstractDto {
  id: number | null = null;
  tariffs: DeliveryTariff[] = [];

  constructor(props: any) {
    super();
    this.load(props);
  }
}
