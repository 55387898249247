import { DialogProps, Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useRootStore } from 'base/hooks/useRootStore';
import EditModalWrapper from 'components/Layouts/EditModalWrapper';
import QModal from 'components/UI/QModal';
import QInput from 'components/UI/textFields/QInput';
import { RenderHelper } from 'helpers/RenderHelper';
import { UpdateDepositFormFields } from 'modules/transport/forms/UpdateDepositForm';
import { TransportFormKeys, TypesOfTransport } from 'modules/transport/types/TransportTypes';

interface IEditDepositModalProps extends DialogProps {
  onClose: () => void;
  transportType?: TypesOfTransport;
}

const EditDepositModal: React.FC<IEditDepositModalProps> = observer(
  ({ onClose, transportType = TypesOfTransport.AUTO, ...rest }) => {
    const { transportStore } = useRootStore();

    // Handlers
    const handleChangeValues = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = event.target;

      transportStore.setForm(TransportFormKeys.UPDATE_DEPOSIT, name as UpdateDepositFormFields, value);
    };

    const handleSave = () => {
      transportStore.updateDeposit(onClose, transportType === TypesOfTransport.AUTO);
    };

    // Renders
    return (
      <QModal onClose={onClose} {...rest}>
        <EditModalWrapper title="Настроить залог" onClose={onClose} onSave={handleSave}>
          <Box mb={3}>
            <QInput
              name={UpdateDepositFormFields.AMOUNT}
              onChange={handleChangeValues}
              value={transportStore.updateDepositForm[UpdateDepositFormFields.AMOUNT]}
              label="Напишите сумму"
              type="number"
              onKeyDown={RenderHelper.disableInputKeyDownEvent}
              onWheel={RenderHelper.disableInputWheelEvent}
              inputProps={{
                min: 0,
              }}
            />
          </Box>
        </EditModalWrapper>
      </QModal>
    );
  },
);

export default EditDepositModal;
